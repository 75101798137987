<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Ir a temas',
          click: (v) => $router.push(`/documentacion/${$route.params.seccion}/temas/${v.id}`),
          posicion: 'izquierda',
          icon: 'mdi-file-search-outline',
        },
      ]"
      :botones-ver="[]"
      :campo-titulo-dialog="(v) => v.nombre"
      :headers="headers"
      :query="query"
      url="/documentacion/grupos"
      search-url="/documentacion/grupos/search"
      search-method="post"
      campo-titulo-dialog-crear="Crear grupo"
      search-inicial
      disable-view
      :on-after-save="afterSave"
      :on-after-delete="afterDelete"
      texto-hits="%s grupos."
      texto-no-hit="No se encontraron grupos."
      texto-un-hit="Un grupo."
      @created="$emit('notificar', 'Grupo creado.')"
      @deleted="$emit('notificar', 'Grupo eliminado.')"
      @updated="$emit('notificar', 'Grupo guardado.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col class="text-h6">
              {{ $payload.seccion($route.params.seccion) }}
            </v-col>
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
              <z-btn
                class="ml-1"
                color="primary"
                outlined
                @click="create({ seccion: $route.params.seccion, prioridad: 50, publicado: false })"
              >
                Crear
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #form="{ accion, form, errors }">
        <grupos-form :accion="accion" :errors="errors" :value="form" @input="form = $event" />
      </template>

      <template #item.publicado="{ value }">
        <z-chip :color="value ? 'success' : 'grey'" outlined>
          {{ value ? 'Si' : 'No' }}
        </z-chip>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import GruposForm from '@/admin/views/Documentacion/GruposForm'
import ZChip from '@/components/ZChip'

export default {
  components: { ZChip, ZMantenedor, ZBtn, GruposForm },
  data() {
    return {
      data: [],
      items: [],
      itemActivo: {},
      query: {
        paginationDisabled: true,
        filter: {
          seccion: this.$route.params.seccion,
        },
        sort: {
          0: { field: 'prioridad', direction: 'asc' },
        },
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Prioridad',
          value: 'prioridad',
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: '¿Publicado?',
          value: 'publicado',
        },
      ]
    },
  },
  methods: {
    afterDelete(item) {
      this.$store.dispatch('app/fetchPayload', 'documentacion', { root: true })
    },
    afterSave(item, accion) {
      this.$store.dispatch('app/fetchPayload', 'documentacion', { root: true })
    },
  },
}
</script>
