<template>
  <div>
    <z-form-search
      ref="zFormSearch"
      v-model="items"
      :url="`/pagos/reportes/info-volumen-transacciones`"
      :query="query"
      :headers="headers"
      :card-data-table-props="{
        style: 'width: 900px',
      }"
    >
      <template #buscador="{ search }">
        <v-form @submit.prevent="search">
          <v-row>
            <v-col>
              <z-input v-model="query.fecha_pago_desde" type="date" label="Desde" />
            </v-col>
            <v-col>
              <z-input v-model="query.fecha_pago_hasta" type="date" label="Hasta" />
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="6" md="5">
              <z-input
                v-model="query.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="9">
              <z-input
                v-model="query.tramitesSeleccionados"
                multiple
                :items="payloadItemsTramites"
                clearable
                label="Trámites"
              />
            </v-col>

            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                color="primary"
                :disabled="query.tramitesSeleccionados.length === 0"
                outlined
                type="submit"
              >
                Buscar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #item.monto="{ item }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ $formato.moneda(item.monto) }}
            </span>
          </template>
        </v-tooltip>
      </template>
    </z-form-search>
    <div v-if="items && items.length > 0" style="width: 80%" class="mx-auto">
      <vue-apex-charts
        class="my-16"
        type="area"
        height="350"
        :options="pagosUltimosDias.chartOptions"
        :series="pagosUltimosDias.series"
      />
    </div>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZInput from '@/components/Input/ZInput'
import ZFormSearch from '@/components/ZFormSearch'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    ZFormSearch,
    ZInput,
    ZBtn,
    VueApexCharts,
  },
  data() {
    return {
      items: [],
      itemActivo: {},
      slugTramite: this.$route.query.tramite ?? '',

      errors: {},
      query: {
        fecha_pago_desde: '',
        fecha_pago_hasta: '',
        institucion_id: '',
        tramitesSeleccionados: [],
      },
    }
  },
  computed: {
    headers() {
      // console.log('1 ' + this.query.tramitesSeleccionados) // lista de tramites

      var encabezado = []
      let tramitesunderscore
      let tramitestitulo
      let j
      const tramites = this.query.tramitesSeleccionados
      encabezado[0] = {
        text: 'Periodo',
        value: 'periodo',
        cellClass: 'text-end',
      }

      for (let i = 0; i < tramites.length; i++) {
        console.log(tramites[i])
        tramitesunderscore = tramites[i].replaceAll('-', '_')
        tramitestitulo = tramites[i].replaceAll('-', ' ')
        tramitestitulo = tramitestitulo.charAt(0).toUpperCase() + tramitestitulo.slice(1)
        j = i + 1
        encabezado[j] = {
          text: tramitestitulo,
          value: 'cant_' + tramitesunderscore,
          cellClass: 'text-end',
          formatter: (v) => this.$formato.numero(v),
        }
      }

      j = j + 1
      encabezado[j] = {
        text: 'Total',
        value: 'cantidad',
        cellClass: 'text-end',
        formatter: (v) => this.$formato.numero(v),
      }
      return encabezado
    },
    pagosUltimosDias() {
      return {
        series: [
          {
            name: 'Cantidad de transacciones mensuales',
            data: this.items.map((i) => i.cantidad).slice(0, 14),
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: 'Cantidad de transacciones por periodo',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: this.items.map((i) => i.periodo).slice(0, 14),
          },
        },
      }
    },
    dates() {
      return this.items.map((i) => [this.$dayjs(i.fecha, 'YYYY-MM-DD').valueOf(), i.monto_total])
    },
  },
  mounted() {
    // this.$nextTick(() => this.$refs.zFormSearch.search())
  },
  methods: {},
}
</script>
