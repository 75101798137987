<template>
  <div>
    <v-card class="mx-auto mt-16" max-width="700">
      <v-form ref="form" v-model="formValido" lazy-validation @submit.prevent="continuar">
        <v-card-title> Crear ticket de asistencia </v-card-title>
        <v-card-subtitle>
          Antes de enviar un ticket de asistencia, te recomendamos que revises las preguntas
          frecuentes. Es posible que tu pregunta ya tenga una respuesta!
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7">
              <z-input
                v-model="form.email"
                :error-messages="errors.email"
                label="Correo electrónico"
                required
                :dense="false"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <z-input
                v-model="form.telefono"
                :error-messages="errors.telefono"
                label="Teléfono"
                required
                :dense="false"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7">
              <z-input
                v-model="form.email_cc"
                :error-messages="errors.email_cc"
                label="Enviar copia de correos electrónicos a"
                :dense="false"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col sm="9">
              <z-input
                v-model="form.tipo"
                :error-messages="errors.tipo"
                tipo="select"
                :items="payloadItemsTipoOirs"
                label="Tipo"
                required
                :dense="false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="9">
              <z-input
                v-model="form.categoria"
                :error-messages="errors.categoria"
                tipo="select"
                :items="$itemsCategoriasOirs"
                label="Categoría"
                required
                :dense="false"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="9">
              <z-input
                v-model="form.tramite_id"
                :error-messages="errors.tramite_id"
                tipo="select"
                :items="$itemsTramitesOirs"
                label="Trámite relacionado"
                :dense="false"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <z-input
                v-model.trim="form.contenido"
                :error-messages="errors.contenido"
                tipo="textarea"
                label="Mensaje"
                counter
                minlength="20"
                maxlength="500"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="errors._message">
          <z-alert color="info">
            {{ errors._message }}
          </z-alert>
        </v-card-text>

        <v-card-actions>
          <btn-guardar-cancelar
            class="my-2"
            texto-guardar="Generar ticket"
            :disabled-guardar="
              !form.tipo ||
              !form.categoria ||
              !form.tramite_id ||
              !form.contenido ||
              form.contenido.toString().length < 20
            "
            @cancelar="$router.push('/tickets')"
          />
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import { getError } from '@/utils/errors'

export default {
  name: 'CrearSolicitud',
  components: { BtnGuardarCancelar, ZInput },
  data() {
    return {
      form: {
        nombres: '',
        apellidos: '',
      },
      errors: {},
      formValido: null,
      loading: false,
    }
  },
  computed: {},
  beforeMount() {
    this.form.nombres = this.$auth.user().name
    this.form.apellidos = this.$auth.user().apellido
    this.form.email = this.$auth.user().email
    this.form.telefono = this.$auth.user().telefono
    this.form.tipo = parseInt(this.$route.query.tipo) || null
    this.form.categoria = parseInt(this.$route.query.categoria) || null
    this.form.tramite_id = parseInt(this.$route.query.tramite_id) || null
  },
  methods: {
    continuar() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      this.errors = {}
      this.$axios
        .post('/documentacion/oirs', this.form)
        .then((response) => {
          const oirs = response.data.data
          console.log(oirs)
          this.$emit('notificar', 'Ticket creado')
          this.$router.push('/tickets/mis-tickets')
        })
        .catch((error) => {
          this.errors = getError(error)
          this.$emit('notificar:error', 'No se pudo crear el ticket.')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
