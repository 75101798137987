<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr>
          <th style="width: 160px">Rol</th>
          <td class="text-capitalize">
            {{ item.roles.map((r) => r.name).join(', ') }}
          </td>
        </tr>
        <tr>
          <th style="width: 160px">RUT</th>
          <td>{{ $formato.rut(item.rut) }}</td>
        </tr>
        <tr>
          <th>Nombre</th>
          <td>
            {{ item.name }} {{ item.apellido }}
            <z-chip :color="item.activo ? 'success' : 'error'" dark small>
              {{ item.activo ? 'Activo' : 'Deshabilitado' }}
            </z-chip>
          </td>
        </tr>
        <tr>
          <th>Teléfono</th>
          <td>{{ item.telefono }}</td>
        </tr>
        <tr>
          <th>Cargo</th>
          <td>{{ item.cargo }}</td>
        </tr>
        <tr>
          <th>Correo electrónico</th>
          <td>
            <a :href="`mailto:${item.email}`">{{ item.email }}</a>
          </td>
        </tr>
        <tr>
          <th>Institución</th>
          <td>{{ item.institucion.nombre }}</td>
        </tr>
        <tr>
          <th>Activo?</th>
          <td>{{ item.activo == true ? 'Activo' : 'Deshabilitado' }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import ZChip from '@/components/ZChip'

export default {
  components: { ZChip },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
