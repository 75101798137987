var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"float-end",staticStyle:{"z-index":"15"},attrs:{"color":"primary","fab":"","fixed":"","bottom":"","right":"","loading":_vm.loading > 0},on:{"click":_vm.requests}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-card',{staticClass:"my-4 mx-auto",attrs:{"max-width":"600px"}},[_c('v-card-title',[_vm._v(" Colas ")]),_c('v-simple-table',[_c('thead',[_c('tr',{staticClass:"text-subtitle-1"},[_c('td',[_vm._v("Cola")]),_c('td',[_vm._v("Estado")]),_c('td',{staticClass:"text-right"},[_vm._v("Mensajes")])])]),_c('tbody',[_vm._l((_vm.queues),function(queue){return [(!(queue.messages === 0 && queue.name.includes('.delay.')))?_c('tr',{key:queue.name},[_c('td',[_vm._v(" "+_vm._s(queue.name.split('.')[0])+" "),(queue.name.includes('.delay.'))?_c('span',{staticClass:"text--secondary text-caption"},[_vm._v(" ("+_vm._s(_vm.$dayjs.duration(parseInt(queue.name.split('.delay.')[1])).humanize())+") ")]):_vm._e()]),_c('td',[_c('v-chip',{attrs:{"small":"","outlined":"","color":queue.state === 'running' ? 'primary' : 'disabled'}},[_vm._v(" "+_vm._s(queue.state)+" ")])],1),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$formato.numero(queue.messages_ready))+" "),(queue.messages_ready !== queue.messages)?_c('span',[_vm._v(" / "+_vm._s(_vm.$formato.numero(queue.messages))+" ")]):_vm._e()])]):_vm._e()]})],2)])],1),_c('v-card',{staticClass:"my-4 mx-auto",attrs:{"max-width":"1200px"}},[_c('v-card-title',[_vm._v(" Resumen de Jobs ejecutados ")]),_c('v-card-text',[_c('vue-apex-charts',{attrs:{"height":"350","options":_vm.chartJobsConsolidado.chartOptions,"series":_vm.chartJobsConsolidado.series}})],1)],1),(
      _vm.chartPorJobProcessed &&
      _vm.chartPorJobProcessed.series.length > 0 &&
      _vm.chartPorJobProcessed.series[0].data.filter((v) => v > 0).length > 0
    )?_c('v-card',{staticClass:"my-4 mx-auto"},[_c('v-card-title',[_vm._v(" Jobs exitosos por minuto ")]),_c('v-card-text',[_c('vue-apex-charts',{attrs:{"height":"400","options":_vm.chartPorJobProcessed.chartOptions,"series":_vm.chartPorJobProcessed.series}})],1)],1):_vm._e(),(
      _vm.chartPorJobFailed &&
      _vm.chartPorJobFailed.series.length > 0 &&
      _vm.chartPorJobFailed.series[0].data.filter((v) => v > 0).length > 0
    )?_c('v-card',{staticClass:"my-4 mx-auto"},[_c('v-card-title',[_vm._v(" JobFailed por minuto ")]),_c('v-card-text',[_c('vue-apex-charts',{attrs:{"height":"400","options":_vm.chartPorJobFailed.chartOptions,"series":_vm.chartPorJobFailed.series}})],1)],1):_vm._e(),(
      _vm.chartPorJobExceptionOccurred &&
      _vm.chartPorJobExceptionOccurred.series.length > 0 &&
      _vm.chartPorJobExceptionOccurred.series[0].data.filter((v) => v > 0).length > 0
    )?_c('v-card',{staticClass:"my-4 mx-auto"},[_c('v-card-title',[_vm._v(" JobExceptionOccurred por minuto ")]),_c('v-card-text',[_c('vue-apex-charts',{attrs:{"height":"400","options":_vm.chartPorJobExceptionOccurred.chartOptions,"series":_vm.chartPorJobExceptionOccurred.series}})],1)],1):_vm._e(),_c('v-card',{staticClass:"my-4 mx-auto"},[_c('v-card-title',[_vm._v(" Últimos logs de Jobs ")]),(!_vm.logs.length)?_c('v-card-text',{staticClass:"text-center py-6"},[_vm._v(" (ninguno) ")]):_c('v-card-text',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Hora")]),_c('th',[_vm._v("Cola")]),_c('th',[_vm._v("Evento")]),_c('th',[_vm._v("Job")]),_c('th')])]),_c('tbody',_vm._l((_vm.logs),function(log){return _c('tr',{key:log.id},[_c('td',[_vm._v(" "+_vm._s(_vm.$dayjs(log.fecha).format('HH:mm:ss'))+"."),_c('span',{staticClass:"text--secondary text-caption"},[_vm._v(_vm._s(_vm.$dayjs(log.fecha).format('SSS')))])]),_c('td',[_vm._v(" "+_vm._s(log.payload.queue)+" ")]),_c('td',[_c('v-chip',{attrs:{"color":log.nivel,"outlined":"","small":""}},[_vm._v(" "+_vm._s(log.nombre)+" ")]),_c('div',{staticClass:"text-caption text--secondary text-truncate hidden-xs-only",staticStyle:{"max-width":"250px"},attrs:{"title":log.payload.exception.replace(/ in \/.*/s, '').substr(0, 200) + '...'}},[_vm._v(" "+_vm._s(log.payload.exception.replace(/ in \/.*/s, '').substr(0, 200))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(log.clase.split('\\').pop())+" "),_c('span',{staticClass:"text--secondary text-caption"},[_vm._v("("+_vm._s(log.payload.attempts)+")")]),_c('div',{staticClass:"text-caption text--secondary hidden-sm-and-down"},[_vm._v(" "+_vm._s(log.payload.uuid)+" ")])]),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.logDetalle = log
                  _vm.dialogLogVisible = true}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)],1)])}),0)])],1)],1),(_vm.dialogLogVisible)?_c('z-dialog',{attrs:{"campo-titulo":"nombre","campo-subtitulo":"clase","max-width":"900","items":_vm.logs,"navegacion":""},on:{"cerrar":function($event){_vm.dialogLogVisible = false}},model:{value:(_vm.logDetalle),callback:function ($$v) {_vm.logDetalle=$$v},expression:"logDetalle"}},[_c('v-simple-table',[_c('tbody',[_vm._l(({
            fecha: _vm.$dayjs(_vm.logDetalle.fecha).format('HH:mm:ss.SSS'),
            microservicio: _vm.logDetalle.microservicio,
            ms: _vm.logDetalle.ms,
            nivel: _vm.logDetalle.nivel,
            ..._vm.logDetalle.payload,
          }),function(valor,campo){return [(campo !== 'exception' && valor !== null)?_c('tr',{key:campo},[_c('td',[_vm._v(_vm._s(campo))]),_c('td',[_vm._v(_vm._s(valor))])]):_vm._e()]})],2)]),(_vm.logDetalle.payload.exception)?_c('v-card',{staticClass:"mt-8"},[_c('v-card-title',[_vm._v(" Exception ")]),_c('v-card-text',[_c('pre',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.logDetalle.payload.exception.replaceAll(
            'vendor/laravel/framework/src/Illuminate',
            'v.../Illuminate'
          )))])])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }