<template>
  <v-card
    :color="seleccionado ? 'primary lighten-1' : 'fondo3'"
    :dark="seleccionado"
    elevation="1"
    outlined
    style="opacity: 0.9"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-simple-table class="subtotales py-1" dense style="background-color: transparent">
      <tbody>
        <!--

    Desglose personalizados de totales a pagar
    (Reemplaza al desglose por defecto)

    -->

        <template v-if="cuenta.montos_labels && !cuentaPagada">
          <tr v-for="(label, campo) in cuenta.montos_labels" :key="campo">
            <td class="text-right" style="opacity: 0.85">
              {{ label.titulo }}
            </td>
            <td class="text-right text-subtitle-1">
              {{ label.valor }}
            </td>
          </tr>
        </template>
        <!--

    Desglose de totales a pagar

    -->

        <template v-else>
          <tr v-if="cuenta.tramite_id !== 9">
            <td class="text-right" style="opacity: 0.85">Vencimiento</td>
            <td class="text-right text-subtitle-1">
              {{ $formato.fecha(cuenta.fecha_vencimiento) }}
            </td>
          </tr>

          <tr v-if="cuenta.montos.monto && cuenta.montos.monto !== cuenta.montos.monto_total">
            <td class="text-right" style="opacity: 0.85">Neto</td>
            <td class="text-right text-subtitle-1">
              {{ $formato.moneda(cuenta.montos.monto) }}
            </td>
          </tr>

          <template v-if="cuenta.estado !== 28 && cuenta.estado !== 27">
            <tr v-if="cuenta.montos.monto_descuento">
              <td class="text-right" style="opacity: 0.85">Descuento</td>
              <td class="text-right text-subtitle-1">
                {{ $formato.moneda(-1 * cuenta.montos.monto_descuento) }}
              </td>
            </tr>

            <tr v-if="cuenta.montos.monto_interes">
              <td class="text-right" style="opacity: 0.85">Intereses</td>
              <td class="text-right text-subtitle-1">
                {{ $formato.moneda(cuenta.montos.monto_interes) }}
              </td>
            </tr>
            <tr v-if="cuenta.montos.monto_reajuste">
              <td class="text-right" style="opacity: 0.85">Reajustes</td>
              <td class="text-right text-subtitle-1">
                {{ $formato.moneda(cuenta.montos.monto_reajuste) }}
              </td>
            </tr>

            <tr v-if="cuenta.montos.monto_extra">
              <td class="text-right" style="opacity: 0.85">Multas</td>
              <td class="text-right text-subtitle-1">
                {{ $formato.moneda(cuenta.montos.monto_extra) }}
              </td>
            </tr>
          </template>
        </template>

        <!--

    Monto total a pagar

    -->

        <tr v-if="!cuentaPagada">
          <td class="text-right" style="opacity: 0.85">Total</td>
          <td class="text-h6 text-right">
            {{ $formato.moneda(cuenta.montos.monto_total + cuenta.montos.monto_extra) }}
          </td>
        </tr>

        <!--

      Monto total pagado

    -->

        <template v-if="cuentaPagada && cuenta.estado !== 28 && cuenta.estado !== 27">
          <tr>
            <td class="text-right" style="opacity: 0.85">Total pagado</td>
            <td class="text-h6 text-right primary--text">
              <v-tooltip :disabled="!cuenta.fecha_pago" top>
                <template #activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon class="ml-1 mt-n1" color="primary">
                      {{ $svg.mdiCheckCircleOutline }}
                    </v-icon>
                    {{ $formato.moneda(cuenta.montos.monto_total) }}
                  </div>
                </template>
                <span>
                  Pagado el
                  {{ $formato.fecha(cuenta.fecha_pago, 'D [de] MMMM [de] YYYY [a las] HH:mm') }}
                </span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="cuenta.montos.monto_saldo">
            <td class="text-right" style="opacity: 0.85">Saldo adeudado</td>
            <td class="text-h6 text-right">
              {{ $formato.moneda(cuenta.montos.monto_saldo) }}
            </td>
          </tr>
        </template>

        <!--

      Advertencia en caso de que la cuenta no se pueda pagar

    -->
        <tr v-if="!!cuenta.no_pagable || (!cuenta.pagable && cuenta.estado === 11)">
          <td class="text-right" colspan="2">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-chip color="secondary" style="opacity: 0.8" v-bind="attrs" x-small v-on="on">
                  {{ (cuenta.no_pagable && cuenta.no_pagable._label) || 'No se puede pagar' }}
                  <v-icon right x-small>
                    {{ $svg.mdiInformationOutline }}
                  </v-icon>
                </v-chip>
              </template>
              <ul v-if="cuenta.no_pagable">
                <template v-for="(razon, key) in cuenta.no_pagable">
                  <li v-if="key.toString().substr(0, 1) !== '_'" :key="razon">
                    {{ razon }}
                  </li>
                </template>
              </ul>
              <span v-else>No está disponible para pago</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mdiCheckCircleOutline } from '@mdi/js'

export default {
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
    seleccionado: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      svg: {
        mdiCheckCircleOutline,
      },
    }
  },
  computed: {
    cuentaPagada() {
      return this.cuenta.estado >= 25 && this.cuenta.estado <= 28
    },
  },
  methods: {},
}
</script>

<style>
.v-data-table.subtotales table > tbody > tr > td {
  border-bottom: none !important;
}

.seleccionado
  .v-data-table.subtotales
  table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #0f69c499 !important; /** color primario */
}
</style>
