<template>
  <div>
    <!--
    dayjs(utm.fecha, 'YYYYMM'
    -->
    <z-mantenedor
      v-model="items"
      :headers="headers"
      :query="query"
      url="/pagos/feriados"
      search-url="/pagos/feriados/search"
      search-method="post"
      disable-view
      height="330"
      search-inicial
      width="380"
      @created="$emit('notificar', 'El feriado fue creado')"
      @created:error="$emit('notificar:error', 'Error al intentar crear el feriado.')"
      @updated="$emit('notificar', 'Feriado guardado.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-card class="d-flex justify-space-between" flat>
            <v-select
              v-model="query.scopes[0].parameters[0]"
              :items="anos"
              dense
              label="Año"
              outlined
            />
            <v-spacer />
            <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            <z-btn
              class="mx-1"
              :loading="loading"
              color="primary"
              outlined
              @click="create({ fecha: '' })"
            >
              Crear
            </z-btn>
          </v-card>
        </v-form>
      </template>

      <template #form="{ accion, form, errors }">
        <v-row v-if="accion === 'create' || accion === 'edit'">
          <v-col>
            <z-input
              v-model="form.fecha"
              :error-messages="errors.fecha"
              tipo="fecha"
              hint="Día no hábil"
            />
          </v-col>
        </v-row>
        <v-row v-if="accion === 'create' || accion === 'edit'">
          <v-col>
            <v-text-field
              v-model="form.nombre"
              :error-messages="errors.nombre"
              label="Nombre"
              minlength="25"
              type="string"
              outlined
            />
          </v-col>
        </v-row>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'

export default {
  components: { ZInput, ZMantenedor, ZBtn },
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Fecha',
          value: 'fecha',
          cellClass: 'text-capitalize',
          formatter: (v) => this.$dayjs(v).format('dddd DD-MMM'),
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: 'Última edición',
          value: 'updated_at',
          formatter: (v) => this.$dayjs(v).format('L LT'),
        },
      ],
      query: {
        scopes: {
          0: { name: 'ano', parameters: [2020] },
        },
        sort: {
          0: { field: 'fecha', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    anoActual() {
      return parseInt(this.$dayjs().format('YYYY'))
    },
    anos() {
      const anos = []
      for (let ano = this.anoActual; ano <= this.anoActual + 1; ano++) {
        anos.push(ano)
      }
      return anos
    },
  },
  beforeMount() {
    this.query.scopes[0].parameters[0] = this.anoActual
  },
}
</script>
