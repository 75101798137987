<template>
  <div>
    <z-mantenedor
      v-model="items"
      disable-edit
      disable-delete
      :botones-listar="[
        {
          title: 'Descargar PDF',
          icon: 'mdi-file-download-outline',
          click: $util.descargarPdf,
          posicion: 'izquierda',
          disabled: (c) => !c.codigo_verificacion,
        },
      ]"
      :botones-ver="[]"
      :campo-titulo-dialog="(v) => v.pk"
      search-inicial
      :on-before-search="beforeSearch"
      :on-after-search="afterSearch"
      :headers="headers"
      :query="query"
      :url="`/tramites/${slugTramite}/cuentas`"
      :search-url="`/tramites/${slugTramite}/cuentas/search?registro=true`"
      search-method="post"
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <input-tramite v-model="slugTramite" />
            </v-col>

            <v-col cols="12" sm="5" md="4">
              <z-input
                v-model="query.filters.fecha_pago_desde.value"
                :error-messages="errors.fecha_pago_desde"
                label="Fecha de pago"
                type="date"
              />
            </v-col>

            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="5" md="4">
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>

            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                :disabled="!slugTramite || !query.filters.fecha_pago_desde.value"
                :loading="loading"
                color="primary"
                outlined
                type="submit"
              >
                Buscar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.montos_rel="{ item }">
        <montos-tooltip :montos="item.montos_rel[0]">
          {{ $formato.moneda(item.montos_rel[0].monto_total) }}
        </montos-tooltip>
      </template>
      <template #item.pk="{ item }">
        {{ item.pk_label }}
        <div v-if="item.subtitulo" class="text--secondary text-caption">
          {{ item.subtitulo }}
        </div>
      </template>

      <template #view="{ item }">
        <pagos-del-dia-view :item="item" />
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import InputTramite from '@/components/Input/InputTramite'
import MontosTooltip from '@/components/MontosTooltip'
import PagosDelDiaView from '@/admin/views/Reportes/PagosDelDiaView'

export default {
  components: {
    MontosTooltip,
    InputTramite,
    ZInput,
    ZMantenedor,
    ZBtn,
    PagosDelDiaView,
  },
  data() {
    return {
      items: [],
      itemActivo: {},
      slugTramite: this.$route.query.tramite || this.$store.getters['app/tramite'] || '',
      headerOptions: {
        identificador: {
          text: 'Identificador',
        },
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      errors: {},
      query: {
        scope: {},
        filter: {
          estado: 25,
          institucion_id: parseInt(this.$route.query.institucion_id),
        },
        filters: {
          // TODO rango de fecha de todo el dia
          fecha_pago_desde: {
            field: 'fecha_pago',
            operator: '>=',
            value: this.$route.params.fecha || this.$dayjs().tz().format('YYYY-MM-DD'),
          },
          fecha_pago_hasta: { field: 'fecha_pago', operator: '<=', value: null },
        },
        include: 'pagos,montos_rel',
        sort: {
          0: { field: 'fecha_pago', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    nombrePk() {
      return (
        (this.slugTramite &&
          this.$payload.tramite(this.slugTramite, 'definicion').buscador?.nombrePk) ||
        'Texto a buscar'
      )
    },
    headers() {
      return [
        {
          text: 'Institución',
          value: 'institucion_id',
          class: 'text-no-wrap',
          visible: this.headerOptions.institucion_id.visible,
          formatter: (v, item) =>
            this.payloadListaInstituciones[item.institucion_id] ||
            this.$formato.rut(item.institucion_id),
        },
        {
          text: 'Pago',
          value: 'fecha_pago',
          formatter: (v) => this.$formato.fecha(v, 'HH:mm:ss'),
        },
        {
          text: 'Envío mail',
          value: 'fecha_comprobante_pago_email',
          formatter: (v) => this.$formato.fecha(v, 'DD/MM HH:mm:ss'),
        },
        {
          text: this.headerOptions.identificador.text,
          value: 'pk',
        },
        {
          text: 'Vencimiento',
          value: 'fecha_vencimiento',
          formatter: (v) => this.$formato.fecha(v, 'L'),
        },
        {
          text: 'Monto pagado',
          value: 'montos_rel',
          cellClass: 'text-end',
        },
        {
          text: 'IRA',
          value: 'pago.ira',
          cellClass: 'text-caption',
          formatter: (v, item) => this.$payload.ira(this.pagoPagado(item).ira),
        },
      ]
    },
  },
  mounted() {
    this.updateHeaders()
  },
  methods: {
    beforeSearch(query) {
      if (!query.filters.fecha_pago_desde.value) {
        this.errors = { fecha_pago_desde: 'Fecha inválida' }
        return false
      }
      const fechaPago = query.filters.fecha_pago_desde.value
      console.log(fechaPago)
      query.filters.fecha_pago_desde.value = this.$dayjs.tz(fechaPago).startOf('day').toJSON()
      query.filters.fecha_pago_hasta.value = this.$dayjs.tz(fechaPago).endOf('day').toJSON()
      this.errors = {}
    },
    afterSearch(response) {
      this.updateHeaders()
      // const items = response.data.data || response.data
      // let numero = 0
      // for (numero = 0; numero < items.length; numero++) {
      //   items[numero].numero = numero + 1
      // }
      // return items
    },
    updateHeaders() {
      this.headerOptions.institucion_id.visible =
        !this.query.filter.institucion_id && this.$auth.can(['operador', 'mesa-de-ayuda'])
      this.headerOptions.identificador.text = this.$payload.tramite(
        this.slugTramite,
        'definicion'
      ).buscador?.nombrePk
    },
    pagoPagado(item) {
      return item.pagos?.find((pago) => pago.estado === 25) ?? { ira: 0 }
    },
  },
}
</script>
