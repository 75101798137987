<template>
  <div>
    <!--
            Formulario de contacto
    -->

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model.trim="contacto.nombre"
          outlined
          :hint="tramiteStatic.labelComprobantePago + ' se enviará a este nombre.'"
          label="Nombre y apellido"
          name="nombre"
          data-testid="nombre"
          maxlength="40"
          required
          :error-messages="errors.nombre"
          :rules="[
            (v) => !!v || 'Debes ingresar un nombre y apellido',
            (v) => (v && v.length >= 5) || 'El nombre debe ser más largo',
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model.trim="contacto.rut"
          type="text"
          outlined
          hint="Será usado para que te puedas identificar en caso de solicitar una reimpresión del documento."
          label="RUT (o pasaporte para extranjeros)"
          name="rut"
          data-testid="rut"
          maxlength="13"
          spellcheck="false"
          required
          :error-messages="errors.rut"
          :rules="[
            (v) => !!v || 'Debes ingresar un RUT o pasaporte válido',
            (v) => (v && v.length >= 6) || 'Debe ser más largo',
            // v => /^\s*\d[\d.]{2,}\.?\d{3}-[0-9kK]\s*$/.test(v) || 'Debes ingresar un RUT válido',
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model.trim="contacto.email"
          type="email"
          outlined
          :hint="tramiteStatic.labelComprobantePago + ' se enviará a este correo electrónico.'"
          label="Correo electrónico"
          name="email"
          inputmode="email"
          data-testid="email"
          maxlength="60"
          autocapitalize="off"
          required
          :error-messages="errors.email"
          :rules="[
            (v) => !!v || 'Debes ingresar un correo electrónico',
            (v) => /.+@.+\..+/.test(v) || 'Debes ingresar un correo electrónico válido',
          ]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    tramiteStatic: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    contacto: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {},
}
</script>
