<template>
  <v-dialog v-model="visible" persistent max-width="400">
    <v-card v-bind="$attrs" v-on="$listeners">
      <v-card-title class="text-center text-h5 text-slab">
        {{ cuenta.nombre }}
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <tbody>
            <template v-for="(valor, campo) in campos">
              <tr v-if="!!valor && valor !== 0" :key="campo">
                <th class="text-right pl-0 pr-2">
                  {{ campo }}
                </th>
                <td class="pl-2 pr-0">
                  {{ valor }}
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="mx-auto" color="primary" @click="visible = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    cuenta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    campos() {
      return {
        Tipo: this.cuenta.registro.tipo_vehiculo,
        Marca: this.cuenta.registro.marca,
        Modelo: this.cuenta.registro.modelo,
        Equipamiento: this.cuenta.registro.equipamiento,
        Color: this.cuenta.registro.color,
        Año: this.cuenta.registro.ano,
        Combustible: this.cuenta.registro.combustible,
        Cilindrada: this.cuenta.registro.cilindrada,
        Motor: this.cuenta.registro.motor,
        Chasis: this.cuenta.registro.chasis,
        Propietario: this.cuenta.registro.nombre_propietario,
      }
    },
    visible: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
