<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card class="mx-auto my-4" max-width="600">
        <v-card-title> Reporte de permisos </v-card-title>

        <v-card-text>
          <v-row>
            <v-col md="9">
              <z-input
                v-model="form.institucion_id"
                :items="payloadItemsMunicipalidades"
                tipo="autocomplete"
                label="Municipalidad"
                clearable
                :error-messages="errors.institucion_id"
              />
            </v-col>
            <v-col md="3">
              <z-input
                v-model="form.ano"
                :error-messages="errors.ano"
                label="Año del permiso"
                name="vehiculo_ano"
                type="number"
                min="1900"
                :max="new Date().getFullYear() + 1"
                hint="Ej. 2022"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.fecha_pago_desde"
                type="date"
                label="Desde"
                :error-messages="errors.fecha_pago_desde"
              />
            </v-col>
            <v-col>
              <z-input v-model="form.fecha_pago_hasta" type="date" label="Hasta" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="descargar()">
            <v-icon left> mdi-file-download-outline </v-icon>
            Generar reporte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <dialog-progress v-if="loading"> Generando reporte... </dialog-progress>
  </div>
</template>
<script>
import { notificar } from '@/admin/utils/notificar'
import { getError } from '@/utils/errors'
import { saveAs } from 'file-saver'
import ZInput from '@/components/Input/ZInput'
import DialogProgress from '@/admin/components/DialogProgress'

export default {
  components: { DialogProgress, ZInput },
  data: () => ({
    errors: {},
    loading: false,
    form: {
      fecha_pago_desde: '',
      fecha_pago_hasta: '',
      fecha_vencimiento: '',
      institucion_id: '',
    },
    slugTramite: '',
  }),
  computed: {},
  methods: {
    descargar() {
      this.loading = true
      this.errors = []

      if (!this.form.ano) {
        this.errors.ano = 'Campo requerido.'
        this.loading = false
        return false
      }

      if (!this.form.institucion_id) {
        this.errors.institucion_id = 'Campo requerido.'
        this.loading = false
        return false
      }

      this.$axios
        .get(
          `bum/reportes/exportar-permisos?
                                          fecha_pago_desde=${this.form.fecha_pago_desde}
                                          &fecha_pago_hasta=${this.form.fecha_pago_hasta}
                                          &ano=${this.form.ano}
                                          &institucion_id=${this.form.institucion_id}`,
          { responseType: 'blob' }
        )
        .then((response) => {
          saveAs(response.data, 'permisos-pagos.xlsx')
        })
        .catch(async (error) => {
          this.errors = await getError(error)

          notificar({
            message: 'Hubo un error.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
