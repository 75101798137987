<template>
  <div>
    <h2 class="text-h3 mx-auto" style="max-width: 500px">
      {{ $auth.institucion().nombre }}
    </h2>

    <v-card class="mx-auto my-4" max-width="600">
      <v-card-text>
        <input-tramite v-model="slugTramite" />
        <p>
          Mediante esta opción puede subir una plantilla PDF que se utilizará como fondo para todos
          los documentos PDF que se emitan en
          {{ $payload.tramite(slugTramite) }}.
        </p>
      </v-card-text>
    </v-card>

    <v-form @submit.prevent="save">
      <div v-if="habilitacion && habilitacion.id">
        <v-card class="mx-auto my-4" max-width="600">
          <v-card-title> Plantilla para comprobantes de pago </v-card-title>
          <v-card-text>
            <p v-if="habilitacion.fecha_plantilla_pdf">
              Esta es la plantilla PDF actualmente configurada para
              {{ $payload.tramite(slugTramite) }}.
            </p>
            <p v-else>
              No hay ninguna plantilla configurada para
              {{ $payload.tramite(slugTramite) }}.
            </p>
            <v-row v-if="habilitacion.fecha_plantilla_pdf">
              <v-col>
                <v-btn outlined color="primary" class="mr-2" @click="descargar()">
                  <v-icon left> mdi-file-download-outline </v-icon>
                  Plantilla PDF actual
                </v-btn>
                <v-btn text color="error" @click="eliminar()"> Eliminar </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn outlined color="primary" @click="descargar('/previsualizar')">
                  <v-icon left> mdi-file-download-outline </v-icon>
                  Previsualizar documento de ejemplo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto my-4" max-width="600">
          <v-card-title> Subir nueva plantilla PDF </v-card-title>
          <v-card-text>
            <p>
              El documento debe estar en formato PDF versión 1.4, tamaño carta y debiera ser
              inferior a 250 KB para así reducir problemas con el despacho en correos electrónicos.
            </p>
            <v-file-input
              v-model="archivo"
              accept=".pdf"
              show-size
              counter
              prepend-icon="mdi-paperclip"
              outlined
              label="Archivo PDF"
              hint="Tamaño máximo: 250 KB"
              persistent-hint
              :error-messages="errors.archivo"
            />
          </v-card-text>

          <v-card-actions>
            <btn-guardar-cancelar
              :disabled-guardar="!archivo || archivo.size > 256000"
              :loading="loading"
              texto-guardar="Subir PDF"
              type="submit"
              @cancelar="$router.push('/dashboard')"
            />
          </v-card-actions>
        </v-card>
      </div>
    </v-form>

    <z-alert
      v-if="tramite_id && JSON.stringify(habilitacion) === '{}'"
      width="600"
      class="mx-auto my-8"
    >
      Trámite no disponible
    </z-alert>

    <div
      v-if="tramite_id && habilitacion === null"
      style="width: 300px"
      class="my-16 mx-auto d-flex flex-column align-center"
    >
      <p class="text-body-1">Cargando...</p>
      <v-progress-linear color="red" indeterminate rounded height="6" />
    </div>
  </div>
</template>
<script>
import { notificar, notificarErrorResponse } from '@/admin/utils/notificar'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import InputTramite from '@/components/Input/InputTramite'
import ZAlert from '@/components/ZAlert'
import { getError } from '@/utils/errors'
import { saveAs } from 'file-saver'

export default {
  components: { ZAlert, InputTramite, BtnGuardarCancelar },
  data: () => ({
    archivo: null,
    errors: [],
    loading: false,
    habilitaciones: {},
    slugTramite: '',
  }),
  computed: {
    tramite_id() {
      return this.$payload.tramite(this.slugTramite, 'id')
    },
    habilitacion_id() {
      const institucionId = this.$auth.institucion().id
      const tramiteId = this.tramite_id.toString().padStart(3, '0')
      return `${institucionId}${tramiteId}`
    },
    habilitacion() {
      return this.habilitaciones[this.slugTramite] || null
    },
  },
  watch: {
    slugTramite(value) {
      if (this.habilitacion === null) {
        this.getHabilitacion(this.tramite_id)
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getHabilitacion(this.tramite_id)
    })
  },
  methods: {
    notificarErrorResponse(event) {
      notificarErrorResponse(event)
    },
    notificar(event) {
      notificar(event)
    },
    save() {
      const formData = new FormData()
      formData.append('archivo', this.archivo)

      this.loading = true
      const headers = { 'Content-Type': 'multipart/form-data' }
      this.$axios
        .post(
          `tramites/${this.slugTramite}/habilitaciones/${this.habilitacion_id}/archivo`,
          formData,
          { headers }
        )
        .then((response) => {
          this.$set(this.habilitaciones, this.slugTramite, response.data.data || response.data)
          notificar('El PDF ha sido guardado.')
        })
        .catch((error) => {
          this.errors = getError(error)
          console.log(this.errors)
          notificar({
            message: `No se pudo subir el PDF: ${this.errors._message || this.errors}`,
            severity: 'error',
          })
        })
        .finally(() => {
          this.archivo = null
          this.loading = false
        })
    },
    eliminar() {
      this.loading = true
      this.$axios
        .delete(`tramites/${this.slugTramite}/habilitaciones/${this.habilitacion_id}/archivo`)
        .then((response) => {
          console.log(response)
          this.$set(this.habilitaciones, this.slugTramite, response.data.data || response.data)
        })
        .catch((error) => {
          error = getError(error)
          notificar({
            message: 'Eliminación fallida.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    descargar(path = '') {
      this.loading = true
      this.$axios
        .get(`tramites/${this.slugTramite}/habilitaciones/${this.habilitacion_id}/archivo${path}`, {
          responseType: 'blob',
        })
        .then((response) => {
          saveAs(response.data, `${this.slugTramite}.pdf`)
        })
        .catch((error) => {
          error = getError(error)
          notificar({
            message: 'Archivo PDF no disponible.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getHabilitacion(id) {
      if (!id) {
        console.log('getHabilitacion(): id vacío')
        return
      }
      this.loading = true
      id = id.toString().padStart(3, '0')
      this.$axios
        .get(`tramites/${this.slugTramite}/habilitaciones/${this.$auth.institucion().id}${id}`)
        .then((response) => {
          console.log(response.data)
          this.$set(this.habilitaciones, this.slugTramite, response.data.data || response.data)
        })
        .catch((error) => {
          error = getError(error)
          console.log(error)
          this.$set(this.habilitaciones, this.slugTramite, {})
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
