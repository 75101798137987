<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr v-if="$payload.institucion(item.institucion_id)">
          <th style="width: 160px">Institución</th>
          <td>{{ $payload.institucion(item.institucion_id) }}</td>
        </tr>
        <tr>
          <th>
            {{ $payload.tramite(item.tramite_id, 'definicion', 'id').buscador.nombrePk }}
          </th>
          <td>{{ item.pk }}</td>
        </tr>
        <tr>
          <th>Rut</th>
          <td>{{ $formato.rut(item.rut) }}</td>
        </tr>
        <tr>
          <th>Monto neto</th>
          <td>{{ $formato.moneda(item.monto) }}</td>
        </tr>
        <tr>
          <th>Fecha de vencimiento</th>
          <td>{{ $formato.fecha(item.fecha_vencimiento, 'LL') }}</td>
        </tr>
        <!--
        <tr>
          <th>Fecha de inicio de pago</th>
          <td>{{ $formato.fechaHora(item.fecha_inicio_pago) }}</td>
        </tr>
        -->
        <tr v-if="item.fecha_pago">
          <th>Fecha de pago</th>
          <td>{{ $formato.fechaHora(item.fecha_pago) }}</td>
        </tr>
        <tr>
          <th>Estado</th>
          <td>
            <chip-cuenta :cuenta="item" small />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="text-h6 mt-4 mb-2">Datos</div>
    <v-card class="mx-10">
      <cuenta-tabla-registro dense :cuenta="item" />
    </v-card>
  </div>
</template>

<script>
import ChipCuenta from '@/admin/components/ChipCuenta'
import CuentaTablaRegistro from '@/admin/components/CuentaTablaRegistro'

export default {
  components: { CuentaTablaRegistro, ChipCuenta },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
  },
}
</script>
