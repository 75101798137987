<template>
  <v-dialog persistent :value="true" max-width="350">
    <v-card class="py-4">
      <v-card-text>
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            <slot>
              <div class="text-body-1 mt-4">Cargando...</div>
            </slot>
          </v-col>
          <v-col cols="8">
            <v-progress-linear color="red" indeterminate rounded height="6" />
          </v-col>
        </v-row>
      </v-card-text>
      <!--
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
        -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
}
</script>

<style>
.input-buscador input {
  font-size: 1.25rem;
}
</style>
