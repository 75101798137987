<template>
  <div>
    <z-form-search
      ref="zFormSearch"
      v-model="items"
      :query="query"
      :url="`/tramites/${slugTramite}/reportes/ultimos-pagos-por-dia`"
      :headers="headers"
      :card-data-table-props="{
        style: 'width: 700px',
      }"
      :on-after-search="onAfterSearch"
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <input-tramite v-model="slugTramite" />
            </v-col>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="6" md="5">
              <z-input
                v-model="query.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>

            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                :disabled="!slugTramite"
                :loading="loading"
                color="primary"
                outlined
                type="submit"
              >
                Buscar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #item.monto_total="{ item }">
        <montos-tooltip :montos="item">
          {{ $formato.moneda(item.monto_total) }}
        </montos-tooltip>
      </template>

      <template #item.id="{ item }">
        <z-btn
          color="grey darken-1"
          :disabled="false"
          icon="mdi-text-box-search-outline"
          title="Ver detalle"
          @click="$router.push(`/pagos?fecha=${item.fecha}&institucion_id=${query.institucion_id}`)"
        />
      </template>
    </z-form-search>

    <div v-if="items && items.length > 0" style="width: 80%" class="mx-auto">
      <vue-apex-charts
        class="my-16"
        type="area"
        height="350"
        :options="pagosUltimosDias.chartOptions"
        :series="pagosUltimosDias.series"
      />
      <vue-apex-charts
        class="my-16"
        type="line"
        height="350"
        :options="pagosYMontosPorDia.chartOptions"
        :series="pagosYMontosPorDia.series"
      />

      <vue-apex-charts
        class="my-16"
        type="area"
        height="350"
        :options="pagosPorDia.chartOptions"
        :series="pagosPorDia.series"
      />
      <vue-apex-charts
        style="width: 450px"
        class="mx-auto my-16"
        type="pie"
        width="450"
        :options="pagosPorIra.chartOptions"
        :series="pagosPorIra.series"
      />
    </div>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZInput from '@/components/Input/ZInput'
import InputTramite from '@/components/Input/InputTramite'
import ZFormSearch from '@/components/ZFormSearch'
import VueApexCharts from 'vue-apexcharts'
import MontosTooltip from '@/components/MontosTooltip'

export default {
  components: {
    MontosTooltip,
    ZFormSearch,
    InputTramite,
    ZInput,
    ZBtn,
    VueApexCharts,
  },
  data() {
    return {
      items: [],
      itemsPie: [],
      itemActivo: {},
      slugTramite: this.$route.query.tramite ?? '',
      errors: {},
      query: {
        institucion_id: '',
      },
      iraSerie: [],
      iraLabel: [],
      /* pagosPorIra: {
          series: [21, 19, 16, 15, 12, 4],
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ['Banco de Chile', 'Banco Santander', 'BancoEstado', 'BCI', 'TRANSBANK', 'Otros'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 300,
                },
                legend: {
                  position: 'bottom',
                },
              },
            }],
          },
        }, */
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Fecha',
          value: 'fecha',
          cellClass: 'text-end',
          formatter: (v) => this.$formato.fechaEnStgo(v, 'dddd LL'),
        },
        {
          text: 'Pagos',
          value: 'count',
          cellClass: 'text-end',
          formatter: (v) => this.$formato.numero(v),
        },
        {
          text: 'Cuentas pagadas',
          value: 'cantidad_cuentas',
          cellClass: 'text-end',
          formatter: (v) => this.$formato.numero(v),
        },
        {
          text: 'Monto pagado',
          value: 'monto_total',
          cellClass: 'text-end',
        },
        {
          value: 'id',
        },
      ]
    },
    pagosUltimosDias() {
      return {
        series: [
          {
            name: 'Pagos',
            data: this.items.map((i) => i.cantidad_cuentas).slice(0, 14),
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: 'Pagos por mes',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: this.items
              .map((i) => this.$dayjs.tz(i.fecha, 'YYYY-MM-DD', "America/Santiago").format('D/M'))
              .slice(0, 14),
          },
        },
      }
    },
    pagosYMontosPorDia() {
      return {
        series: [
          {
            name: 'Monto recaudado',
            type: 'column',
            data: this.items.map((i) => i.monto_total).slice(0, 14),
            // [440000, 505000, 414000, 671000, 227000, 413000, 201000, 352000, 752000, 320000, 257000, 160000],
          },
          {
            name: 'Pagos',
            type: 'line',
            data: this.items.map((i) => i.cantidad_cuentas).slice(0, 14),
            // [23, 24, 20, 34, 12, 22, 17, 31, 42, 22, 12, 16],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
          },
          stroke: {
            width: [0, 4],
          },
          title: {
            text: 'Recaudación y pagos por día',
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: this.items.map((i) => i.fecha).slice(0, 14),
          // ['01 Jun 2021', '02 Jun 2021', '03 Jun 2021', '04 Jun 2021', '05 Jun 2021', '06 Jun 2021', '07 Jun 2021', '08 Jun 2021', '09 Jun 2021', '10 Jun 2021', '11 Jun 2021', '12 Jun 2021'],
          xaxis: {
            type: 'datetime',
          },
          yaxis: [
            {
              title: {
                text: 'Pesos',
              },
              labels: {
                formatter: function (val) {
                  return '$ ' + new Intl.NumberFormat('de').format(val)
                },
              },
            },
            {
              opposite: true,
              title: {
                text: 'Cantidad',
              },
            },
          ],
        },
      }
    },
    pagosPorDia() {
      return {
        series: [
          {
            name: 'Pagos realizados',
            data: this.items.map((i) => [
              this.$dayjs.tz(i.fecha, 'YYYY-MM-DD', 'America/Santiago').valueOf(),
              i.monto_total,
            ]),
          },
        ],
        chartOptions: {
          chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Pagos por día',
            align: 'left',
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100],
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return (val / 1000000).toFixed(0)
              },
            },
            title: {
              text: 'Pagos',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: false,
            y: {
              formatter: function (val) {
                return (val / 1).toFixed(0)
              },
            },
          },
        },
      }
    },
    pagosPorIra() {
      return {
        series: this.cantidadIra(),
        chartOptions: {
          chart: {
            type: 'pie',
            height: 380,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
          title: {
            text: 'Pagos por institución recaudadora',
            align: 'left',
          },
          labels: this.ira(),
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 300,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      }
    },
    dates() {
      return this.items.map((i) => [this.$dayjs(i.fecha, 'YYYY-MM-DD', 'America/Santiago').valueOf(), i.monto_total])
      /*
        const dias = 180
        var ts2 = this.$dayjs().subtract(dias, 'days').valueOf()
        var dates = []
        // var spikes = [5, -5, 3, -3, 8, -8]
        for (var i = 0; i < dias; i++) {
          ts2 = ts2 + 86400000
          const dia = this.$dayjs(ts2).format('D')
          var innerArr = [ts2, parseInt(Math.random() * 100) + dia * 3]
          dates.push(innerArr)
        }
        console.log(dates)
        return dates */
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.zFormSearch.search())
  },
  methods: {
    cantidadIra() {
      this.iraSerie = []
      this.itemsPie.map((i) => {
        console.log(i)
        this.iraSerie.push(i.cantidad)
      })
      return this.iraSerie
    },
    ira() {
      this.iraLabel = []
      this.itemsPie.map((i) => {
        this.iraLabel.push(this.$payload.ira(i.ira))
      })
      return this.iraLabel
    },
    onAfterSearch(response) {
      this.itemsPie = response.data.pie

      console.log(this.itemsPie)
    },
  },
}
</script>
