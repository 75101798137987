var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('z-mantenedor',{ref:"mantenedor",attrs:{"botones-listar":[
      {
        title: 'Ver historial',
        icon: 'mdi-history',
        click: _vm.mostrarHistorial,
        posicion: 'menu',
      },
    ],"botones-ver":[{ title: 'Ver historial', icon: 'mdi-history', click: _vm.mostrarHistorial }],"botones-principales-crear":[
      {
        label: 'Validar',
        click: _vm.validar,
        loading: false,
        disabled: () => _vm.certificadoValidado === true,
      },
    ],"boton-principal-crear-disabled":_vm.certificadoValidado !== true,"on-before-dialog-edit":_vm.onBeforeDialogEdit,"campo-titulo-dialog":(v) => v.nombre,"headers":_vm.headers,"query":_vm.query,"url":"/usuarios/firmantes","search-url":"/usuarios/firmantes/search","search-method":"post","campo-titulo-dialog-crear":"Crear firmante","search-inicial":"","on-after-search":_vm.afterSearch,"on-after-save":_vm.afterSave,"on-after-delete":_vm.afterDelete,"texto-filtrar":"Filtrar","texto-hits":"%s firmantes.","texto-no-hit":"No se encontraron firmantes.","texto-un-hit":"Un usuario."},on:{"created":function($event){return _vm.$emit('notificar', 'Firmante creado.')},"deleted":function($event){return _vm.$emit('notificar', 'Firmante eliminado.')},"updated":function($event){return _vm.$emit('notificar', 'Firmante guardado.')},"created:error":function($event){return _vm.$emit('notificar:error', 'Error al intentar crear.')},"update:error":function($event){return _vm.$emit('notificar:error', 'Error al intentar guardar.')},"deleted:error":function($event){return _vm.$emit('notificar:error', 'Error al intentar eliminar.')}},scopedSlots:_vm._u([{key:"buscador",fn:function({ loading, search, create }){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return search.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[(_vm.$auth.can(['operador', 'mesa-de-ayuda']))?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('z-input',{attrs:{"items":_vm.payloadItemsInstituciones,"tipo":"autocomplete","label":"Institución","clearable":""},model:{value:(_vm.query.filter.institucion_id),callback:function ($$v) {_vm.$set(_vm.query.filter, "institucion_id", $$v)},expression:"query.filter.institucion_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('v-select',{attrs:{"items":[
                { text: 'Activo', value: true },
                { text: 'Deshabilitado', value: false },
              ],"clearable":"","dense":"","label":"¿Activo?","outlined":""},model:{value:(_vm.query.filter.activo),callback:function ($$v) {_vm.$set(_vm.query.filter, "activo", $$v)},expression:"query.filter.activo"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex"},[_c('v-spacer'),_c('z-btn',{attrs:{"loading":loading,"color":"primary","outlined":"","type":"submit"}},[_vm._v(" Actualizar ")]),_c('z-btn',{staticClass:"ml-1",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.certificadoValidado = null
                create({ activo: true })}}},[_vm._v(" Crear ")])],1)],1)],1)]}},{key:"item.activo",fn:function({ item }){return [_c('z-chip',{attrs:{"color":item.activo ? 'success' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.activo ? 'Activo' : 'Deshabilitado')+" ")])]}},{key:"form",fn:function({ accion, form, errors }){return [_c('firmantes-form',{attrs:{"certificado":_vm.certificado,"certificado-validado":_vm.certificadoValidado,"accion":accion,"errors":errors,"value":form},on:{"input":function($event){form = $event}}})]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),(_vm.historialVisible)?_c('dialog-historial',{attrs:{"id":_vm.itemActivo.id,"titulo":`${_vm.itemActivo.nombre}`,"microservicio":"usuarios","clase":"firmantes"},on:{"cerrar":function($event){_vm.historialVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }