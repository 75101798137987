<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Ver historial',
          icon: 'mdi-history',
          click: mostrarHistorial,
          posicion: 'menu',
        },
        {
          title: 'Personificar',
          visible: (item) =>
            $auth.can(['operador', 'mesa-de-ayuda']) && item.login !== $auth.user().login,
          posicion: 'menu',
          click: loginAs,
        },
      ]"
      :botones-ver="[
        { title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial },
        { title: 'Regenerar contraseña', icon: 'mdi-lock-plus-outline', click: regenerarPassword },
        {
          title: 'Personificar',
          icon: 'mdi-account-supervisor-circle-outline',
          visible: (item) =>
            $auth.can(['operador', 'mesa-de-ayuda']) && item.login !== $auth.user().login,
          click: loginAs,
        },
      ]"
      :campo-titulo-dialog="(v) => `${v.name} ${v.apellido}`"
      :headers="headers"
      :query="query"
      url="/usuarios/users?include=institucion,roles"
      search-url="/usuarios/users/search?include=institucion,roles"
      search-method="post"
      campo-titulo-dialog-crear="Crear usuario"
      search-inicial
      :on-before-search="beforeSearch"
      :on-after-search="afterSearch"
      texto-filtrar="Filtrar"
      texto-hits="%s usuarios."
      texto-no-hit="No se encontraron usuarios."
      texto-un-hit="Un usuario."
      @created="$emit('notificar', 'Usuario creado.')"
      @deleted="$emit('notificar', 'Usuario eliminado.')"
      @updated="$emit('notificar', 'Usuario guardado.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="6" md="5">
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="5" md="3">
              <v-select
                v-model="query.filter.activo"
                :items="[
                  { text: 'Activo', value: true },
                  { text: 'Deshabilitado', value: false },
                ]"
                clearable
                dense
                label="¿Activo?"
                outlined
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
              <z-btn
                class="ml-1"
                color="primary"
                outlined
                @click="create({ activo: true, principal: true })"
              >
                Crear
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.name="{ item }">
        {{ item.name }} {{ item.apellido }}
        <div class="font-weight-light text-body-2">
          {{ item.login }}
        </div>
      </template>

      <template #item.cargo="{ item }">
        {{ item.cargo }}
        <div class="font-weight-light text-caption">
          {{
            item.roles
              .map((r) => r.name)
              .join(', ')
              .replaceAll('-', ' ')
          }}
        </div>
      </template>

      <template #item.activo="{ item }">
        <z-chip :color="item.activo ? 'success' : 'red'" dark>
          {{ item.activo ? 'Activo' : 'Deshabilitado' }}
        </z-chip>
      </template>

      <template v-if="$vuetify.breakpoint.xsOnly" #item="{ item, viewItem }">
        <v-card class="mx-auto" flat tile @click="$emit('click', $event)">
          <v-list class="py-0">
            <v-list-item @click="viewItem(item)">
              <v-list-item-avatar
                :color="item.activo ? 'success' : 'red'"
                :title="item.activo ? 'Activo' : 'Deshabilitado'"
                horizontal
                left
              >
                <v-icon v-if="item.activo"> mdi-account </v-icon>
                <v-icon v-else> mdi-account-off </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="text-truncate font-weight-bold"
                  style="max-width: calc(100vw - 155px)"
                >
                  {{ item.name }} {{ item.apellido }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div
                    class="text-truncate text-capitalize font-weight-light text-body-2"
                    style="max-width: calc(100vw - 155px)"
                  >
                    {{ item.roles.map((r) => r.name).join(', ') }}
                    •
                    {{ item.institucion.nombre }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="align-self-start">
                <span class="text-subtitle-2">
                  {{ item.login }}
                </span>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </template>
      <template #view="{ item }">
        <users-view :item="item" />
      </template>

      <template #form="{ accion, form, errors }">
        <users-form :accion="accion" :errors="errors" :value="form" @input="form = $event" />
      </template>
    </z-mantenedor>

    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.id"
      :titulo="`${itemActivo.name} ${itemActivo.apellido}`"
      microservicio="usuarios"
      clase="user"
      @cerrar="historialVisible = false"
    />
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZChip from '@/components/ZChip'
import UsersView from '@/admin/views/Admin/UsersView'
import UsersForm from '@/admin/views/Admin/UsersForm'
import * as API from '@/admin/services/API'
import { getError } from '@/utils/errors'
import DialogHistorial from '@/admin/components/DialogHistorial'
import ZInput from '@/components/Input/ZInput'

export default {
  components: { ZInput, DialogHistorial, ZChip, ZMantenedor, ZBtn, UsersView, UsersForm },
  data() {
    return {
      items: [],
      itemActivo: {},
      historialVisible: false,
      headerOptions: {
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      query: {
        paginationDisabled: true,
        filter: {
          activo: null,
          institucion_id: null,
        },
        filters: {
          institucion_id: null,
        },
        sort: {
          0: { field: 'institucion.nombre', direction: 'asc' },
        },
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Institución',
          value: 'institucion',
          visible: this.headerOptions.institucion_id.visible,
          formatter: (v) => `${v.nombre}`,
        },
        {
          text: 'Nombre',
          value: 'name',
        },
        {
          text: 'Cargo y roles',
          value: 'cargo',
          cellClass: 'text-capitalize',
          // formatter: (v) => v.map(r => r.name).join(', '),
        },
        { text: 'Activo', value: 'activo' },
        // {
        //   text: 'Última edición',
        //   value: 'updated_at',
        //   cellClass: 'text-no-wrap',
        //   formatter: (v) => this.$formato.fechaFromNow(v, 23, 'hour', 'L HH:mm'),
        // },
      ]
    },
  },
  created() {
    this.query.filter.institucion_id =
      parseInt(this.$route.query.institucion_id) || this.$auth.institucion().id
  },
  methods: {
    regenerarPassword(item) {
      if (confirm('¿Deseas enviarle por email un nuevo password aleatorio?')) {
        API.apiClient
          .post(`usuarios/users/${item.id}/regenerar-password`)
          .then((response) => {
            // TODO: mensaje snackbar de éxito
            this.$refs.mantenedor.ocultarDialog()
          })
          .catch((error) => {
            // TODO: mensaje snackbar de error
            alert(getError(error) ?? 'Ocurrió un error')
          })
      }
    },
    loginAs(user) {
      this.$store
        .dispatch('auth/login', { id: user.id, userOriginal: true })
        .then(() => {
          if (this.$route.path !== '/dashboard') {
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.log(getError(error))
          this.logout()
        })
    },
    afterSearch() {
      this.updateHeaders()
    },
    updateHeaders() {
      this.headerOptions.institucion_id.visible =
        !this.query.filter.institucion_id && this.$auth.can(['operador', 'mesa-de-ayuda'])
    },
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
    beforeSearch(query) {
      // Si la institucion es nula se filtrara para sacar los resultados que correspondan a usuarios Ciudadanos.
      if (query.filter.institucion_id === null) {
        query.filters.institucion_id = { field: 'institucion_id', operator: '!=', value: 1 }
      }
    },
  },
}
</script>
