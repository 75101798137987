<template>
  <v-chip
    :color="color(pago.estado)"
    :outlined="outlined(pago.estado)"
    dark
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $payload.estadoPago(pago.estado) }}
  </v-chip>
</template>

<script>
export default {
  props: {
    pago: {
      type: Object,
      required: true,
    },
  },
  methods: {
    color(estado) {
      return (
        {
          11: 'info',
          25: 'success',
          26: 'success',
          27: 'success',
        }[estado] ?? 'grey'
      )
    },
    outlined(estado) {
      return estado < 25
    },
  },
}
</script>

<style scoped></style>
