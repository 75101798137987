<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr>
          <th>
            {{ $payload.tramite(item.tramite_id, 'definicion', 'id').buscador.nombrePk }}
          </th>
          <td>{{ item.pk }}</td>
        </tr>
        <tr>
          <th>Rut</th>
          <td>{{ $formato.rut(item.rut) }}</td>
        </tr>
        <tr>
          <th>UTM</th>
          <td>{{ $formato.numero(item.monto_utm) }}</td>
        </tr>
        <tr>
          <th>Fecha de audiencia</th>
          <td>{{ $formato.fecha(item.fecha_audiencia, 'LL') }}</td>
        </tr>
        <tr>
          <th>Fecha de vencimiento</th>
          <td>{{ $formato.fecha(item.fecha_vencimiento, 'LL') }}</td>
        </tr>
        <tr>
          <th>Estado</th>
          <td>
            <seguimiento-chip-estado :cuenta="item" small />
          </td>
        </tr>
        <tr v-if="item.fecha_pago">
          <th>Fecha de pago</th>
          <td>{{ $formato.fecha(item.fecha_pago, 'LL, HH:mm') }} hrs.</td>
        </tr>
        <tr v-if="item.fecha_pago">
          <th>Monto</th>
          <td>{{ $formato.moneda(item.monto) }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="text-h6 mt-4 mb-2">Detalle</div>

    <v-card class="mx-10">
      <cuenta-tabla-registro :cuenta="item" />
    </v-card>
  </div>
</template>

<script>
import SeguimientoChipEstado from '@/admin/views/Infracciones/SeguimientoChipEstado'
import CuentaTablaRegistro from '@/admin/components/CuentaTablaRegistro'

export default {
  components: { CuentaTablaRegistro, SeguimientoChipEstado },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
  },
}
</script>
