<template>
  <div>
    <v-form @submit.prevent="crear">
      <v-card max-width="600" class="mx-auto mb-8">
        <v-card-text>
          <input-tramite v-model="slugTramite" :tipo-institucion="3" />
        </v-card-text>
      </v-card>

      <v-card width="600" class="mx-auto">
        <v-card-text>
          <cuentas-form
            v-if="slugTramite"
            accion=""
            :errors="errors"
            :value="cuenta"
            @input="cuenta = $event"
          />
        </v-card-text>
        <v-card-actions>
          <z-btn color="primary" :disabled="!slugTramite" :loading="loading" type="submit">
            Crear infracción
          </z-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import CuentasForm from '@/admin/views/Cuentas/CuentasForm'
import InputTramite from '@/components/Input/InputTramite'
import { getError } from '@/utils/errors'

export default {
  components: {
    InputTramite,
    ZBtn,
    CuentasForm,
  },
  data() {
    return {
      loading: false,
      cuenta: {
        registro: {},
      },
      errors: {},
      slugTramite: this.$route.query.tramite ?? '',
    }
  },
  computed: {
    tramite() {
      return this.$tramites.find((t) => t.slug === this.slugTramite)
    },
  },
  watch: {
    slugTramite(slug) {
      this.cuenta = { ...this.cuenta, tramite_id: this.tramite.id }
    },
  },
  mounted() {},
  methods: {
    crear() {
      this.loading = true
      this.errors = {}

      if (this.slugTramite === 'infracciones-presenciales') {
        this.cuenta.registro.porcentaje_descuento = 0.25

        if (this.cuenta.registro?.clasificacion === 'Gravisima') {
          this.cuenta.registro.porcentaje_descuento = 0
        }
      }

      this.$axios
        .post(`/tramites/${this.slugTramite}/cuentas`, this.cuenta)
        .then((response) => {
          const cuenta = response.data.data
          this.$emit('notificar', `Infracción ${cuenta.pk_label} creada`)
          this.$router.push(
            `/infracciones/seguimiento?tramite=${this.slugTramite}&search=${cuenta.pk}`
          )
          // this.cuenta = {
          //   tramite_id: this.tramite.id,
          //   registro: {},
          // }
        })
        .catch((error) => {
          this.errors = getError(error)
          this.$emit('notificar:error', 'No se pudo crear la infracción.')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
