<template>
  <div>
    <v-card max-width="600" class="mx-auto mb-8">
      <v-card-text>
        <input-tramite v-model="slugTramite" />
      </v-card-text>
    </v-card>

    <buscador
      v-if="slugTramite && visible"
      :tramite="slugTramite"
      :institucion="`${$auth.user().institucion.id}`"
      modo="admin"
      @pago-iniciado="pagoIniciado"
    />
  </div>
</template>

<script>
import Buscador from '@/portal/components/Buscador'
import InputTramite from '@/components/Input/InputTramite'
import { notificar } from '@/admin/utils/notificar'

export default {
  components: {
    InputTramite,
    Buscador,
  },
  data() {
    return {
      items: [],
      visible: true,
      itemDetalle: {},
      slugTramite: this.$route.query.tramite ?? '',
    }
  },
  computed: {},
  watch: {
    slugTramite(value) {
      this.visible = false
      this.$nextTick(() => {
        this.visible = true
      })
    },
  },
  mounted() {},
  methods: {
    pagoIniciado(data) {
      console.log(data)
      this.visible = false
      notificar({
        message: 'Pago realizado por ' + this.$formato.moneda(data.pago?.monto_total),
        severity: 'success',
      })
      this.$nextTick(() => {
        this.visible = true
      })
    },
  },
}
</script>
