var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._b({attrs:{"hide-details":"","label":"Juzgado","items":[
    { text: '1º ' + _vm.sufijo, value: 1 },
    { text: '2º ' + _vm.sufijo, value: 2 },
    { text: '3º ' + _vm.sufijo, value: 3 },
    { text: '4º ' + _vm.sufijo, value: 4 },
    { text: '5º ' + _vm.sufijo, value: 5 },
  ],"outlined":""},model:{value:(_vm.jpl),callback:function ($$v) {_vm.jpl=$$v},expression:"jpl"}},'v-select',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }