<template>
  <v-chip
    :color="color(cuenta.estado_infraccion)"
    :outlined="outlined(cuenta.estado_infraccion)"
    dark
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ label(cuenta.estado_infraccion) }}
  </v-chip>
</template>

<script>
export default {
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    label(estado) {
      return this.$payloadStatic.estadosInfraccion()[estado] || ''
    },
    color(estado) {
      return (
        {
          1: 'info',
          5: 'success',
          9: 'warning',
          10: 'info',
          15: 'grey',
          19: 'warning',
          25: 'grey',
        }[estado] ?? 'grey'
      )
    },
    outlined(estado) {
      return true
    },
  },
}
</script>
