<template>
  <div>
    <v-card-text>
      <v-item-group v-model="cuotas" mandatory>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-item v-slot="{ active, toggle }" :value="false">
                <buscador-opciones-pago-ficha
                  :active="active"
                  :monto="totalCuentas + totalMultas"
                  @click="toggle"
                  @keypress="toggle"
                >
                  <template #subtitulo>
                    {{ tramiteStatic.parrafo('{nombre} complet{o}') }} ({{
                      $formato.moneda(totalCuentas)
                    }})
                    <span v-if="totalMultas > 0">
                      y multas ({{ $formato.moneda(totalMultas) }})
                    </span>
                  </template>
                  Pagarás {{ tramiteStatic.parrafo('{el} {nombre} complet{o}')
                  }}<span v-if="totalMultas > 0"> y las multas</span>.
                </buscador-opciones-pago-ficha>
              </v-item>
            </v-col>
            <v-col cols="12" sm="6">
              <v-item v-slot="{ active, toggle }" :value="true">
                <buscador-opciones-pago-ficha
                  :active="active"
                  :monto="totalCuentasPrimeraCuota + totalMultas"
                  @click="toggle"
                  @keypress="toggle"
                >
                  <template #subtitulo>
                    Primera cuota ({{ $formato.moneda(totalCuentasPrimeraCuota) }})
                    <span v-if="totalMultas > 0">
                      y multas ({{ $formato.moneda(totalMultas) }})
                    </span>
                  </template>
                  La deuda para la segunda cuota será de
                  {{ $formato.moneda(totalCuentas - totalCuentasPrimeraCuota) }}.
                </buscador-opciones-pago-ficha>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card-text>
  </div>
</template>

<script>
import BuscadorOpcionesPagoFicha from '@/portal/components/BuscadorOpcionesPagoFicha'
export default {
  components: { BuscadorOpcionesPagoFicha },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    totalAPagar: {
      type: Number,
      required: true,
    },
    totalMultas: {
      type: Number,
      required: true,
    },
    totalCuentas: {
      type: Number,
      required: true,
    },
    totalCuentasPrimeraCuota: {
      type: Number,
      required: true,
    },
    tramiteStatic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },

  computed: {
    cuotas: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
