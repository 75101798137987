<template>
  <div>
    <h2 class="text-h3 mx-auto" style="max-width: 500px">
      {{ $auth.institucion().nombre }}
    </h2>

    <v-card class="mx-auto my-4" max-width="500">
      <v-card-text>
        <input-tramite
          v-model="slugTramite"
          :filter="
            (t) =>
              ![
                'pagos-genericos',
                'multas',
                'pagos-de-prueba',
                'permisos-de-circulacion-primer-permiso',
              ].includes(t.slug)
          "
        />
      </v-card-text>
    </v-card>

    <template v-if="JSON.stringify(habilitacion) !== '{}'">
      <v-card class="mx-auto my-8 pb-4" min-width="800">
        <v-card-title> Enlace al buscador </v-card-title>

        <v-card-subtitle>
          Selecciona el botón que deseas instalar en el sitio web de la municipalidad.
        </v-card-subtitle>

        <div
          v-for="banner in [
            { filename: '1.png', width: 300, height: 251 },
            { filename: '2.png', width: 421, height: 181 },
            { filename: '3.png', width: 985, height: 171 },
          ]"
          :key="banner.filename.replaceAll('.', '')"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto my-4"
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 8 : 1"
              :width="banner.width"
              :height="banner.height"
              @click="showDialog(banner)"
            >
              <v-img contain :src="`${www}/banners/${slugTramite}/${banner.filename}`" />
            </v-card>
          </v-hover>
        </div>
      </v-card>
    </template>

    <z-alert
      v-if="tramite_id && JSON.stringify(habilitacion) === '{}'"
      width="500"
      ma
      class="mx-auto my-8"
    >
      Trámite no disponible
    </z-alert>

    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <dialog-toolbar @close="dialog = false"> Banner con enlace al buscador </dialog-toolbar>
        <v-card-subtitle>
          Copia el siguiente código HTML e insertalo en el sitio web.
        </v-card-subtitle>
        <v-card-text>
          <v-card dark>
            <v-card-text class="text-caption secondary">
              {{ html }}
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--

    Validación de documentos

    -->

    <v-card class="mx-auto my-8" min-width="800">
      <v-card-title> Enlace para validar documentos </v-card-title>

      <v-card-subtitle>
        Selecciona el botón que deseas instalar en el sitio web de la municipalidad. (El enlace es
        el mismo para todos los trámites)
      </v-card-subtitle>

      <v-row justify="space-around">
        <v-col
          v-for="banner in [
            { filename: '1.png', width: 158, height: 68 },
            { filename: '2.png', width: 176, height: 100 },
            { filename: '3.png', width: 161, height: 136 },
            { filename: '4.png', width: 163, height: 191 },
          ]"
          :key="banner.filename.replaceAll('.', '')"
          cols="12"
          sm="6"
          md="3"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto"
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 8 : 1"
              :width="banner.width"
              :height="banner.height"
              @click="showDialogValidar(banner)"
            >
              <v-img contain :src="`${www}/banners/validar/${banner.filename}`" />
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialogValidar" persistent width="500">
      <v-card>
        <dialog-toolbar @close="dialogValidar = false">
          Banner con enlace para validar documentos
        </dialog-toolbar>
        <v-card-subtitle>
          Copia el siguiente código HTML e insertalo en el sitio web.
        </v-card-subtitle>
        <v-card-text>
          <v-card dark>
            <v-card-text class="text-caption secondary">
              {{ htmlValidar }}
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text @click="dialogValidar = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InputTramite from '@/components/Input/InputTramite'
import ZAlert from '@/components/ZAlert'
import DialogToolbar from '@/components/DialogToolbar'

export default {
  components: { DialogToolbar, ZAlert, InputTramite },
  data: () => ({
    dialog: false,
    dialogValidar: false,
    habilitacion: { inicializando: true },
    slugTramite: '',
    bannerSeleccionado: {},
  }),
  computed: {
    tramite_id() {
      return this.$payload.tramite(this.slugTramite, 'id')
    },
    www() {
      return process.env.VUE_APP_WWW_URL || window.location.origin
    },
    html() {
      return `<a href="${this.www}/tramites/${this.slugTramite}/${
        this.$auth.institucion().id
      }/buscar" rel="external"><img
          alt="Buscar" src="${this.www}/banners/${this.slugTramite}/${
        this.bannerSeleccionado.filename
      }"
          style="border:0" width="${this.bannerSeleccionado.width}" height="${
        this.bannerSeleccionado.height
      }" /></a>`
    },
    htmlValidar() {
      return `<a href="${this.www}/validar/" rel="external"><img
          alt="Validar documentos" src="${this.www}/banners/validar/${this.bannerSeleccionado.filename}"
          style="border:0" width="${this.bannerSeleccionado.width}" height="${this.bannerSeleccionado.height}" /></a>`
    },
  },
  beforeCreate() {
    this.slugTramite = this.$store?.getters['app/tramite'] || ''
  },
  methods: {
    showDialog(banner) {
      this.bannerSeleccionado = banner
      this.dialog = true
    },
    showDialogValidar(banner) {
      this.bannerSeleccionado = banner
      this.dialogValidar = true
    },
  },
}
</script>
