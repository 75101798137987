<template>
  <div>
    <v-form>
      <v-card class="mx-auto my-4" max-width="600">
        <v-card-title> Reporte resumen general </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <z-input
                v-model="form.tramite_id"
                multiple
                :items="items"
                clearable
                label="Trámites"
                :error-messages="errors.tramite_id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.institucion_id"
                multiple
                :items="payloadItemsMunicipalidades"
                clearable
                label="Municipalidades"
                :error-messages="errors.institucion_id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.tipo_pago"
                multiple
                :items="$itemsTipoPago"
                clearable
                label="Tipo de pago"
                :error-messages="errors.tipo_pago"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="form.ano"
                :items="anos"
                dense
                label="Año"
                outlined
                :error-messages="errors.ano"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.fecha_desde"
                :error-messages="errors.fecha_desde"
                label="Desde"
                type="date"
              />
            </v-col>
            <v-col>
              <z-input
                v-model="form.fecha_hasta"
                :error-messages="errors.fecha_hasta"
                label="Hasta"
                type="date"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="descargar()">
            <v-icon left> mdi-file-download-outline </v-icon>
            Generar reporte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <dialog-progress v-if="loading"> Generando reporte... </dialog-progress>
  </div>
</template>
<script>
import { notificar } from '@/admin/utils/notificar'
import { saveAs } from 'file-saver'
import ZInput from '@/components/Input/ZInput'
import DialogProgress from '@/admin/components/DialogProgress'
import { getError } from '@/utils/errors'

export default {
  components: { DialogProgress, ZInput },
  data: () => ({
    errors: {},
    loading: false,
    form: {
      institucion_id: '',
      ano: '',
      tipo_pago: '',
      tramite_id: '',
      fecha_hasta: '',
      fecha_desde: '',
    },
    instituciones: '',
    tipoPagos: '',
    tramites: '',
  }),
  computed: {
    anoActual() {
      return parseInt(this.$dayjs().format('YYYY'))
    },
    anos() {
      const anos = []
      for (let ano = this.anoActual + 1; ano >= this.anoActual; ano--) {
        anos.push(ano)
      }
      return anos
    },
    items() {
      console.log(this.payloadItemsTramites)
      return this.payloadItemsTramitesId.filter((t) =>
        [
          3, // 'permisos-de-circulacion',
          5, // 'permisos-de-circulacion-pesados',
          6, // 'permisos-de-circulacion-locomocion-colectiva',
        ].includes(t.value)
      )
    },
  },
  methods: {
    descargar() {
      this.loading = true
      this.errors = {}

      const paramsObj = {
        fecha_desde: this.form.fecha_desde,
        fecha_hasta: this.form.fecha_hasta,
      }
      const searchParams = new URLSearchParams(paramsObj)

      if (this.form.institucion_id) {
        this.instituciones = this.form.institucion_id.map((v) => `&institucion_id[]=${v}`).join('&')
      }

      if (this.form.tipo_pago) {
        this.tipoPagos = this.form.tipo_pago.map((v) => `&tipo_pago[]=${v}`).join('&')
      }

      if (this.form.tramite_id) {
        this.tramites = this.form.tramite_id.map((v) => `&tramite_id[]=${v}`).join('&')
      }

      this.$axios
        .get(
          `bum/reportes/resumen-general?${searchParams}` +
            this.tramites +
            this.instituciones +
            this.tipoPagos,

          { responseType: 'blob' }
        )
        .then((response) => {
          saveAs(response.data, 'permisos-pagos.xlsx')
        })
        .catch(async (error) => {
          this.errors = await getError(error)

          notificar({
            message: 'Hubo un error.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
