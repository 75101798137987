<!--
    case CONSTRUCCION = 1;
    case PROCESANDO = 5;
    case APROBACION_PENDIENTE = 6;
    case RECHAZO_INTERNO = 8;
    case RECHAZADA = 9;
    case APROBADA = 15;
-->
<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Aceptar o rechazar',
          icon: 'mdi-file-check-outline',
          click: dialogAceptarRechazar,
          disabled: (v) => v.institucion_asignada_id !== $auth.institucion().id,
          posicion: 'derecha',
        },
      ]"
      :botones-ver="[]"
      :campo-titulo-dialog="(v) => tiposSolicitud[v.tipo]"
      :campo-subtitulo-dialog="(v) => $formato.placaPatente(v.vehiculo.patente)"
      :headers="headers"
      :query="query"
      :on-before-dialog-view="onBeforeDialogView"
      url="/bum/solicitudes"
      search-url="/bum/solicitudes/search"
      search-method="post"
      search-inicial
      texto-hits="%s solicitud."
      texto-no-hit="No se encontraron solicitud."
      texto-un-hit="Un usuario."
      disable-delete
      disable-edit
      max-width="800"
      @created="$emit('notificar', 'Solicitud creada.')"
      @updated="$emit('notificar', 'Solicitud guardada.')"
      @deleted="$emit('notificar', 'Solicitud eliminada.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear la solicitud.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar la solicitud.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar la solicitud.')"
    >
      <template #buscador="{ search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="query.filter.tipo"
                :items="[
                  { text: 'Traslado', value: 1 },
                  { text: 'Primer permiso de circulación', value: 2 },
                ]"
                clearable
                dense
                label="Tipo de Solicitud"
                outlined
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                v-model="query.filter.estado"
                :items="[
                  { text: 'Enviada', value: 5 },
                  { text: 'Rechazada', value: 9 },
                  { text: 'Aceptada', value: 15 },
                ]"
                clearable
                dense
                label="Estado"
                outlined
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #view="{ item }">
        <v-simple-table>
          <tbody>
            <tr>
              <th>Tipo</th>
              <td>{{ tiposSolicitud[item.tipo] }}</td>
            </tr>
            <tr>
              <th>Solicitante</th>
              <td>{{ $formato.rut(item.ciudadano_id) }}</td>
            </tr>
            <tr>
              <th>Patente</th>
              <td>{{ $formato.placaPatente(item.vehiculo.patente) }}</td>
            </tr>
            <tr v-if="item.mensaje">
              <th>Mensaje al ciudadano</th>
              <td style="white-space: pre-line">
                {{ item.mensaje }}
              </td>
            </tr>
            <tr v-if="item.mensaje_interno">
              <th>Mensaje interno</th>
              <td style="white-space: pre-line">
                {{ item.mensaje_interno }}
              </td>
            </tr>
            <tr>
              <th>Fecha de solicitud</th>
              <td>{{ $formato.fechaHora(item.fecha_solicitud) }}</td>
            </tr>
            <tr>
              <th>Última actualización</th>
              <td>{{ $formato.fechaHora(item.updated_at) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-subtitle-1 my-4">Datos contribuyente</div>

        <v-simple-table>
          <tbody>
            <tr>
              <th>Nombres</th>
              <td>{{ item.registro.propietario_nombres }}</td>
            </tr>
            <tr>
              <th>Apellidos</th>
              <td>{{ item.registro.propietario_apellidos }}</td>
            </tr>
            <tr>
              <th>Rut</th>
              <td>{{ $formato.rut(item.registro.propietario_rut) }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                {{ item.registro.propietario_email }}
              </td>
            </tr>
            <tr>
              <th>Teléfono</th>
              <td>
                {{ item.registro.propietario_telefono }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <template v-if="item.tipo === 1">
          <v-row class="mt-4 mb-8">
            <v-col>
              <div class="text-subtitle-1 my-4">SOAP</div>
              <z-btn
                v-if="!verificaciones.soap"
                :loading="loading"
                color="primary"
                outlined
                @click="verificar(item, 'soap')"
              >
                Verificar SOAP
              </z-btn>
              <v-card v-else>
                <v-simple-table>
                  <tbody>
                    <tr v-for="(valor, campo) in verificaciones.soap" :key="campo">
                      <template v-if="valor">
                        <th>{{ campo }}</th>
                        <td>{{ valor }}</td>
                      </template>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col>
              <div class="text-subtitle-1 my-4">Revisión técnica</div>
              <z-btn
                v-if="!verificaciones.revisionTecnica"
                :loading="loading"
                color="primary"
                outlined
                @click="verificar(item, 'revisionTecnica')"
              >
                Verificar revisión técnica
              </z-btn>
              <v-card v-else>
                <v-simple-table>
                  <tbody>
                    <tr v-for="(valor, campo) in verificaciones.revisionTecnica" :key="campo">
                      <template v-if="valor">
                        <th>{{ campo }}</th>
                        <td>{{ valor }}</td>
                      </template>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-if="item.tipo === 2">
          <v-row class="mt-4 mb-8">
            <v-col>
              <div class="text-subtitle-1 my-4">Certificado de Homologación Individual</div>
              <z-btn
                v-if="!verificaciones.chie"
                :loading="loading"
                color="primary"
                outlined
                @click="verificar(item, 'chie')"
              >
                Verificar certificado
              </z-btn>
              <v-card v-else>
                <v-simple-table>
                  <tbody>
                    <tr v-for="(valor, campo) in verificaciones.chie" :key="campo">
                      <template v-if="valor">
                        <th>{{ campo }}</th>
                        <td>{{ valor }}</td>
                      </template>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <div class="text-subtitle-1 my-4">Archivos</div>

        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th>Descargar</th>
                <th>Tipo</th>
                <th>Fecha enviado</th>
                <th>Tamaño</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="collectionName in item.tipos_archivo" :key="collectionName">
                <td>
                  <v-btn
                    :disabled="!item.archivos[collectionName]"
                    icon
                    title="Descargar"
                    @click="descargarArchivo(item, collectionName)"
                  >
                    <v-icon>
                      {{
                        item.archivos[collectionName]
                          ? 'mdi-cloud-download-outline'
                          : 'mdi-cloud-off-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </td>
                <td>{{ collectionName.replaceAll('-', ' ') }}</td>
                <template v-if="item.archivos[collectionName]">
                  <td>{{ $formato.fechaHora(item.archivos[collectionName].created_at) }}</td>
                  <td>
                    {{ $formato.numero(item.archivos[collectionName].size / (1024 * 1024)) }} MB
                  </td>
                </template>
                <template v-else>
                  <td />
                  <td />
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <div class="text-subtitle-1 my-4">Detalles de la solicitud</div>

        <v-card class="my-4">
          <v-simple-table dense>
            <tbody>
              <tr v-for="(valor, campo) in obternerRegistro(item)" :key="campo">
                <th class="capitalize">
                  {{ registro(campo) }}
                </th>
                <td>{{ valor }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </template>

      <template #item.estado="{ value }">
        <z-chip color="info" small outlined>
          {{ labelEstado(value) }}
        </z-chip>
      </template>
    </z-mantenedor>

    <v-dialog v-model="dialogAceptarRechazarVisible" persistent width="500">
      <v-card>
        <dialog-toolbar @close="dialogAceptarRechazarVisible = false">
          Aceptar o rechazar solicitud
        </dialog-toolbar>
        <v-card-text>
          <z-input
            v-model="mensaje"
            label="Mensaje"
            tipo="textarea"
            minlength="10"
            maxlength="255"
            counter
          />
        </v-card-text>

        <v-card-actions>
          <z-input
            v-if="zbtnAceptar && mostrarDisponibilizarPago"
            v-model="disponibilizar"
            label="Disponibilizar pago"
            tipo="checkbox"
          />
        </v-card-actions>

        <v-card-actions>
          <z-btn
            v-if="zbtnAceptar"
            color="primary"
            :disabled="mensaje.length < 10"
            @click="aceptarRechazarSolicitud('aceptar')"
          >
            Aceptar
          </z-btn>
          <z-btn
            v-if="zbtnPreAprobar"
            color="primary"
            :disabled="mensaje.length < 10"
            @click="aceptarRechazarSolicitud('aceptar')"
          >
            Pre aprobar
          </z-btn>
          <v-spacer />
          <z-btn
            color="primary"
            :disabled="mensaje.length < 10 || disponibilizar"
            @click="aceptarRechazarSolicitud('rechazar')"
          >
            Rechazar
          </z-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import { getError } from '@/utils/errors'
import ZChip from '@/components/ZChip'
import { saveAs } from 'file-saver'
import { notificar } from '@/admin/utils/notificar'
import ZInput from '@/components/Input/ZInput'
import DialogToolbar from '@/components/DialogToolbar'

export default {
  components: { DialogToolbar, ZInput, ZChip, ZMantenedor, ZBtn },
  data() {
    return {
      zbtnAceptar: false,
      zbtnPreAprobar: false,
      loading: false,
      dialogAceptarRechazarVisible: false,
      dialogoAceptarRechazarDisponibilizarCuenta: false,
      mostrarDisponibilizarPago: true,
      mensaje: '',
      disponibilizar: false,
      items: [],
      solicitud: {},
      verificaciones: {
        soap: null,
        revisionTecnica: null,
        chie: null,
      },
      query: {
        paginationDisabled: true,
        filters: [{ field: 'estado', operator: '!=', value: 1 }],
        filter: {
          estado: null,
        },
        include: 'vehiculo,media',
        sort: {
          0: { field: 'updated_at', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    tiposSolicitud() {
      return { 1: 'Traslado', 2: 'Primer permiso de circulación' }
    },
    headers() {
      return [
        {
          text: 'Tipo Solicitud',
          value: 'tipo',
          formatter: (v) => this.tiposSolicitud[v],
        },

        {
          text: 'Solicitante',
          value: 'ciudadano_id',
          cellClass: 'text-no-wrap',
          formatter: (v) => this.$formato.rut(v),
        },
        {
          text: 'Patente',
          value: 'vehiculo',
          formatter: (v) => this.$formato.placaPatente(v.patente),
        },
        {
          text: 'Fecha de solicitud',
          value: 'fecha_solicitud',
          formatter: (v) => this.$formato.fechaHora(v),
        },
        {
          text: 'Estado',
          value: 'estado',
        },
      ]
    },
  },

  created() {
    this.query.filter.estado = parseInt(this.$route.query.estado)
  },
  methods: {
    labelEstado(value) {
      return (
        {
          1: 'No enviada',
          5: 'Enviada',
          6: 'Aprobación pendiente',
          8: 'Rechazo interno',
          9: 'Rechazada',
          15: 'Aceptada',
        }[value] || ''
      )
    },

    registro(campo) {
      campo = campo.replace(/^vehiculo_/, '').replaceAll('_', ' ')

      if (campo === 'ano') campo = 'Año'
      if (campo === 'transmision') campo = 'Transmisión'
      if (campo === 'institucion propietario') campo = 'institución propietario'

      return campo
    },
    obternerRegistro(solicitud) {
      const registro = solicitud.registro
      const registroCopia = JSON.parse(JSON.stringify(registro))

      if (registro.propietario_institucion_id) {
        registroCopia.institucion_propietario = this.$payloadStatic.municipalidad(
          registro.propietario_institucion_id
        ).nombre
        delete registroCopia.propietario_institucion_id
      }

      if (registro.institucion_anterior_id) {
        registroCopia.institucion_anterior = this.$payloadStatic.municipalidad(
          registro.institucion_anterior_id
        ).nombre
        delete registroCopia.institucion_anterior_id
      }

      // Se eliminan los campos de registroCopia que estaran en registroOrdenadno
      delete registroCopia.propietario_nombres
      delete registroCopia.propietario_apellidos
      delete registroCopia.propietario_rut
      delete registroCopia.propietario_email
      delete registroCopia.propietario_telefono

      return registroCopia
    },

    descargarArchivo(solicitud, collectionName) {
      this.$axios
        .get(`bum/solicitudes/${solicitud.id}/archivos/${collectionName}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']
            ?.toString()
            ?.match(/filename=["']?([^'"]+)["']?/)[1]
          saveAs(response.data, filename)
        })
        .catch((error) => {
          error = getError(error)
          notificar({
            message: error._message || 'Archivo no disponible.',
            severity: 'error',
          })
        })
        .finally(() => {})
    },
    verificar(solicitud, tipoVerificacion) {
      this.loading = true
      this.$axios
        .get(`bum/solicitudes/${solicitud.id}/verificar/${tipoVerificacion}`)
        .then((response) => {
          this.verificaciones[tipoVerificacion] = response.data
        })
        .catch((error) => {

          notificar({
            message: error.response.data?.message || 'No se pudo hacer la verificación.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onBeforeDialogView(item) {
      this.verificaciones.soap = null
      this.verificaciones.revisionTecnica = null
      this.verificaciones.chie = null

      return new Promise((resolve, reject) => resolve(item))
    },
    dialogAceptarRechazar(solicitud) {
      console.log(solicitud)
      this.dialogoAceptarRechazarDisponibilizarCuenta = true
      console.log(this.dialogoAceptarRechazarDisponibilizarCuenta)

      if (solicitud.tipo === 1) {
        // Traslado
        this.mostrarDisponibilizarPago = true

        if (solicitud.institucion_aprobadora_id === this.$auth.user().institucion_id) {
          this.zbtnAceptar = true
          this.zbtnPreAprobar = false
        } else {
          this.zbtnPreAprobar = true
          this.zbtnAceptar = false
        }
      }

      if (solicitud.tipo === 2) {
        // PrimerPermisoDeCirculacion
        this.mostrarDisponibilizarPago = true
        this.zbtnAceptar = true
        this.zbtnPreAprobar = false
      }
      this.disponibilizar = false
      this.dialogAceptarRechazarVisible = true
      this.mensaje = solicitud.mensaje || ''
      this.solicitud = solicitud
    },
    aceptarRechazarSolicitud(accion) {
      this.loading = true
      this.$axios
        .post(`bum/solicitudes/${this.solicitud.id}/${accion}`, {
          mensaje: this.mensaje,
          disponibilizar: this.disponibilizar,
        })
        .then((response) => {
          this.solicitud.estado = response.data.data.estado
          this.solicitud.mensaje = response.data.data.mensaje
          this.solicitud.mensaje_interno = response.data.data.mensaje_interno
          this.solicitud.institucion_asignada_id = response.data.data.institucion_asignada_id
        })
        .catch((error) => {
          error = getError(error)
          notificar({
            message: error._message || `No se pudo ${accion} la solicitud.`,
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
          this.dialogAceptarRechazarVisible = false
        })
    },
  },
}
</script>

<style>
.capitalize {
  text-transform: capitalize;
}
</style>
