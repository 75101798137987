<template>
  <div>
    <z-mantenedor
      v-model="items"
      :botones-listar="[
        {
          title: 'Ver historial',
          icon: 'mdi-history',
          click: mostrarHistorial,
          posicion: 'derecha',
        },
      ]"
      :botones-ver="[
        {
          title: mostrarTodo ? 'Ver todo' : 'Ver cambios',
          icon: mostrarTodo ? 'mdi-playlist-minus' : 'mdi-playlist-plus',
          click: () => {
            mostrarTodo = !mostrarTodo
          },
        },
        {
          title: 'Historial',
          icon: 'mdi-history',
          click: mostrarHistorial,
        },
      ]"
      :campo-subtitulo-dialog="(v) => formatAccion(v.nombre)"
      :campo-titulo-dialog="(v) => `${v.clase}:${v.model_id}`"
      :headers="headers"
      :query="query"
      disable-delete
      disable-edit
      texto-filtrar="Filtrar"
      url="/logs/logs"
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="5" md="4">
              <z-input
                v-model="query.filter.nombre"
                :items="['created', 'updated', 'deleted']"
                clearable
                label="Acción"
                multiple
              />
            </v-col>
            <v-col cols="6" sm="4" md="3">
              <z-input v-model="query.filter.clase" clearable label="Entidad" />
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <z-input v-model="query.filter.model_id" clearable label="Id" />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #item.fecha="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss'
          )
        }}
        <div class="text--secondary text-caption">({{ $dayjs(value).fromNow() }})</div>
      </template>

      <template #view="{ item }">
        <tabla-campos-editados
          v-if="item.payload && item.payload.attributes"
          :attributes="{ ...item.payload.attributes }"
          :mostrar-todo="mostrarTodo || item.nombre !== 'updated'"
          :old="{ ...item.payload.old, updated_at: undefined }"
        />
      </template>
    </z-mantenedor>

    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.model_id"
      :microservicio="itemActivo.microservicio"
      :clase="itemActivo.clase"
      @cerrar="historialVisible = false"
    />
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import TablaCamposEditados from '@/admin/components/TablaCamposEditados'
import DialogHistorial from '@/admin/components/DialogHistorial'

export default {
  components: { DialogHistorial, TablaCamposEditados, ZInput, ZMantenedor, ZBtn },
  data() {
    return {
      items: [],
      itemActivo: {},
      mostrarTodo: false,
      historialVisible: false,
      headers: [
        {
          text: 'Fecha',
          value: 'fecha',
        },
        {
          text: 'Acción',
          value: 'nombre',
          formatter: this.formatAccion,
        },
        {
          text: 'Entidad',
          value: 'clase',
        },
        {
          text: 'Id',
          value: 'model_id',
        },
      ],
      query: {
        filter: {
          microservicio: null,
          model_id: null,
          nombre: null,
          clase: null,
          tipo: 'model',
        },
        scopes: {
          0: { name: 'desde', parameters: ['6 month ago'] },
        },
        sort: {
          0: { field: 'fecha', direction: 'desc' },
        },
      },
    }
  },
  methods: {
    formatAccion(nombre) {
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
  },
}
</script>
