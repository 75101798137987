<template>
  <v-chip
    :color="color(cuenta.estado)"
    :outlined="outlined(cuenta.estado)"
    dark
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $payload.estadoCuenta(cuenta.estado) }}
  </v-chip>
</template>

<script>
export default {
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    color(estado) {
      return (
        {
          11: 'info',
          21: 'success',
          25: 'success',
          26: 'success',
          27: 'success',
          28: 'success',
        }[estado] ?? 'grey'
      )
    },
    outlined(estado) {
      return estado < 25
    },
  },
}
</script>
