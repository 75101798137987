var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hint":_vm.tramiteStatic.labelComprobantePago + ' se enviará a este nombre.',"label":"Nombre y apellido","name":"nombre","data-testid":"nombre","maxlength":"40","required":"","error-messages":_vm.errors.nombre,"rules":[
          (v) => !!v || 'Debes ingresar un nombre y apellido',
          (v) => (v && v.length >= 5) || 'El nombre debe ser más largo',
        ]},model:{value:(_vm.contacto.nombre),callback:function ($$v) {_vm.$set(_vm.contacto, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contacto.nombre"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","hint":"Será usado para que te puedas identificar en caso de solicitar una reimpresión del documento.","label":"RUT (o pasaporte para extranjeros)","name":"rut","data-testid":"rut","maxlength":"13","spellcheck":"false","required":"","error-messages":_vm.errors.rut,"rules":[
          (v) => !!v || 'Debes ingresar un RUT o pasaporte válido',
          (v) => (v && v.length >= 6) || 'Debe ser más largo',
          // v => /^\s*\d[\d.]{2,}\.?\d{3}-[0-9kK]\s*$/.test(v) || 'Debes ingresar un RUT válido',
        ]},model:{value:(_vm.contacto.rut),callback:function ($$v) {_vm.$set(_vm.contacto, "rut", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contacto.rut"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"email","outlined":"","hint":_vm.tramiteStatic.labelComprobantePago + ' se enviará a este correo electrónico.',"label":"Correo electrónico","name":"email","inputmode":"email","data-testid":"email","maxlength":"60","autocapitalize":"off","required":"","error-messages":_vm.errors.email,"rules":[
          (v) => !!v || 'Debes ingresar un correo electrónico',
          (v) => /.+@.+\..+/.test(v) || 'Debes ingresar un correo electrónico válido',
        ]},model:{value:(_vm.contacto.email),callback:function ($$v) {_vm.$set(_vm.contacto, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contacto.email"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }