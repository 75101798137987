var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"text-h6"},[_vm._v(" Datos para declaración jurada beneficio de zona extrema ")])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","label":"Domicilio","name":"domicilio","data-testid":"domicilio","hide-details":"","maxlength":"100","required":"","error-messages":_vm.errors.domicilio,"rules":[
          (v) => !!v || 'Debes ingresar tu domicilio',
          (v) => (v && v.length >= 5) || 'El domicilio debe ser más largo',
        ]},model:{value:(_vm.contacto.domicilio),callback:function ($$v) {_vm.$set(_vm.contacto, "domicilio", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contacto.domicilio"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","label":"Ciudad","name":"ciudad","data-testid":"ciudad","hide-details":"","maxlength":"50","required":"","error-messages":_vm.errors.ciudad,"rules":[
          (v) => !!v || 'Debes ingresar tu ciudad',
          (v) => (v && v.length >= 3) || 'La ciudad debe ser más larga',
        ]},model:{value:(_vm.contacto.ciudad),callback:function ($$v) {_vm.$set(_vm.contacto, "ciudad", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contacto.ciudad"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }