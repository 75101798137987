<template>
  <div>
    <v-form>
      <v-card class="mx-auto my-4" max-width="600">
        <v-card-title> Resumen de cuentas y pagos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <z-input
                v-model="form.tramitesSeleccionados"
                multiple
                :items="payloadItemsTramites"
                value:1
                clearable
                label="Trámites"
                :error-messages="errors.tramitesSeleccionados"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.fecha_desde"
                :error-messages="errors.fecha_desde"
                label="Desde"
                type="date"
              />
            </v-col>
            <v-col>
              <z-input
                v-model="form.fecha_hasta"
                :error-messages="errors.fecha_hasta"
                label="Hasta"
                type="date"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="descargar()">
            <v-icon left> mdi-file-download-outline </v-icon>
            Generar reporte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <dialog-progress v-if="loading"> Generando reporte... </dialog-progress>
  </div>
</template>
<script>
import { notificar } from '@/admin/utils/notificar'
import { saveAs } from 'file-saver'
import ZInput from '@/components/Input/ZInput'
import DialogProgress from '@/admin/components/DialogProgress'

export default {
  components: { DialogProgress, ZInput },
  data: () => ({
    errors: {},
    loading: false,
    form: {
      fecha_desde: '',
      fecha_hasta: '',
      institucion_id: '',
      tramitesSeleccionados: '',
    },
  }),
  computed: {},
  methods: {
    descargar() {
      this.loading = true
      this.errors = {}

      if (this.form.tramitesSeleccionados === '') {
        this.errors.tramitesSeleccionados = 'Debes seleccionar al menos un trámite.'
      }

      this.$axios
        .get(
          `pagos/reportes/resumen-cuentas-pagos?
          institucion_id=${this.form.institucion_id}
          &tramitesSeleccionados=${this.form.tramitesSeleccionados}
          &fecha_desde=${this.form.fecha_desde}
          &fecha_hasta=${this.form.fecha_hasta}`,
          { responseType: 'blob', timeout: 300 * 1000 }
        )
        .then((response) => {
          saveAs(response.data, 'resumen_cuentas_pagos.xlsx')
        })
        .catch(() => {
          notificar({
            message: 'Reporte no disponible.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
          this.form.tramitesSeleccionados = ''
        })
    },
  },
}
</script>
