<template>
  <v-tooltip :bottom="bottom" :top="!bottom">
    <template #activator="{ on, attrs }">
      <v-chip v-bind="attrs" label :outlined="outlined" :color="color" class="px-1" v-on="on">
        <slot> </slot>
      </v-chip>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'SimpleChipTooltip',
  inheritAttrs: false,
  props: {
    bottom: Boolean,
    outlined: Boolean,
    color: {
      type: String,
      default: 'transparent',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
}
</script>
