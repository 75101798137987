<template>
  <div>
    <z-form-editar
      :id="$route.params.id"
      v-model="data"
      :after-read="afterRead"
      :after-save="afterSave"
      :before-save="beforeSave"
      url="logs/avisos"
      @updated="notificar('Aviso actualizado.')"
      @read:error="notificarErrorResponse('No se pudieron leer los datos del aviso.')"
      @update:error="notificarErrorResponse"
    >
      <template #default="{ form, errors, loading, save }">
        <v-form ref="form" :readonly="loading" lazy-validation>
          <v-card :loading="loading" class="mx-auto" max-width="700" tile>
            <v-card-title> Mensaje inicial a mostrar </v-card-title>
            <v-card-subtitle>
              Se mostrará como mensaje de una línea en una barra superior.
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.resumen"
                    :error-messages="errors.resumen"
                    label="Mensaje"
                    maxlength="100"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-expand-transition>
              <div v-show="form.resumen">
                <v-card-title> Fechas </v-card-title>
                <v-card-subtitle>
                  Período de tiempo entre el que se mostrará el aviso.
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <z-input
                        v-model="form.fecha_desde"
                        :error-messages="errors.fecha_desde"
                        label="Desde"
                        type="datetime-local"
                      />
                    </v-col>
                    <v-col>
                      <z-input
                        v-model="form.fecha_hasta"
                        :error-messages="errors.fecha_hasta"
                        label="Hasta"
                        type="datetime-local"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title> Ventana con mensaje detallado </v-card-title>
                <v-card-subtitle>
                  En caso de mostrar más información, se mostrará una ventana de diálogo con el
                  mensaje aquí ingresado.
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <z-input
                        v-model="form.titulo"
                        :error-messages="errors.titulo"
                        label="Título"
                        maxlength="40"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <z-input
                        v-model="form.mensaje"
                        :error-messages="errors.mensaje"
                        height="300px"
                        label="Mensaje"
                        tipo="textarea"
                        maxlength="1000"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <z-btn outlined color="primary" @click="previsualizarAviso">
                    Generar previsualización del aviso
                  </z-btn>
                </v-card-actions>
              </div>
            </v-expand-transition>

            <v-card-actions>
              <btn-guardar-cancelar
                :loading="loading"
                @cancelar="$router.push('/dashboard')"
                @guardar="save"
              />
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </z-form-editar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { notificar, notificarErrorResponse } from '@/admin/utils/notificar'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import ZFormEditar from '@/components/Input/ZFormEditar'
import ZInput from '@/components/Input/ZInput'
import ZBtn from '@/components/ZBtn'

export default {
  components: { ZBtn, ZInput, BtnGuardarCancelar, ZFormEditar },
  data: () => ({
    md: '## Aviso\nHola mundo',
    data: {},
  }),
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  mounted() {},
  methods: {
    afterRead(form) {
      form.fecha_desde = this.$dayjs(form.fecha_desde).tz().format('YYYY-MM-DDTHH:mm')
      form.fecha_hasta = this.$dayjs(form.fecha_hasta).tz().format('YYYY-MM-DDTHH:mm')
    },
    beforeSave(form) {
      form.fecha_desde = this.$dayjs(form.fecha_desde).tz().toJSON()
      form.fecha_hasta = this.$dayjs(form.fecha_hasta).tz().toJSON()
    },
    afterSave(form) {
      this.quitarAviso()
      this.$store.dispatch('app/fetchPayload', 'logs')
      this.$router.push('/dashboard')
    },
    previsualizarAviso() {
      this.$store.dispatch('app/mergePayload', {
        aviso: {
          resumen: this.data.resumen,
          titulo: this.data.titulo,
          mensaje: this.data.mensaje,
          fecha_desde: '2020-01-01T00:00:00',
          fecha_hasta: '2050-01-01T00:00:00',
        },
      })
    },
    quitarAviso() {
      this.$store.dispatch('app/mergePayload', {
        aviso: {
          resumen: '',
          titulo: '',
          mensaje: '',
          fecha_desde: '2020-01-01T00:00:00',
          fecha_hasta: '2020-01-01T00:00:00',
        },
      })
    },
    notificarErrorResponse(event) {
      notificarErrorResponse(event)
    },
    notificar(event) {
      console.log(event);
      notificar(event)
    },
  },
}
</script>
