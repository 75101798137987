<template>
  <div>
    <v-row>
      <v-col>
        <z-input
          v-model="form.titulo"
          :error-messages="errors.titulo"
          label="Título"
          minlength="3"
          maxlength="100"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <z-input
          v-model="form.subtitulo"
          :error-messages="errors.subtitulo"
          label="Subtítulo"
          maxlength="500"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-input :error-messages="errors.contenido">
          <tiptap v-model="form.contenido" />
        </v-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-slider
          v-model="form.prioridad"
          :error-messages="errors.prioridad"
          min="1"
          max="100"
          thumb-label="always"
          :thumb-size="24"
          label="Prioridad"
          hint="Se ordenarán desde 1 hasta 100"
        />
      </v-col>
      <v-col cols="4" class="d-flex">
        <v-spacer />
        <z-input
          v-model="form.publicado"
          :error-messages="errors.publicado"
          label="¿Publicado?"
          tipo="checkbox"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import Tiptap from '@/components/Tiptap'
import { mapGetters } from 'vuex'

export default {
  components: { ZInput, Tiptap },
  props: {
    accion: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', ['payload']),
    roles() {
      return Object.entries(this.payloadListaRoles).map((v) => ({ name: v[1], id: parseInt(v[0]) }))
    },
    form: {
      get: function () {
        console.log(this.value)
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {},
}
</script>
