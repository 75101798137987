<template>
  <v-card v-bind="$attrs" color="success" dark v-on="$listeners">
    <v-card-title> Puedes acogerte al beneficio tributario de zonas extremas </v-card-title>
    <v-card-text>
      La comuna en la que estás pagando está acogida al beneficio de zonas extremas. Puedes optar a
      pagar el permiso de circulación con un valor reducido, realizando una declaración jurada
      simple antes de iniciar el pago.
    </v-card-text>
    <v-card-actions>
      <v-select
        v-model="beneficioTributarioZonaFranca"
        class="mx-auto mb-6 success darken-2"
        hide-details
        style="max-width: 450px"
        solo-inverted
        :items="[
          { value: false, text: 'Pagar monto normal' },
          { value: true, text: 'Pagar con beneficio tributario' },
        ]"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    beneficioTributarioZonaFranca: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
