var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('z-mantenedor',{attrs:{"headers":_vm.headers,"query":_vm.query,"url":"/logs/logs","search-url":"/logs/logs/search","search-method":"post","disable-delete":"","disable-edit":""},scopedSlots:_vm._u([{key:"item.nombre",fn:function({ item }){return [_vm._v(" "+_vm._s(item.nombre)+" "),(item.clase)?_c('div',{staticClass:"font-weight-light text-caption"},[_vm._v(" "+_vm._s(item.clase)+" ")]):_vm._e()]}},{key:"buscador",fn:function({ loading, search }){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return search.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('z-input',{attrs:{"items":['evento', 'job', 'log', 'model', 'user'],"clearable":"","chips":"","label":"Tipo","multiple":""},model:{value:(_vm.query.filter.tipo),callback:function ($$v) {_vm.$set(_vm.query.filter, "tipo", $$v)},expression:"query.filter.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('z-input',{attrs:{"clearable":"","label":"clase"},model:{value:(_vm.query.filter.clase),callback:function ($$v) {_vm.$set(_vm.query.filter, "clase", $$v)},expression:"query.filter.clase"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('z-input',{attrs:{"items":['debug', 'info', 'notice', 'warning', 'error'],"clearable":"","label":"Severidad","multiple":""},model:{value:(_vm.query.filter.nivel),callback:function ($$v) {_vm.$set(_vm.query.filter, "nivel", $$v)},expression:"query.filter.nivel"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('z-input',{attrs:{"items":[
                'logs',
                'pagos',
                'tramitesgenerales',
                'permisosdecirculacion',
                'permisosdecirculacion2c',
                'usuarios',
              ],"clearable":"","label":"Microservicio","multiple":""},model:{value:(_vm.query.filter.microservicio),callback:function ($$v) {_vm.$set(_vm.query.filter, "microservicio", $$v)},expression:"query.filter.microservicio"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex"},[_c('v-spacer'),_c('z-btn',{attrs:{"loading":loading,"color":"primary","outlined":"","type":"submit"}},[_vm._v(" Actualizar ")])],1)],1)],1)]}},{key:"item.fecha",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$dayjs(value).format( _vm.$dayjs(value).isBefore(_vm.$dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss' ))),_c('span',{staticClass:"text--secondary text-caption"},[_vm._v(_vm._s(_vm.$dayjs(value).format('.SSS')))]),_c('div',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(_vm.$dayjs(value).fromNow())+" ")])]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }