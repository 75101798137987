<template>
  <tr>
    <td
      :class="{
        'py-1': !!campo.titulo.titulo && !!campo.titulo.subtitulo,
      }"
    >
      <div class="text-subtitle-1" :style="$vuetify.breakpoint.xs ? 'line-height: 1.3rem' : ''">
        {{ campo.titulo.titulo }}
      </div>
      <div
        v-if="campo.titulo.subtitulo"
        :style="$vuetify.breakpoint.xs ? 'line-height: 1.2rem' : ''"
        class="text--secondary text-caption"
        :class="{ 'mt-n1': $vuetify.breakpoint.smAndUp }"
      >
        <template v-if="campo.titulo.subtitulo_link">
          <a href="#" @click.prevent="dialogVisible = true">{{ campo.titulo.subtitulo }} </a>
          <dialog-detalles-vehiculo
            v-if="campo.titulo.subtitulo_link === 'dialog-detalles-vehiculo'"
            v-model="dialogVisible"
            :cuenta="cuenta"
          />
        </template>
        <template v-else>
          {{ campo.titulo.subtitulo }}
        </template>
      </div>
    </td>
    <td style="max-width: 200px" class="text-right">
      <div class="text-subtitle-1" :style="$vuetify.breakpoint.xs ? 'line-height: 1.3rem' : ''">
        {{ campo.valor.titulo }}
      </div>

      <v-chip
        v-if="campo.valor.chip"
        small
        class="font-weight-medium"
        style="opacity: 0.8"
        :color="campo.valor.chip.color || 'primary'"
      >
        <v-icon v-if="!!campo.valor.chip.icon" left small>
          {{ $svg[campo.valor.chip.icon] || '' }}
        </v-icon>
        {{ campo.valor.chip.texto }}
      </v-chip>
      <div v-if="campo.valor.subtitulo" class="text--secondary text-caption">
        {{ campo.valor.subtitulo }}
      </div>
    </td>
  </tr>
</template>

<script>
import DialogDetallesVehiculo from '@/portal/components/PermisosDeCirculacion/DialogDetallesVehiculo'
export default {
  components: { DialogDetallesVehiculo },
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
    campo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
}
</script>
