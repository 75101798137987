<template>
  <!--
    Al hacer click
    @event click
  -->
  <!--
    Al hacer click en el icono de cierre
    @event click:close
  -->
  <v-chip
    :close="close"
    :color="color"
    :dark="dark"
    :outlined="outlined"
    :small="!small"
    :x-small="small"
    v-on="$listeners"
  >
    <!-- @slot Contenido del alert -->
    <slot />
  </v-chip>
</template>

<script>
export default {
  props: {
    /**
     * Habilita un botón para cerrar el chip
     */
    close: {
      type: Boolean,
      default: false,
    },

    /**
     * Color del chip
     */
    color: {
      type: String,
    },

    /**
     * Inidica que el fondo es oscuro con lo que se cambia el texto a color claro.
     */
    dark: {
      type: Boolean,
      default: false,
    },

    /**
     * Énfasis
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Pequeño
     */
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
