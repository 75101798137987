<template>
  <span>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <slot />
        </span>
      </template>
      <table>
        <tbody>
          <tr>
            <td>Neto:</td>
            <th class="text-end">{{ $formato.moneda(montos.monto - montos.monto_descuento) }}</th>
          </tr>
          <tr v-if="montos.monto_interes">
            <td>Intereses:</td>
            <th class="text-end">{{ $formato.moneda(montos.monto_interes) }}</th>
          </tr>
          <tr v-if="montos.monto_reajuste">
            <td>Reajustes:</td>
            <th class="text-end">{{ $formato.moneda(montos.monto_reajuste) }}</th>
          </tr>
          <tr>
            <td>Total:</td>
            <th class="text-end">{{ $formato.moneda(montos.monto_total) }}</th>
          </tr>
          <tr v-if="montos.monto_saldo">
            <td>Saldo:</td>
            <th class="text-end">{{ $formato.moneda(montos.monto_saldo) }}</th>
          </tr>
        </tbody>
      </table>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'MontosTooltip',
  props: {
    montos: {
      type: Object,
      required: true,
    },
  },
}
</script>
