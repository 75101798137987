<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card class="mx-auto my-4" max-width="600">
        <v-card-title> Reporte de cuentas pagadas </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <input-tramite v-model="slugTramite" />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12">
              <z-input
                v-model="form.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input v-model="form.fecha_pago_desde" type="date" label="Desde" />
            </v-col>
            <v-col>
              <z-input v-model="form.fecha_pago_hasta" type="date" label="Hasta" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="descargar()">
            <v-icon left> mdi-file-download-outline </v-icon>
            Generar reporte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <dialog-progress v-if="loading"> Generando reporte... </dialog-progress>
  </div>
</template>
<script>
import { notificar } from '@/admin/utils/notificar'
import InputTramite from '@/components/Input/InputTramite'
import { getError } from '@/utils/errors'
import { saveAs } from 'file-saver'
import ZInput from '@/components/Input/ZInput'
import DialogProgress from '@/admin/components/DialogProgress'

export default {
  components: { DialogProgress, ZInput, InputTramite },
  data: () => ({
    errors: [],
    loading: false,
    form: {
      fecha_pago_desde: '',
      fecha_pago_hasta: '',
      institucion_id: '',
    },
    slugTramite: '',
  }),
  computed: {},
  methods: {
    descargar() {
      this.loading = true
      this.$axios
        .get(
          `tramites/${this.slugTramite}/reportes/cuentas-pagadas?fecha_pago_desde=${this.form.fecha_pago_desde}&fecha_pago_hasta=${this.form.fecha_pago_hasta}&institucion_id=${this.form.institucion_id}`,
          { responseType: 'blob' }
        )
        .then((response) => {
          saveAs(response.data, `${this.slugTramite} cuentas-pagadas.xlsx`)
        })
        .catch((error) => {
          error = getError(error)
          notificar({
            message: error || 'Reporte no disponible.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
