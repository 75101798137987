<template>
  <div>
    <!--
            Campos adicionales para pago desde el "admin"
    -->

    <v-row>
      <v-col class="text-h6"> Datos adicionales </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model.trim="contacto.folio"
          outlined
          label="Número de ingreso o folio"
          name="folio"
          data-testid="folio"
          maxlength="50"
          autocapitalize="off"
          required
          :error-messages="errors.folio"
          :rules="[
            (v) => !!v || 'Debes ingresar un número de ingreso o folio',
            (v) => (v && v.length >= 4) || 'Debe ser más largo',
          ]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    tramiteStatic: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    contacto: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {},
}
</script>
