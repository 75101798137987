<template>
  <div>
    <z-mantenedor
      v-model="items"
      :campo-subtitulo-dialog="(factor2c) => factor2c.ano"
      :campo-titulo-dialog="(factor2c) => 'Factor segundas cuotas'"
      :headers="headers"
      :query="query"
      url="/pagos/factores2c"
      search-url="/pagos/factores2c/search"
      search-method="post"
      disable-view
      height="380"
      search-inicial
      :on-before-save="beforeSave"
      width="700"
      @created="$emit('notificar', 'El Factor fue creado')"
      @created:error="$emit('notificar:error', 'Error al intentar crear Factor.')"
      @updated="$emit('notificar', 'Factor guardado.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-card class="d-flex justify-space-between" flat>
            <v-select v-model="query.filter.ano" :items="anos" dense label="Año" outlined />
            <v-spacer />
            <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            <z-btn
              class="mx-1"
              :loading="loading"
              color="primary"
              outlined
              @click="create({ ano: anoActual, factor: '', tramite_id: '' })"
            >
              Crear
            </z-btn>
          </v-card>
        </v-form>
      </template>

      <template #form="{ accion, form, errors }">
        <v-row>
          <v-col>
            <input-tramite
              v-model="form.tramite_id"
              campo="id"
              :filter="
                (t) =>
                  [
                    'permisos-de-circulacion',
                    'permisos-de-circulacion-locomocion-colectiva',
                    'permisos-de-circulacion-pesados',
                  ].includes(t.slug)
              "
              :disabled="accion !== 'create'"
              :error-messages="errors.tramite_id"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.ano"
              :error-messages="errors.ano"
              type="number"
              label="Año"
              :max="anoActual"
              :min="anoActual"
              outlined
              :disabled="true"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.factor"
              :error-messages="errors.factor"
              label="Factor"
              minlength="1"
              type="number"
              min="0"
              outlined
              hint="Para ingresar números decimales debes utilizar como separador el punto(.) y no la coma(,). Ejemplo: 5.7"
            />
          </v-col>
        </v-row>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import InputTramite from '@/components/Input/InputTramite'

export default {
  components: { ZMantenedor, ZBtn, InputTramite },
  data() {
    return {
      items: [],
      slugTramite: '',
      headers: [
        {
          text: 'Año',
          value: 'ano',
        },
        {
          text: 'Trámite',
          value: 'tramite_id',
          formatter: (v) => this.$tramites.find((t) => t.id === v).nombre,
        },
        {
          text: 'Factor',
          value: 'factor',
        },
        {
          text: 'Última edición',
          value: 'updated_at',
          formatter: (v) => this.$dayjs(v).format('L LT'),
        },
      ],
      query: {
        filter: { ano: this.anoActual },
        sort: {
          0: { field: 'id', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    anoActual() {
      return parseInt(this.$dayjs().format('YYYY'))
    },
    anos() {
      const anos = []
      for (let ano = this.anoActual - 1; ano <= this.anoActual + 1; ano++) {
        anos.push(ano)
      }
      return anos
    },
  },
  beforeMount() {
    this.query.filter.ano = this.anoActual
  },
  methods: {
    beforeSave(factor2c, accion, form) {
      console.log(this.$tramites)
      console.log(factor2c)
      // factor2c.tramite_id = this.$tramites.find((t) => t.id === Number(factor2c.tramite_id)).id
    },
  },
}
</script>
