<template>
  <v-card
    width="600"
    color="success"
    dark
    class="mx-auto mt-10 mb-6"
    :elevation="$vuetify.breakpoint.xs ? 0 : 2"
  >
    <v-card-title> Declaración jurada beneficio de zonas extremas </v-card-title>
    <v-card-subtitle>
      Declaración jurada que deben presentar los contribuyentes que se acojan a la franquicia del
      art. 13 D.S. 2.385/96
    </v-card-subtitle>

    <v-card-text class="px-sm-10 px-md-14 font-weight-medium white--text">
      Yo {{ contacto.nombre }}, R.U.T. {{ contacto.rut }}
      declaro bajo fe de juramento la efectividad de los siguientes hechos.

      <ol class="my-2">
        <li>
          <span v-if="cuentas.length === 1">
            Que el vehículo placa única {{ cuentas[0].pk_label }}, se encuentra destinado
          </span>
          <span v-else>
            Que los vehículos placa única
            {{ cuentas.map((c) => c.pk_label).join(', ') }}, se encuentran destinados
          </span>
          para el uso en la
          {{
            $payloadStatic.region($payloadStatic.municipalidad(cuentas[0].institucion_id).region)
          }}.
        </li>
        <li>
          Que el solicitante tiene como domicilio el de {{ contacto.domicilio }} de la ciudad de
          {{ contacto.ciudad }}.
        </li>
      </ol>

      Declaro que estos antecedentes son fidedignos y asumo mi responsabilidad en caso de falsedad,
      facultando al municipio para verificar tales hechos. Lo anterior para efectos de lo dispuesto
      en los artículos 196 A y siguientes de la Ley 18.290.
    </v-card-text>
    <v-card-actions>
      <v-btn
        large
        class="mx-auto mb-6"
        color="success darken-2"
        @click.stop="declaracionJuradaBeneficioZonaFranca = !declaracionJuradaBeneficioZonaFranca"
      >
        <v-checkbox
          v-model="declaracionJuradaBeneficioZonaFranca"
          label="Acepto condiciones"
          class="py-0 my-0 mx-4"
          hide-details
          @click.stop=""
        />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contacto: {
      type: Object,
      required: true,
    },
    cuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    declaracionJuradaBeneficioZonaFranca: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
    region() {
      return this.$payloadStatic.region(
        this.$payloadStatic.municipalidad(this.cuentas[0]?.institucion_id)?.region
      )
    },
  },
}
</script>
