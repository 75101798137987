<template>
  <div>
    <v-row>
      <v-col>
        <v-select
          v-model="form.proveedor"
          :items="payloadItemsProveedoresFirma"
          :error-messages="errors.firmante"
          clearable
          dense
          label="Proveedor de firma"
          outlined
          required
          :rules="[(v) => !!v || 'Debes seleccionar un proveedor']"
          :disabled="certificadoValidado === true"
        />
      </v-col>
      <v-col>
        <z-input
          v-model="form.codigo"
          :error-messages="errors.codigo"
          label="Código"
          minlength="3"
          :disabled="certificadoValidado === true"
        />
      </v-col>
    </v-row>
    <v-row v-if="$auth.can(['operador', 'mesa-de-ayuda'])">
      <v-col>
        <z-input
          v-model="form.institucion_id"
          :error-messages="errors.institucion_id"
          :items="payloadItemsInstituciones"
          tipo="autocomplete"
          label="Institución"
          required
          :disabled="certificadoValidado === true"
        />
      </v-col>
      <v-col v-if="accion === 'edit'">
        <z-input
          v-model="form.nombre"
          :error-messages="errors.nombre"
          label="Nombre"
          minlength="3"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="accion === 'edit'" cols="3">
        <z-input
          v-model="form.activo"
          :error-messages="errors.activo"
          label="Activo"
          tipo="checkbox"
        />
      </v-col>
    </v-row>

    <v-alert
      v-if="certificadoValidado === true"
      type="success"
      class="mx-auto my-4"
      max-width="600"
    >
      Certificado válido: {{ certificado.CN }}
    </v-alert>

    <v-alert v-if="certificadoValidado === false" class="mx-auto my-4" max-width="600" type="error">
      Certificado no válido
    </v-alert>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import { mapGetters } from 'vuex'

export default {
  components: { ZInput },
  props: {
    accion: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    certificado: {
      type: Object,
      required: true,
    },
    certificadoValidado: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', ['payload']),
    roles() {
      return Object.entries(this.payloadListaRoles).map((v) => ({ name: v[1], id: parseInt(v[0]) }))
    },
    form: {
      get: function () {
        console.log(this.value)
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {},
}
</script>
