<template>
  <div>
    <z-mantenedor
      v-model="items"
      :botones-listar="[
        {
          title: 'Iniciar validación de nómina',
          icon: 'mdi-cog-sync-outline',
          click: requestValidar,
          disabled: (c) => c.estado_masivo === 2 || c.cantidad === c.cantidad_cuentas_pagadas,
        },
        {
          title: 'Descargar Excel con detalle de validaciones',
          icon: 'mdi-microsoft-excel',
          click: descargarExcel,
          disabled: (c) => c.estado_masivo === 1 || c.estado_masivo === 2,
        },
        {
          title: 'Pago masivo',
          icon: 'mdi-cog-play-outline',
          click: pagoMasivo,
          disabled: (c) =>
            c.estado_masivo === 1 ||
            c.estado_masivo === 2 ||
            c.cantidad === c.cantidad_cuentas_pagadas,
        },
        {
          title: 'Descargar archivos PDF con comprobantes de pago',
          icon: 'mdi-cloud-download-outline',
          click: descargarZip,
          disabled: (c) => !c.fecha_zip_pdf_generado,
        },
      ]"
      :campo-subtitulo-dialog="(v) => formatAccion(v.nombre)"
      :campo-titulo-dialog="(v) => ` ${v.nombre}`"
      :headers="headers"
      :query="query"
      :url="tramiteUrl"
      :search-url="tramiteUrl + '/search'"
      search-method="post"
      :on-after-search="afterSearch"
      disable-edit
      disable-delete
      disable-view
    >
      <!-- :disable-delete="!$auth.can(['gestionar-nominas'])" -->

      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col>
              <input-tramite
                v-model="slugTramite"
                :filter="
                  (t) =>
                    [
                      'permisos-de-circulacion',
                      'permisos-de-circulacion-locomocion-colectiva',
                      'permisos-de-circulacion-pesados',
                      'permisos-de-circulacion-2c',
                    ].includes(t.slug)
                "
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filters[0].value"
                label="Nómina desde"
                type="date"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filters[1].value"
                label="Nómina hasta"
                type="date"
                clearable
              />
            </v-col>
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                :disabled="!slugTramite"
                :loading="loading"
                color="primary"
                outlined
                type="submit"
              >
                Actualizar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.fecha="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss'
          )
        }}
        <div class="text--secondary text-caption">({{ $dayjs(value).fromNow() }})</div>
      </template>

      <template #item.nombre="{ value, item }">
        {{ value }}
        <div class="text--secondary text-caption">
          {{ item.user_nombre }}
        </div>
      </template>

      <template #item.estado="{ item }">
        <chip-nomina :nomina="item" small />
      </template>

      <template #item.cantidad="{ value }">
        <v-chip small>
          {{ $formato.numero(value || 0) }}
        </v-chip>
      </template>

      <template #item.cantidad_cuentas_pagadas="{ value }">
        <v-chip small>
          {{ $formato.numero(value || 0) }}
        </v-chip>
      </template>

      <template #item.estado_masivo="{ item }">
        <pago-masivo-chip-estado :nomina="item" small />
      </template>
    </z-mantenedor>

    <pago-masivo-nominas-form
      v-if="nominasViewVisible"
      :id="itemActivo.id"
      :titulo="itemActivo.nombre"
      :slug="slugTramite"
      @cerrar="nominasViewVisible = false"
      @enviado="
        nominasViewVisible = false
        itemActivo.estado_masivo = 7
      "
    />
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import PagoMasivoNominasForm from '@/admin/views/PagoMasivoNominas/PagoMasivoNominasForm'
import ChipNomina from '@/admin/components/ChipNomina'
import { saveAs } from 'file-saver'
import { getError } from '@/utils/errors'
import { notificar } from '@/admin/utils/notificar'
import InputTramite from '@/components/Input/InputTramite'
import PagoMasivoChipEstado from '@/admin/views/PagoMasivoNominas/PagoMasivoChipEstado'

export default {
  components: {
    ChipNomina,
    ZMantenedor,
    ZBtn,
    PagoMasivoNominasForm,
    InputTramite,
    PagoMasivoChipEstado,
    ZInput,
  },
  data() {
    return {
      items: [],
      itemActivo: {},
      mostrarTodo: false,
      nominasViewVisible: false,
      solicitarArchivoVisible: false,
      slugTramite: this.$route.query.tramite ?? '',
      tramitesParaPagoMasivo: true,
      headerOptions: {
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      query: {
        filter: {
          institucion_id: parseInt(this.$route.query.institucion_id),
        },
        filters: {
          0: { field: 'created_at', operator: '>=', value: null },
          1: { field: 'created_at', operator: '<=', value: null },
        },
        scope: {
          estado: 'importada',
        },
        sort: {
          0: { field: 'created_at', direction: 'desc' },
        },
        cantidad_cuentas_pagadas: true,
      },
    }
  },
  computed: {
    tramiteUrl() {
      return `/tramites/${this.slugTramite}/nominas`
    },
    headers() {
      return [
        {
          text: 'Creación',
          value: 'created_at',
          formatter: (v) => this.$formato.fechaFromNow(v, 1, 'day', 'L H:mm'),
        },
        {
          text: 'Nómina',
          value: 'nombre',
        },
        {
          text: 'Cuentas',
          value: 'cantidad',
          cellClass: 'text-end',
        },
        {
          text: 'Pagadas',
          value: 'cantidad_cuentas_pagadas',
          cellClass: 'text-end',
        },
        {
          text: 'Estado masivo',
          value: 'estado_masivo',
        },
      ]
    },

    puedeSeleccionarInstitucion() {
      return true
    },
  },
  mounted() {

    const tramitesValidosParaMasivos = [
                                        'permisos-de-circulacion',
                                        'permisos-de-circulacion-locomocion-colectiva',
                                        'permisos-de-circulacion-pesados',
                                        'permisos-de-circulacion-2c',
                                      ];

    if(tramitesValidosParaMasivos.includes(this.slugTramite) === false){
      this.slugTramite = '';
    }

  },
  methods: {
    afterSearch() {
      this.headerOptions.institucion_id.visible = !this.query.filter.institucion_id
    },
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
    descargarZip(item) {
      const urlDescarga = `tramites/${this.slugTramite}/nominas/${item.id}/masivos/zip`
      this.$axios
        .get(urlDescarga, { responseType: 'blob' })
        .then((res) => {
          saveAs(res.data, item.nombre + '.zip')
          notificar('Descarga exitosa')
        })
        .catch((error) => {
          error = getError(error)

          this.$emit('notificar:error', 'El archivo seleccionado no se encuentra disponible.')
        })
    },
    descargarExcel(item) {
      const urlDescarga =
        `tramites/${this.slugTramite}/nominas/${item.id}/masivos/xls?nomina_id= ` + item.id

      this.$axios
        .get(urlDescarga, { responseType: 'blob' })
        .then((res) => {
          saveAs(res.data, 'Validación ' + item.nombre + '.xlsx')
          notificar(
            'Se ha descargado el archivo Excel con el detalle de las validaciones de cuentas.'
          )
        })
        .catch((error) => {
          error = getError(error)

          this.$emit('notificar:error', 'El archivo seleccionado no se encuentra disponible.')
        })
    },
    pagoMasivo(item) {
      this.nominasViewVisible = true
      this.itemActivo = item
    },
    requestValidar(item) {
      console.log(item)
      const mensaje =
        item.estado_masivo === 1
          ? '¿Deseas dar inicio al proceso de validación de la nómina?'
          : '¿Deseas dar inicio a UN NUEVO proceso de validación de la nómina?'

      if (!window.confirm(mensaje)) {
        return
      }

      const url = `tramites/${this.slugTramite}/nominas/${item.id}/masivos/validar`

      this.$axios
        .post(url)
        .then((res) => {
          notificar(
            'Se ha dado inicio a la validación de la nómina.\nRecibirás una notificación cuando el proceso se complete.'
          )
          item.estado_masivo = 2
        })
        .catch((error) => {
          error = getError(error)

          this.$emit('notificar:error', 'La solicitud de validación no se encuentra disponible.')
        })
    },
  },
}
</script>
