<template>
  <div>
    <v-form ref="form" :readonly="loading" @submit.prevent="calcular">
      <v-card width="700" class="mx-auto mt-16">
        <v-card-title>Datos para cálculo de monto a pagar</v-card-title>
        <v-card-text>
          Aquí podrás verificar los cálculos de intereses y reajustes que realizará el sistema para
          los pagos. Selecciona el trámite e ingresa el monto, la fecha de vencimiento del pago, la
          fecha de pago y luego presiona el botón "Calcular".
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <input-tramite
                v-model="form.tramite_id"
                campo="id"
                :error-messages="errors.tramite_id"
                required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" md="4">
              <z-input
                v-model="form.monto"
                type="number"
                required
                :error-messages="errors.monto"
                min="1"
                max="999999999"
                maxlength="9"
                :rules="[
                  (v) => (v && v > 0) || 'Monto debe ser mayor a 0',
                  (v) => (v && v <= 999999999) || 'Monto demasiado grande',
                ]"
                prefix="$"
                label="Monto neto cuota"
                hint="Ingresar sin corrección monetaria"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <z-input
                v-model="form.fecha_vencimiento"
                tipo="fecha"
                required
                :error-messages="errors.fecha_vencimiento"
                label="Fecha de vencimiento del pago"
                hint="Normalmente el último día del mes"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <z-input
                v-model="form.fecha_pago"
                tipo="fecha"
                required
                :error-messages="errors.fecha_pago"
                label="Fecha de pago"
                min="2022-01-01"
                :max="maxFechaPago"
                :hint="`La fecha máxima es el ${maxFechaPagoLabel}`"
                persistent-hint
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-spacer />
              <v-btn :loading="loading" color="primary" type="submit"> Calcular </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <div v-if="data && data.monto && data.monto.monto_total">
      <v-card width="350" class="mx-auto my-6" color="info" dark>
        <v-card-title>Monto a pagar</v-card-title>
        <v-card-text class="text-h2 text-center" style="white-space: nowrap">
          {{ $formato.moneda(data.monto.monto_total) }}
        </v-card-text>
      </v-card>

      <v-card width="350" class="mx-auto my-6">
        <v-card-title>Indicadores para el cálculo</v-card-title>
        <v-simple-table>
          <tbody>
            <tr v-if="data.tramite_id_1c">
              <th>Trámite primera cuota</th>
              <td>{{ tramite1cStatic.nombre }}</td>
            </tr>
            <tr v-if="data.factor2c">
              <th>Factor de corrección monetaria (IPC)</th>
              <td>{{ $formato.numero(data.factor2c) }}%</td>
            </tr>
            <tr>
              <th>Factor de reajuste</th>
              <td>{{ data.reajuste }}%</td>
            </tr>
            <tr>
              <th>Meses de atraso</th>
              <td>{{ data.meses }}</td>
            </tr>
            <tr>
              <th>Tasa de interés mensual</th>
              <td>{{ $formato.numero(data.interes_mensual) }}%</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card max-width="1000" class="mx-auto my-6">
        <v-card-title>Cálculo de monto a pagar</v-card-title>
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th>Valor</th>
              <th>Cálculo</th>
              <th>Comentario</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Monto neto cuota</th>
              <th class="text-end text-body-1">{{ $formato.moneda(data.monto_original) }}</th>
              <td></td>
              <td>Monto neto cuota cargado en la nómina</td>
            </tr>

            <tr v-if="data.factor2c">
              <th>IPC</th>
              <th class="text-end text-body-1">
                {{ $formato.moneda(data.monto.monto - data.monto_original) }}
              </th>
              <td>
                =
                <simple-chip-tooltip tooltip="Monto neto cuota">
                  {{ $formato.moneda(data.monto_original) }}
                </simple-chip-tooltip>
                *
                <simple-chip-tooltip
                  tooltip="Factor de corrección monetaria, se calcula en base a la fecha de vencimiento"
                >
                  {{ $formato.numero(data.factor2c) }}%
                </simple-chip-tooltip>
              </td>
              <td>
                Corrección monetaria para {{ tramiteStatic.nombre }}. Se aplica a trámites que
                permiten pago en cuotas
              </td>
            </tr>

            <tr>
              <th>Reajuste</th>
              <th class="text-end text-body-1">{{ $formato.moneda(data.monto.monto_reajuste) }}</th>
              <td>
                =
                <simple-chip-tooltip :tooltip="`Monto neto cuota ${labelMasIpc}`">
                  {{ $formato.moneda(data.monto.monto + 0) }}
                </simple-chip-tooltip>
                *
                <simple-chip-tooltip
                  :tooltip="`Factor de reajuste para pagos realizados el ${labelFechaPago} y vencimiento en ${labelFechaVencimiento}`"
                >
                  {{ $formato.numero(data.reajuste) }}%
                </simple-chip-tooltip>
              </td>
              <td>
                Es la suma del "monto neto cuota {{ labelMasIpc }}" con la corrección monetaria,
                multiplicado por el factor de reajuste para pagos realizados el
                {{ labelFechaPago }} y vencimiento en {{ labelFechaVencimiento }} según tabla de
                reajuste para impuestos morosos publicado por la TGR
              </td>
            </tr>

            <tr>
              <th>Interés</th>
              <th class="text-end text-body-1">
                {{ $formato.moneda(data.monto.monto_interes) }}
              </th>
              <td>
                =
                <simple-chip-tooltip :tooltip="`Monto neto cuota ${labelMasIpc} + Reajuste`">
                  {{ $formato.moneda(data.monto.monto + data.monto.monto_reajuste) }}
                </simple-chip-tooltip>
                *
                <simple-chip-tooltip tooltip="Meses de atraso">
                  {{ $formato.numero(data.meses) }}
                </simple-chip-tooltip>
                *
                <simple-chip-tooltip tooltip="Interés mensual">
                  {{ $formato.numero(data.interes_mensual) }}%
                </simple-chip-tooltip>
              </td>
              <td>
                Es la suma del "monto neto cuota {{ labelMasIpc }}" con la corrección monetaria y
                con el reajuste, multiplicado por la cantidad de meses de atraso y un
                {{ $formato.numero(data.interes_mensual) }}% de interés mensual
              </td>
            </tr>

            <tr>
              <th>Monto a pagar</th>
              <th class="text-end text-body-1">
                {{ $formato.moneda(data.monto.monto_total) }}
              </th>
              <td style="white-space: nowrap">
                =
                <simple-chip-tooltip :tooltip="`Monto neto cuota ${labelMasIpc}`">
                  {{ $formato.moneda(data.monto.monto) }}
                </simple-chip-tooltip>
                +
                <simple-chip-tooltip tooltip="Reajuste">
                  {{ $formato.moneda(data.monto.monto_reajuste) }}
                </simple-chip-tooltip>
                +
                <simple-chip-tooltip tooltip="Interés">
                  {{ $formato.moneda(data.monto.monto_interes) }}
                </simple-chip-tooltip>
              </td>
              <td>Suma de todos los conceptos anteriores</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import { getError } from '@/utils/errors'
import SimpleChipTooltip from '@/components/SimpleChipTooltip'
import InputTramite from '@/components/Input/InputTramite'

export default {
  components: {
    InputTramite,
    SimpleChipTooltip,
    ZInput,
  },
  data() {
    return {
      loading: false,
      errors: {},
      data: {},
      form: {
        monto: 1000,
        fecha_vencimiento: '',
        fecha_pago: '',
        // WIP
        tramite_id: 3,
        factor2c: 1,
      },
    }
  },
  computed: {
    tramitesEnCuotas() {
      return this.$tramites
        .filter((t) => t.pagoEnCuotas)
        .map((t) => ({ text: t.nombre, value: t.id }))
    },
    slugTramite() {
      return this.payloadListaTramitesByIdStatic[this.form.tramite_id].slug
    },
    tramiteStatic() {
      return this.payloadListaTramitesByIdStatic[this.data.tramite_id]
    },
    tramite1cStatic() {
      return this.payloadListaTramitesByIdStatic[this.data.tramite_id_1c]
    },
    maxFechaPago() {
      return this.$dayjs().endOf('month').format('YYYY-MM-DD')
    },
    maxFechaPagoLabel() {
      return this.$dayjs().endOf('month').format('D [de] MMMM')
    },
    labelFechaVencimiento() {
      return this.$dayjs(this.form.fecha_vencimiento).format('MMMM [de] YYYY')
    },
    labelFechaPago() {
      return this.$dayjs(this.form.fecha_pago).format('DD [de] MMMM [de] YYYY')
    },
    labelMasIpc() {
      return this.data.factor2c ? '+ IPC' : ''
    },
  },
  beforeMount() {
    this.form.fecha_vencimiento = this.$dayjs().endOf('month').format('YYYY-MM-DD')
    this.form.fecha_pago = this.$dayjs().format('YYYY-MM-DD')
  },
  mounted() {
    // this.$nextTick(() => this.$refs.zFormSearch.search())
  },
  methods: {
    calcular() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      this.data = {}
      this.errors = {}

      const params = new URLSearchParams(this.form)
      this.$axios
        .get(`tramites/${this.slugTramite}/calcular-pago?${params}`)
        .then((r) => {
          this.data = r.data
          this.data.monto_original ??= this.data.monto.monto
        })
        .catch((error) => {
          this.errors = getError(error)
          this.$emit('notificar:error', this.errors._message || this.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
