<template>
  <div>
    <z-mantenedor
      v-model="items"
      :botones-listar="[
        {
          title: 'Archivar',
          icon: 'mdi-file-remove-outline',
          click: archivar,
          visible: (c) =>
            c.estado_infraccion === 9 && c.estado !== 25 && $auth.can('confirmar-infraccion-jpl'),
          posicion: 'izquierda',
        },
        {
          title: 'Confirmar',
          icon: 'mdi-file-check-outline',
          click: confirmar,
          visible: (c) =>
            c.estado_infraccion === 9 && c.estado !== 25 && $auth.can('confirmar-infraccion-jpl'),
          posicion: 'izquierda',
        },
      ]"
      :campo-titulo-dialog="(v) => v.pk_label"
      :campo-subtitulo-dialog="(v) => tramite.nombre"
      :search-inicial="$route.query.buscar === 'true' || !!$route.query.search"
      :on-before-search="beforeSearch"
      :on-after-search="afterSearch"
      :on-before-dialog-view="beforeDialogView"
      :on-before-dialog-edit="beforeDialogEdit"
      max-width="800"
      disable-default-botones-dialog
      :disable-edit="!$auth.can(['editar-cuentas'])"
      :disabled-edit="
        (cuenta) =>
          ![1, 9].includes(cuenta.estado_infraccion) ||
          (cuenta.estado >= 25 && cuenta.estado <= 27) ||
          !$auth.can('editar-cuentas')
      "
      disable-delete
      :headers="headers"
      text-header-acciones="Acciones"
      :query="query"
      :url="tramiteUrl"
      :search-url="tramiteUrl + '/search'"
      search-method="post"
      @deleted="$emit('notificar', 'La cuenta ha sido eliminada.')"
      @updated="$emit('notificar', 'La cuenta ha sido actualizada.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'La cuenta no puede ser eliminada.')"
    >
      <template #buscador="{ loading: loadingMantenedor, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <input-tramite v-model="slugTramite" :tipo-institucion="3" />
            </v-col>

            <v-col cols="12" sm="6" md="7">
              <z-input
                v-model="query.filter.estado_infraccion"
                tipo="select"
                :items="payloadListaEstadosInfraccion"
                label="Estado"
                name="estado"
                clearable
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filters[0].value"
                label="Vencimiento desde"
                type="date"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filters[1].value"
                label="Vencimiento hasta"
                type="date"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.scope.fechaAudiencia"
                label="Fecha audiencia"
                type="date"
                clearable
              />
            </v-col>

            <v-col cols="7" sm="6" md="3">
              <z-input v-model="query.scope.buscarEnTramite" :label="nombrePk" />
            </v-col>

            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="6" md="5">
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                :disabled="!slugTramite || !tramite.tiposInstitucion.includes(3)"
                :loading="loadingMantenedor"
                color="primary"
                outlined
                type="submit"
              >
                Buscar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.pk_label="{ value, item }">
        {{ value }}
        <div v-if="headerOptions.institucion_id.visible" class="text--secondary text-caption">
          {{ payloadListaInstituciones[item.institucion_id] || $formato.rut(item.institucion_id) }}
        </div>
      </template>

      <template #item.estado_infraccion="{ item }">
        <seguimiento-chip-estado :cuenta="item" small />
      </template>

      <template #view="{ item }">
        <seguimiento-infraccion-view :item="item" />
      </template>

      <template #form="{ accion, form, errors }">
        <cuentas-form :accion="accion" :errors="errors" :value="form" @input="form = $event" />
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import CuentasForm from '@/admin/views/Cuentas/CuentasForm'
import InputTramite from '@/components/Input/InputTramite'
import { getError } from '@/utils/errors'
import { notificar } from '@/admin/utils/notificar'
import SeguimientoChipEstado from '@/admin/views/Infracciones/SeguimientoChipEstado'
import SeguimientoInfraccionView from '@/admin/views/Infracciones/SeguimientoInfraccionView'

export default {
  components: {
    SeguimientoInfraccionView,
    SeguimientoChipEstado,
    InputTramite,
    ZInput,
    ZMantenedor,
    ZBtn,
    CuentasForm,
  },
  data() {
    return {
      loading: false,
      items: [],
      itemActivo: {},
      nomina: {
        nombre: '',
      },
      slugTramite: this.$route.query.tramite ?? '',
      headerOptions: {
        identificador: {
          text: 'Identificador',
        },
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      query: {
        scope: {
          buscarEnTramite: this.$route.query.search || '',
          fechaAudiencia: null,
        },
        attributes: [
          'id',
          'estado',
          'pk',
          'pk_label',
          'fecha_audiencia',
          'fecha_vencimiento',
          'monto',
          'monto_utm',
          'porcentaje_descuento',
          'institucion_id',
          'estado_infraccion',
          'fecha_notificacion_infraccion_vencida',
        ],
        filter: {
          institucion_id: parseInt(this.$route.query.institucion_id),
          estado_infraccion: parseInt(this.$route.query.estado_infraccion) || null,
        },
        filters: {
          0: {
            field: 'fecha_vencimiento',
            operator: '>=',
            value: this.$dayjs().format('YYYY-MM-DD'),
          },
          1: { field: 'fecha_vencimiento', operator: '<=', value: null },
        },
        sort: {
          0: { field: 'fecha_vencimiento', direction: 'asc' },
        },
      },
    }
  },
  computed: {
    tramiteUrl() {
      return `/tramites/${this.slugTramite}/cuentas`
    },
    tramite() {
      return this.$tramites.find((t) => t.slug === this.slugTramite)
    },
    nombrePk() {
      return (
        (this.slugTramite &&
          this.$payload.tramite(this.slugTramite, 'definicion').buscador?.nombrePk) ||
        'Texto a buscar'
      )
    },
    headers() {
      return [
        {
          text: this.headerOptions.identificador.text,
          value: 'pk_label',
        },
        {
          text: 'Audiencia',
          value: 'fecha_audiencia',
          formatter: (v) => this.$formato.fecha(v, 'L'),
        },
        {
          text: 'Vencimiento',
          value: 'fecha_vencimiento',
          formatter: (v) => this.$formato.fecha(v, 'L'),
        },
        {
          text: 'Neto UTM',
          value: 'monto_utm',
          cellClass: 'text-end',
          formatter: (v) => `${v}`,
        },
        {
          text: 'Total a pagar',
          value: 'monto',
          cellClass: 'text-end',
          formatter: (v) => this.$formato.moneda(v),
        },
        {
          text: 'Estado',
          value: 'estado_infraccion',
        },
      ]
    },
  },
  mounted() {
    this.updateHeaders()
  },
  methods: {
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
    async beforeDialogView(cuenta) {
      return this.beforeDialog(cuenta, 'true')
    },
    async beforeDialogEdit(cuenta) {
      return this.beforeDialog(cuenta, 'editable')
    },

    async beforeDialog(cuenta, registro = '') {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`tramites/${this.slugTramite}/cuentas/${cuenta.id}?registro=${registro}`)
          .then((response) => {
            resolve(response.data.data)
          })
          .catch((error) => {
            error = getError(error)
            notificar({ message: error, severity: 'error' })
            reject(error)
          })
      })
    },
    beforeSearch(query) {
      // if (query.filter.estado?.length === 0) {
      //   query.filter.estado = null
      // }
    },
    afterSearch() {
      this.updateHeaders()
    },
    updateHeaders() {
      this.headerOptions.institucion_id.visible =
        !this.query.filter.institucion_id && this.$auth.can(['operador', 'mesa-de-ayuda'])
      this.headerOptions.identificador.text = this.$payload.tramite(
        this.slugTramite,
        'definicion'
      ).buscador?.nombrePk
    },
    archivar(cuenta) {
      if (!confirm(`¿Deseas archivar la infracción ${cuenta.pk_label}?`)) {
        return
      }
      this.loading = true
      this.$axios
        .post(`tramites/${this.slugTramite}/infracciones/${cuenta.id}/archivar`)
        .then((response) => {
          this.$set(
            this.items,
            this.items.findIndex((item) => item.id === cuenta.id),
            response.data.data
          )
        })
        .catch((error) => {
          notificar({ message: getError(error), severity: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirmar(cuenta) {
      if (!confirm(`¿Deseas confirmar la infracción ${cuenta.pk_label}?`)) {
        return
      }
      this.loading = true
      this.$axios
        .post(`tramites/${this.slugTramite}/infracciones/${cuenta.id}/confirmar`)
        .then((response) => {
          this.$set(
            this.items,
            this.items.findIndex((item) => item.id === cuenta.id),
            response.data.data
          )
        })
        .catch((error) => {
          notificar({ message: getError(error), severity: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
