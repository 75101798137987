<template>
  <div>
    <z-mantenedor
      v-model="items"
      :botones-listar="[
        {
          title: 'Ver historial del usuario',
          icon: 'mdi-history',
          click: mostrarHistorial,
          posicion: 'derecha',
          disabled: (i) => !i.user_id,
        },
      ]"
      :botones-ver="[
        {
          title: 'Historial',
          icon: 'mdi-history',
          click: mostrarHistorial,
          visible: (i) => !!i.user_id,
        },
      ]"
      :campo-subtitulo-dialog="(v) => v.nombre"
      :campo-titulo-dialog="(v) => $formato.rut(v.user_id) || v.payload.login"
      :headers="headers"
      :query="query"
      url="/logs/logs"
      search-url="/logs/logs/search"
      search-method="post"
      disable-delete
      disable-edit
      texto-filtrar="Filtrar"
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="5" md="4">
              <z-input
                v-model="query.filter.institucion_id"
                :items="instituciones"
                clearable
                label="Institución"
                tipo="autocomplete"
              />
            </v-col>
            <v-col cols="8" sm="4" md="4">
              <z-input
                v-model="query.filter.nombre"
                :items="['login', 'login-fallido', 'refresh', 'logout']"
                clearable
                label="Acción"
                multiple
              />
            </v-col>
            <v-col cols="4" sm="3" md="2">
              <z-input v-model="query.filter.user_id" clearable label="Id" />
            </v-col>

            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #item.fecha="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss'
          )
        }}
        <div class="text--secondary text-caption">({{ $dayjs(value).fromNow() }})</div>
      </template>

      <template #view="{ item }">
        <v-simple-table>
          <tbody>
            <tr>
              <th>Acción</th>
              <td>{{ item.nombre }}</td>
            </tr>

            <tr v-if="item.payload.anterior">
              <th>Login anterior</th>
              <td>
                {{ item.payload.anterior.login }} ({{ item.payload.anterior.user_id }})
                <div v-if="item.payload.anterior.permissions" class="text--secondary text-caption">
                  {{ item.payload.anterior.permissions.join(', ') }}
                </div>
              </td>
            </tr>
            <tr>
              <th>Login</th>
              <td>
                {{ item.payload.login }} ({{ item.user_id }})
                <div v-if="item.payload.permissions" class="text--secondary text-caption">
                  {{ item.payload.permissions.join(', ') }}
                </div>
              </td>
            </tr>
            <tr>
              <th>Institución</th>
              <td>{{ $payload.institucion(item.institucion_id) }}</td>
            </tr>
            <tr>
              <th>Fecha</th>
              <td>
                {{ $dayjs(item.fecha).format('LLLL:ss') }}
                <div class="text--secondary text-caption">({{ $dayjs(item.fecha).fromNow() }})</div>
              </td>
            </tr>
            <tr v-if="item.payload && item.payload.ip">
              <th>IP</th>
              <td>{{ item.payload.ip }}</td>
            </tr>
            <tr v-if="item.payload && item.payload.userAgent">
              <th>UserAgent</th>
              <td class="text-caption">
                {{ item.payload.userAgent }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </z-mantenedor>

    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.user_id"
      microservicio="usuarios"
      clase="user"
      titulo="Historial de edición del usuario"
      @cerrar="historialVisible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogHistorial from '@/admin/components/DialogHistorial'
import ZBtn from '@/components/ZBtn'
import ZInput from '@/components/Input/ZInput'
import ZMantenedor from '@/components/ZMantenedor'

export default {
  components: { DialogHistorial, ZInput, ZMantenedor, ZBtn },
  data() {
    return {
      items: [],
      itemActivo: {},
      historialVisible: false,
      headers: [
        {
          text: 'Fecha',
          value: 'fecha',
        },
        {
          text: 'Acción',
          value: 'nombre',
        },
        {
          text: 'Login',
          value: 'payload',
          formatter: (payload, item) =>
            this.$formato.login(payload.login_usuario) || this.$formato.rut(item.user_id),
        },
        {
          text: 'Institución',
          value: 'institucion_id',
          formatter: (v) => this.$payload.institucion(v),
        },
      ],
      query: {
        filter: {
          microservicio: null,
          nombre: null,
          tipo: 'user',
        },
        scopes: {
          0: { name: 'desde', parameters: ['6 month ago'] },
        },
        sort: {
          0: { field: 'fecha', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    ...mapGetters('app', ['payload']),
    instituciones() {
      return this.payload.instituciones.map((i) => ({
        text: i.nombre,
        value: parseInt(i.id),
      }))
    },
  },
  methods: {
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
  },
}
</script>
