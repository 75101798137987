<template>
  <v-simple-table v-bind="$attrs" v-on="$listeners">
    <tbody>
      <template v-if="cuenta.registro">
        <template v-for="[campo, columna] in columnasDefinicionTramite">
          <tr v-if="cuenta.registro.hasOwnProperty(campo)" :key="campo">
            <th>{{ columna.nombre }}</th>
            <td>{{ valorRegistro(columna, cuenta.registro[campo]) }}</td>
          </tr>
        </template>
      </template>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    definicionTramite() {
      return this.$payload.tramite(this.cuenta.tramite_id, 'definicion', 'id') || {}
    },
    columnasDefinicionTramite() {
      return Object.entries(this.definicionTramite.columnas).sort(
        (a, b) => a[1].prioridadEdicion - b[1].prioridadEdicion
      )
    },
  },
  methods: {
    valorRegistro(columna, valor) {
      if (columna.tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\Moneda') {
        return this.$formato.moneda(valor)
      }
      if (columna.tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\Fecha') {
        return this.$formato.fecha(valor)
      }
      if (columna.tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\FechaHora') {
        return this.$formato.fechaHora(valor)
      }
      if (columna.tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\Rut') {
        return this.$formato.rut(valor)
      }
      if (columna.tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\Porcentaje') {
        return this.$formato.porcentaje(valor)
      }
      if (columna.tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\Utm') {
        return this.$formato.utm(valor)
      }

      return valor
    },
  },
}
</script>
