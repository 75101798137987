<template>
  <div>
    <v-row>
      <v-col>
        <z-input
          v-model.trim="form.name"
          :error-messages="errors.name"
          label="Nombre"
          minlength="3"
        />
      </v-col>
      <v-col>
        <z-input
          v-model.trim="form.apellido"
          :error-messages="errors.apellido"
          label="Apellido"
          minlength="3"
        />
      </v-col>
    </v-row>
    <v-row v-if="$auth.can(['operador', 'mesa-de-ayuda'])">
      <v-col>
        <z-input
          v-model="form.institucion_id"
          :error-messages="errors.institucion_id"
          :items="payloadItemsInstituciones"
          tipo="autocomplete"
          label="Institución"
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <z-input
          v-model="form.roles"
          :error-messages="errors.roles"
          :items="roles"
          item-text="name"
          item-value="id"
          multiple
          return-object
          tipo="select"
          label="Rol"
          hint="Selecciona el rol que tendrá el usuario"
          persistent-hint
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="7">
        <z-input v-model="form.cargo" :error-messages="errors.cargo" label="Cargo" maxlength="50" />
      </v-col>
      <v-col>
        <z-input v-model="form.telefono" :error-messages="errors.telefono" label="Teléfono" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <z-input
          v-model.trim="form.email"
          :error-messages="errors.email"
          label="Correo electrónico"
          required
          type="email"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <z-input
          v-model="form.rut"
          :error-messages="errors.rut"
          tipo="rut"
          :required="accion === 'create'"
          :disabled="accion === 'edit'"
        />
      </v-col>
      <v-col cols="3">
        <z-input
          v-model="form.activo"
          :error-messages="errors.activo"
          label="Activo"
          tipo="checkbox"
        />
      </v-col>
      <v-col v-if="accion === 'edit'" cols="5">
        <z-input
          v-model="form.principal"
          :error-messages="errors.principal"
          label="Cuenta principal"
          tipo="checkbox"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import { mapGetters } from 'vuex'

export default {
  components: { ZInput },
  props: {
    accion: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', ['payload']),
    roles() {
      console.log(this.payloadListaRoles)
      return Object.entries(this.payloadListaRoles)
        .map((v) => ({ name: v[1], id: parseInt(v[0]) }))
        .filter((v) => v.name !== 'Ciudadano')
        .filter((v) => v.name !== 'ciudadano')
        .filter((v) => v.name !== 'Super-admin')
        .filter((v) => v.name !== 'super-admin')
    },
    form: {
      get: function () {
        console.log(this.value)
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {},
}
</script>
