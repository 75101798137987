<template>
  <div>
    <z-mantenedor
      v-model="items"
      :headers="headers"
      :query="query"
      url="/logs/logs"
      search-url="/logs/logs/search"
      search-method="post"
      disable-delete
      disable-edit
    >
      <template #item.nombre="{ item }">
        {{ item.nombre }}
        <div v-if="item.clase" class="font-weight-light text-caption">
          {{ item.clase }}
        </div>
      </template>

      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <z-input
                v-model="query.filter.tipo"
                :items="['evento', 'job', 'log', 'model', 'user']"
                clearable
                chips
                label="Tipo"
                multiple
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <z-input v-model="query.filter.clase" clearable label="clase" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filter.nivel"
                :items="['debug', 'info', 'notice', 'warning', 'error']"
                clearable
                label="Severidad"
                multiple
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filter.microservicio"
                :items="[
                  'logs',
                  'pagos',
                  'tramitesgenerales',
                  'permisosdecirculacion',
                  'permisosdecirculacion2c',
                  'usuarios',
                ]"
                clearable
                label="Microservicio"
                multiple
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #item.fecha="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss'
          )
        }}<span class="text--secondary text-caption">{{ $dayjs(value).format('.SSS') }}</span>
        <div class="text--secondary text-caption">
          {{ $dayjs(value).fromNow() }}
        </div>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'

export default {
  components: { ZInput, ZMantenedor, ZBtn },
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Fecha',
          value: 'fecha',
        },
        {
          text: 'Microservicio',
          value: 'microservicio',
        },
        {
          text: 'Tipo',
          value: 'tipo',
        },
        {
          text: 'Severidad',
          value: 'nivel',
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
      ],
      query: {
        filter: {
          nivel: null,
          microservicio: null,
          clase: null,
          tipo: null,
        },
        sort: {
          0: { field: 'fecha', direction: 'desc' },
        },
      },
    }
  },
  beforeMount() {
    // this.query.scopes[0].parameters[0] = this.anoActual
  },
}
</script>
