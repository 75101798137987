<template>
  <div>
    <!--

            Título del trámite

    -->

    <div v-if="modo === 'portal'" class="mx-auto mb-10 text-h5 text-slab" style="max-width: 600px">
      {{ tramiteStatic.nombre }}
    </div>

    <!--

            Formulario de búsqueda

    -->

    <v-card
      v-if="paso === 1 && tramiteStatic.formularioDeBusqueda"
      :elevation="$vuetify.breakpoint.xs ? 0 : 2"
      class="mx-auto mt-sm-4 mb-sm-10 mb-8"
      max-width="600"
    >
      <v-form aria-label="Buscador" role="search" @submit.prevent="buscar">
        <v-row v-if="mostrarFormInstitucion" v-show="modo === 'portal'" class="mx-0">
          <v-col v-if="mostrarFormJpl" cols="12" sm="4">
            <select-jpl v-model="form.jpl" dense rounded solo sufijo="JPL" />
          </v-col>
          <v-col cols="12" sm="8">
            <v-autocomplete
              v-model="form.institucion_id"
              :items="payloadItemsMunicipalidades"
              :readonly="modo === 'admin'"
              auto-select-first
              dense
              hide-details
              rounded
              solo
              label="Municipalidad"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-text-field
              v-model.trim="form.search"
              :hide-details="cuentas.length <= 1"
              :hint="
                cuentasFiltradas.length === cuentas.length
                  ? `${cuentas.length} resultados encontrados.`
                  : `Mostrando ${cuentasFiltradas.length} de los ${cuentas.length} resultados encontrados.`
              "
              :label="tramiteStatic.label"
              aria-label="Buscar"
              class="mx-4 input-buscador"
              data-testid="buscar"
              inputmode="search"
              name="search"
              outlined
              persistent-hint
              spellcheck="false"
              type="search"
            >
              <template slot="append-outer">
                <v-btn :loading="loading" class="mt-n2" color="primary" type="submit">
                  <v-icon class="hidden-sm-and-up"> mdi-magnify </v-icon>
                  <span class="hidden-xs-only">Buscar</span>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="cuentas.length > 0">
          <v-col cols="12">
            <buscador-filtros-cuenta
              v-model="filtrarCuentas"
              :cantidad-no-pagadas="cuentasFiltradasNoPagadas.length"
              :cantidad-pagadas="cuentasFiltradasPagadas.length"
              :cantidad-seleccionadas="cuentasAPagar.length"
              :cantidad-total="cuentas.length"
              :cantidad-vencidas="cuentasFiltradasVencidas.length"
              :tramite-static="tramiteStatic"
              class="mt-n4 ml-4"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!--

            Texto con resultados de búsqueda para screen readers

    -->

    <v-alert v-if="!loading && !error && cuentas.length" class="d-sr-only">
      <span v-if="cuentas.length === 1">Un resultado encontrado.</span>
      <span v-else> {{ cuentas.length }} resultados encontrados.</span>
    </v-alert>

    <!--

            Mensaje "Buscando..." en caso que la búsqueda tarde un poco

    -->

    <div v-show="loading && loadingDemorando" class="text-center my-16">
      <v-progress-circular :size="70" color="primary" indeterminate />
      <br />
      <div class="text-caption mt-2">Buscando...</div>
    </div>

    <!--

            Resultados de búsqueda

    -->

    <div v-if="cuentas.length && !loading">
      <alerta-beneficio-zona-franca
        v-if="cuentasDisponiblesParaPagar.length > 0 && tieneBeneficioZonaFranca"
        v-model="usaBeneficioZonaFranca"
        class="mx-auto mt-4 mb-8"
        max-width="600"
      />

      <section>
        <buscador-ficha
          v-for="cuenta in cuentasFiltradas"
          :key="cuenta.id"
          :cuenta="cuenta"
          :puede-cambiar-seleccion="cuentaPuedeCambiarSeleccion(cuenta)"
          :value="cuenta._seleccionado"
          class="mx-auto my-12"
          max-width="600"
          @input="toggleCuenta($event, cuenta)"
        />
      </section>

      <!--

              Cuadro para avanzar al siguiente paso

      -->
      <template v-if="cuentasDisponiblesParaPagar.length > 0 && horarioHabil()">
        <v-sheet
          v-if="tramiteStatic.pagoEnPasos && paso === 1"
          class="mx-auto my-16 text-center align-center justify-center"
          max-width="500"
        >
          <div class="text-body-2 my-4">
            {{ tramiteStatic.pagoEnPasosTexto }}
          </div>
          <v-btn
            :disabled="!haySeleccion"
            class="mx-auto text-center align-center justify-center"
            color="primary"
            @click="checkout(2)"
          >
            Continuar al siguiente paso
            <v-icon right>
              {{ svg.mdiChevronRight }}
            </v-icon>
          </v-btn>
          <div v-if="!haySeleccion" class="text-caption">
            (Para continuar, debes seleccionar al menos
            {{ tramiteStatic.txtGenero('un', 'una') }}
            {{ tramiteStatic.nombreSingular.toLowerCase() }})
          </div>
        </v-sheet>

        <!--

              Formulario con datos de contacto

        -->

        <section
          v-if="cuentasDisponiblesParaPagar.length > 0 && (!tramiteStatic.pagoEnPasos || paso > 1)"
        >
          <v-form
            ref="formContacto"
            v-model="formPagarValido"
            class="mx-4"
            lazy-validation
            @submit.prevent="pagar"
          >
            <v-card
              :elevation="$vuetify.breakpoint.xs ? 0 : 2"
              class="mx-auto mt-10 mb-6"
              width="600"
            >
              <v-card-title class="text-h5 text-slab"> Datos de contacto </v-card-title>

              <v-card-subtitle>
                Te solicitaremos algunos datos para enviar el documento PDF y para poder
                identificarte en caso que quieras volver a descargarlo, o que hayas introducido un
                correo electrónico equivocado.
              </v-card-subtitle>

              <v-card-text>
                <buscador-formulario-contacto
                  v-model="contacto"
                  :errors="formErrors"
                  :tramite-static="tramiteStatic"
                />

                <formulario-contacto-zona-franca
                  v-if="usaBeneficioZonaFranca"
                  v-model="contacto"
                  :errors="formErrors"
                  :tramite-static="tramiteStatic"
                />

                <buscador-formulario-contacto-admin
                  v-if="modo === 'admin'"
                  v-model="contacto"
                  :errors="formErrors"
                  :tramite-static="tramiteStatic"
                />
              </v-card-text>
            </v-card>

            <!--

            Formulario de beneficio de zona franca

            -->
            <declaracion-jurada-beneficio-zona-franca
              v-if="tieneBeneficioZonaFranca"
              v-show="usaBeneficioZonaFranca && datosDeContactoIngresadosBeneficioZonaExtrema"
              v-model="declaracionJuradaBeneficioZonaFranca"
              :contacto="contacto"
              :cuentas="cuentasAPagar"
            />

            <!--

            Botón de pago y selección de cuotas

            -->

            <v-card
              :elevation="$vuetify.breakpoint.xs ? 0 : 2"
              class="mx-auto mt-10 mb-6"
              width="600"
            >
              <v-card-title class="text-h5 text-slab"> Pago </v-card-title>

              <template
                v-if="
                  montoTotalAPagar > 0 &&
                  tramiteStatic.pagoEnCuotas &&
                  cuentasAPagarPermitenPagoEnCuotas.length === cuentasAPagar.length
                "
              >
                <v-card-title>Modalidad de pago</v-card-title>
                <v-card-subtitle>
                  Puedes pagar
                  {{ tramiteStatic.parrafo('{el} {nombre}') }}
                  en dos cuotas.
                  <span v-if="totalMultas > 0">
                    Sin embargo, deberás pagar ahora el monto total de las multas.
                  </span>
                </v-card-subtitle>

                <buscador-opciones-pago
                  v-model="cuotas"
                  :total-a-pagar="montoTotalAPagar"
                  :total-cuentas="totalCuentas"
                  :total-cuentas-primera-cuota="totalCuentasPrimeraCuota"
                  :total-multas="totalMultas"
                  :tramite-static="tramiteStatic"
                />
              </template>

              <v-card-text class="text-center">
                <div class="text-slab text-h6 mb-4">
                  Total:
                  {{ $formato.moneda(montoTotalAPagar) }}
                  <div v-show="cuentasAPagar.length > 1" class="text-caption">
                    ({{
                      tramiteStatic.parrafo(
                        '{cantidad} {nombre} seleccionad{o}{s}',
                        cuentasAPagar.length
                      )
                    }})
                  </div>
                </div>

                <div v-show="!haySeleccion" class="text-caption">
                  (Para continuar, debes seleccionar al menos
                  {{ tramiteStatic.parrafo('{un} {nombre}') }}
                  a pagar)
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="
                    !haySeleccion ||
                    !horarioHabil() ||
                    (usaBeneficioZonaFranca && !declaracionJuradaBeneficioZonaFranca)
                  "
                  :loading="loading"
                  class="mx-auto mb-4"
                  color="primary"
                  large
                  type="submit"
                >
                  <span v-if="modo === 'admin'"> Marcar como pagado </span>
                  <span v-else> Ir a pagar </span>
                </v-btn>
              </v-card-actions>
              <v-card-text class="text-center pt-0 mt-n4 text-caption">
                <div
                  v-if="usaBeneficioZonaFranca && !declaracionJuradaBeneficioZonaFranca"
                  class=""
                >
                  (para poder pagar debes
                  <span v-show="!datosDeContactoIngresadosBeneficioZonaExtrema">
                    ingresar tus datos y
                  </span>
                  aceptar las condiciones de la declaración jurada)
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </section>
      </template>
    </div>

    <!--

        Mensaje de horario no habil

    -->

    <v-card
      v-if="!horarioHabil()"
      :elevation="$vuetify.breakpoint.xs ? 0 : 2"
      class="mx-auto mt-10 mb-6"
      width="600"
    >
      <v-card-title class="text-h5 text-slab"> Fuera de horario </v-card-title>

      <v-card-text class="text-center text-cation">
        El servicio de pago no está disponible entre las 23:50 y 00:10 hrs.
        <div v-if="$dayjs.tz.guess() !== 'America/Santiago'">(Hora de Chile Continental)</div>
      </v-card-text>
      <v-card-text class="text-center text-cation">
        Para iniciar el pago deberás esperar unos minutos.
      </v-card-text>
    </v-card>

    <!--
            Mensajes de error

            Puede ser como un <v-dialog> (dialogError = true)
            o mediante un <v-alert> para mensajes menos importantes
    -->

    <div v-if="error && !loading">
      <v-dialog v-if="dialogError" v-model="dialogError" persistent width="400">
        <v-card>
          <v-card-title>
            {{ dialogError }}
          </v-card-title>
          <v-card-text class="py-4 text-pre-line">
            {{ error }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mb-2" color="primary" text @click="dialogError = ''"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-alert v-else class="mx-auto" outlined type="info" width="500">
        <template v-if="!errorDetalle">
          {{ error }}
        </template>
        <template v-else>
          <p class="text-h6">
            {{ error }}
          </p>
          <p v-html="errorDetalle" />
        </template>
      </v-alert>
    </div>

    <!--

    Diálogo al ir al paso siguiente

    -->

    <dialog-progress v-if="loading && paso > 1">
      <div class="text-caption mb-4">
        Validando
        {{
          cuentasAPagar === 1
            ? tramiteStatic.nombreSingular.toLowerCase()
            : tramiteStatic.nombrePlural.toLowerCase()
        }}
      </div>
      <div class="text-body-1">Esta operación tardará un momento</div>
    </dialog-progress>

    <!--

    Diálogo al iniciar el pago en la TGR

    -->

    <dialog-progress v-if="cargandoTgr">
      <div v-if="modo === 'portal'">
        <div class="text-caption mb-4">Iniciando pago en</div>
        <div class="text-body-1">Tesorería General de la República</div>
        <logo-tgr style="width: 70px" />
      </div>
      <div v-else>
        <div class="text-body-1">Emitiendo pagos</div>
      </div>
    </dialog-progress>

    <v-snackbar
      v-model="snackbar"
      :class="{
        'mb-16': cuentasAPagar.length > 0,
      }"
      color="success"
      timeout="8000"
    >
      {{ snackbarText }}

      <template #action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!--

    Barra inferior mostrando la cantidad de cuentas seleccionadas

    -->

    <div v-if="cuentasDisponiblesParaPagar.length > 1 && cuentasAPagar.length > 0">
      <v-tooltip v-if="tramiteStatic.pagoEnPasos && paso === 1" top>
        <template #activator="{ on, attrs }">
          <v-btn
            bottom
            class="float-end hidden-xs-only"
            color="primary"
            fab
            fixed
            right
            style="z-index: 10"
            v-bind="attrs"
            @click="checkout(2)"
            v-on="on"
          >
            <v-icon>{{ svg.mdiChevronRight }}</v-icon>
          </v-btn>
        </template>
        Continuar al siguiente paso
      </v-tooltip>

      <v-bottom-navigation app class="primary" dark>
        <div class="d-flex flex-column justify-center align-center">
          <div class="text-caption font-weight-medium">
            {{
              tramiteStatic.parrafo('{cantidad} {nombre} seleccionad{o}{s}', cuentasAPagar.length)
            }}
          </div>
          <div class="mx-10" style="overflow: hidden; white-space: nowrap">
            <v-tooltip v-for="cuenta in cuentasAPagar" :key="cuenta.id" top>
              <template #activator="{ on, attrs }">
                <v-chip
                  :close="cuentaPuedeCambiarSeleccion(cuenta)"
                  :close-icon="svg.mdiClose"
                  class="text-caption mr-1"
                  color="white"
                  label
                  outlined
                  small
                  style="opacity: 0.8"
                  v-bind="attrs"
                  v-on="on"
                  @click:close="toggleCuenta(false, cuenta)"
                >
                  {{ cuenta.pk_label }}
                </v-chip>
              </template>
              <div v-show="cuenta.pago_obligatorio" class="font-weight-medium">
                Pago obligatorio
              </div>
              <div v-show="cuenta.subtitulo" class="font-weight-medium">
                {{ cuenta.subtitulo }}
              </div>
              {{ $formato.moneda(cuenta.montos.monto_total) }}
              <span v-show="cuenta.montos.monto_extra">
                + {{ $formato.moneda(cuenta.montos.monto_extra) }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-bottom-navigation>
    </div>
  </div>
</template>

<script>
import LogoTgr from '@/components/Icons/LogoTgr'
import DialogProgress from '@/portal/components/DialogProgress'
import BuscadorFicha from '@/portal/components/BuscadorFicha'
import BuscadorOpcionesPago from '@/portal/components/BuscadorOpcionesPago'
import BuscadorFormularioContacto from '@/portal/components/BuscadorFormularioContacto'
import { mdiClose, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import BuscadorFiltrosCuenta from '@/portal/components/BuscadorFiltrosCuenta'
import AlertaBeneficioZonaFranca from '@/portal/components/PermisosDeCirculacion/AlertaBeneficioZonaFranca'
import FormularioContactoZonaFranca from '@/portal/components/PermisosDeCirculacion/FormularioContactoZonaFranca'
import DeclaracionJuradaBeneficioZonaFranca from '@/portal/components/PermisosDeCirculacion/DeclaracionJuradaBeneficioZonaFranca'
import { getError } from '@/utils/errors'
import BuscadorFormularioContactoAdmin from '@/portal/components/BuscadorFormularioContactoAdmin'
import SelectJpl from '@/portal/components/SelectJpl'

export default {
  components: {
    SelectJpl,
    BuscadorFormularioContactoAdmin,
    DeclaracionJuradaBeneficioZonaFranca,
    FormularioContactoZonaFranca,
    AlertaBeneficioZonaFranca,
    BuscadorFiltrosCuenta,
    BuscadorFormularioContacto,
    BuscadorOpcionesPago,
    BuscadorFicha,
    DialogProgress,
    LogoTgr,
  },
  beforeRouteUpdate() {
    // console.log(this.$route.fullPath)
  },
  props: {
    tramite: {
      type: String,
      required: true,
    },
    institucion: {
      type: String,
      default: '',
    },
    jpl: {
      type: String,
      default: '',
    },
    modo: {
      type: String,
      required: true, // 'portal' o 'admin'
    },
  },
  data() {
    return {
      svg: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiClose,
      },

      /**
       * Indicadores de que se está realizando un request al backend
       */
      // Indica si se está haciendo una búsqueda o request relacionado
      loading: false,
      // Indica que la búsqueda está demorando un poco
      loadingDemorando: false,
      // Indica que se está haciendo un request de inicio de pago con la TGR
      cargandoTgr: false,

      /**
       * Búsqueda de cuentas
       */
      // Formulario de búsqueda
      form: {
        search: '',
        institucion_id: '',
      },
      // Lista de cuentas encontradas por la búsqueda
      cuentas: [],
      // Indica si se deben filtrar las cuentas encontradas por "vencida", "pagada", "no-pagada", ...
      filtrarCuentas: '',

      /**
       * Mensajes de error
       */
      // Errores leves como por ejemplo un resultado de búsqueda sin resultados
      error: '',
      // Texto adicional para errores
      errorDetalle: '',
      // Errores graves que se mostrarán en un dialog
      dialogError: false,
      // Array de errores, normalmente de validación de formulario
      formErrors: {},

      /**
       * Mensajes en snackbar
       */
      snackbar: false,
      snackbarText: '',

      // Paso del proceso de búsqueda.
      // - El paso 1 es el resultado de búsqueda
      // - El paso 2 es el checkout (donde se ingresa nombre, email, ... y se inicia el pago)
      paso: 1,

      /**
       * Beneficio de zona extrema
       */
      // Indica si la comuna tiene opción del beneficio tributario de zona extrema
      tieneBeneficioZonaFranca: false,
      // Indica si el ciudadano quiere acogerse al beneficio tributario de zona extrema
      usaBeneficioZonaFranca: true,
      // Indica si el ciudadano "firmó" la declaración jurada para acogerse al beneficio
      declaracionJuradaBeneficioZonaFranca: true,

      // Indica si el ciudadano escogió la opción de pago en cuotas
      cuotas: false,

      /**
       * Formulario de contacto
       */
      // Formulario con datos de contacto antes de pagar
      contacto: {
        nombre: '',
        email: '',
        rut: '',
        // Datos adicionales para beneficio Zona Extrema
        domicilio: '',
        ciudad: '',
        // Datos de "admin"
        folio: '',
      },
      // Indica si el formulario de contacto fue correctamente llenado
      formPagarValido: true,
    }
  },
  computed: {
    mostrarFormJpl() {
      return this.tramiteStatic.requiereJpl // } || this.form.jpl
    },

    mostrarFormInstitucion() {
      return this.tramiteStatic.requiereInstitucion || this.form.institucion_id
    },
    haySeleccion() {
      return (
        !!this.cuentas.find((c) => c._seleccionado) || this.cuentasDisponiblesParaPagar.length === 1
      )
    },

    /**
     * Retorna un array de cuentas para ser mostradas como resultado de búsqueda, las que podrían
     * estar filtradas por criterios como: vencida, pagada, ninguno, entre otros.
     */
    cuentasFiltradas() {
      if (this.filtrarCuentas === 'vencida') {
        return this.cuentasFiltradasVencidas
      }
      if (this.filtrarCuentas === 'pagada') {
        return this.cuentasFiltradasPagadas
      }
      if (this.filtrarCuentas === 'no-pagada') {
        return this.cuentasFiltradasNoPagadas
      }
      if (this.filtrarCuentas === 'seleccionada') {
        return this.cuentasAPagar
      }
      return this.cuentas
    },

    cuentasFiltradasVencidas() {
      return this.cuentas.filter((c) => !this.cuentaPagada(c) && this.cuentaVencida(c))
    },

    cuentasFiltradasPagadas() {
      return this.cuentas.filter((c) => this.cuentaPagada(c))
    },

    cuentasFiltradasNoPagadas() {
      return this.cuentas.filter((c) => !this.cuentaPagada(c))
    },

    /**
     * Retorna un array de cuentas que pueden ser seleccionadas para pagar.
     * Descarta por ejemplo las cuentas ya pagadas.
     */
    cuentasDisponiblesParaPagar() {
      return this.cuentas.filter((c) => c.pagable)
    },

    /**
     * Retorna un array de cuentas seleccionadas para pagar
     */
    cuentasAPagar() {
      if (this.cuentasDisponiblesParaPagar.length === 1) {
        return this.cuentasDisponiblesParaPagar
      } else {
        return this.cuentasDisponiblesParaPagar.filter((c) => c._seleccionado)
      }
    },

    /**
     * Retorna un array de de cuentas para pagar que permiten el pago en cuotas
     */
    cuentasAPagarPermitenPagoEnCuotas() {
      return this.cuentasAPagar.filter((c) => c.cuotas === true)
    },

    /**
     * Retorna el monto total a pagar de acuedo a las cuentas seleccionadas, multas y opciones de pago.
     * Este será el monto que se pagará en la TGR.
     *
     * @returns {number}
     */
    montoTotalAPagar() {
      const cuentas = this.cuotas ? this.totalCuentasPrimeraCuota : this.totalCuentas
      return cuentas + this.totalMultas
    },
    totalCuentas() {
      return this.cuentasAPagar.reduce((suma, cuenta) => suma + cuenta.montos.monto_total, 0)
    },
    totalCuentasPrimeraCuota() {
      return Math.ceil(this.totalCuentas / 2)
    },
    totalMultas() {
      return this.cuentasAPagar.reduce((suma, cuenta) => suma + cuenta.montos.monto_extra, 0)
    },
    tramiteStatic() {
      return this.$tramites.find((t) => t.slug === this.tramite)
    },
    datosDeContactoIngresadosBeneficioZonaExtrema() {
      return (
        this.contacto.nombre && this.contacto.rut && this.contacto.ciudad && this.contacto.domicilio
      )
    },
  },
  watch: {
    usaBeneficioZonaFranca() {
      this.actualizarMontosCuentasBeneficioZonaFranca()
    },
  },
  beforeMount() {
    this.form.institucion_id = parseInt(this.institucion)
    this.form.jpl = parseInt(this.jpl)

    // Verifica si debe hacer una búsqueda inicial
    const search = (this.$route.params.search || this.$route.query.search || '').trim()

    if (search !== '0' && search) {
      this.form.search = search
      this.buscar()
    }
  },
  methods: {
    horarioHabil() {
      const hh = parseInt(this.$dayjs().hour())
      const mm = parseInt(this.$dayjs().tz('America/Santiago').minute())
      return !((hh === 23 && mm >= 50) || (hh === 0 && mm <= 10))
    },

    cuentaPagable(cuenta) {
      return cuenta.pagable
    },

    cuentaPagada(cuenta) {
      return cuenta.estado >= 25 && cuenta.estado <= 27
    },

    cuentaVencida(cuenta) {
      return this.$dayjs(cuenta.fecha_vencimiento).diff(this.$dayjs(), 'second') < 0
    },

    cuentaPuedeCambiarSeleccion(cuenta) {
      return (
        this.cuentasDisponiblesParaPagar.length !== 1 &&
        !cuenta.pago_obligatorio &&
        this.cuentaPagable(cuenta)
      )
    },

    /**
     * Marca la cuenta solo seleccionada o no.
     */
    toggleCuenta(event, cuenta) {
      cuenta._seleccionado = event

      if (cuenta.pago_segun_vencimiento) {
        this.toggleCuentasSegunFechaDeVencimiento(cuenta)
      }
    },

    /**
     * Valida que todas las cuentas con vencimiento anterior también estén marcadas
     */
    toggleCuentasSegunFechaDeVencimiento(cuenta) {
      const fechaVencimiento = this.$dayjs(cuenta.fecha_vencimiento)

      let cuentasAgregadas = 0
      let cuentasQuitadas = 0
      this.cuentasDisponiblesParaPagar
        .filter((c) => c.id !== cuenta.id && c.pago_segun_vencimiento && c.pk === cuenta.pk)
        .forEach((c) => {
          const diferenciaDeVencimiento = this.$dayjs(c.fecha_vencimiento).diff(
            fechaVencimiento,
            'day'
          )
          if (diferenciaDeVencimiento < 0 && c._seleccionado === false) {
            c._seleccionado = true
            cuentasAgregadas++
          }
          if (diferenciaDeVencimiento > 0 && c._seleccionado === true) {
            c._seleccionado = false
            cuentasQuitadas++
          }
        })

      this.snackbar = false
      this.$nextTick(() => {
        if (cuentasQuitadas > 0) {
          const parrafo = this.tramiteStatic.parrafo(
            `Se {n:quitó:quitaron} {cantidad} {nombre} "${cuenta.pk_label}" que vence{n}`,
            cuentasQuitadas
          )
          this.snackbarText = `${parrafo} después del ` + fechaVencimiento.format('DD/MM/YYYY')
          this.snackbar = true
        }
        if (cuentasAgregadas > 0) {
          const parrafo = this.tramiteStatic.parrafo(
            `Se {n:agregó:agregaron} {cantidad} {nombre} "${cuenta.pk_label}" que vence{n}`,
            cuentasAgregadas
          )
          this.snackbarText = `${parrafo} antes del ` + fechaVencimiento.format('DD/MM/YYYY')
          this.snackbar = true
        }
      })
    },

    buscar() {
      const search = this.form.search?.trim().toUpperCase()
      if (!search) {
        alert('Debes ingresar un texto de búsqueda.')
        return
      }

      if (this.modo === 'portal') {
        let retornar = false

        // Actualiza URL en el historial y la barra de navegación
        /* eslint-disable eqeqeq */
        // noinspection EqualityComparisonWithCoercionJS
        if (
          this.$route.params.tramite != this.tramite ||
          (this.$route.params.institucion ?? '') != this.form.institucion_id ||
          (this.$route.params.jpl ?? '') != this.form.jpl ||
          this.$route.params.search != search
        ) {
          const route = {
            name: this.mostrarFormJpl
              ? 'buscar-en-jpl'
              : this.mostrarFormInstitucion
              ? 'buscar-en-institucion'
              : 'buscar',
            params: {
              search,
              tramite: this.tramite,
              institucion: (this.form.institucion_id ?? '').toString(),
              jpl: (this.form.jpl ?? '').toString(),
            },
          }
          const push = this.$router.push(route)
          if (!this.$nuxt && push) {
            push
              .then(() => {
                retornar = true
              })
              .catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                  throw error
                }
              })
          }

          /*
           * Debido al cambio de route, la búsqueda se va a volver a ejecutar,
           * por lo que aquí retornamos para abortar esta búsqueda
           */
          if (retornar) {
            return
          }
        }
      }

      // Query string
      const searchParams = new URLSearchParams()
      searchParams.set('search', search)
      if (this.mostrarFormJpl) {
        searchParams.set('jpl', this.form.jpl)
      }
      if (this.mostrarFormInstitucion) {
        searchParams.set('institucion_id', this.form.institucion_id)
      }

      // Ejecuto la búsqueda
      this.error = ''
      this.errorDetalle = ''
      this.loading = true
      // En caso de que la búsqueda tarde un poco, muestra un mensaje de cargando
      this.loadingDemorando = false

      if (this.paso === 1) {
        setTimeout(() => {
          this.loadingDemorando = true
        }, 2000)
      }

      this.cuentas = []
      this.$axios
        .get(`tramites/${this.tramite}/buscar?${searchParams}`)
        .then((response) => {
          if (typeof response.data === 'object') {
            this.cuentas = response.data.data || []
          } else {
            this.cuentas = []
          }
          this.habilitacionBusqueda = response.data.habilitacion || {}
          this.afterBuscar()
        })
        .catch((e) => {
          this.error = 'No se encontraron resultados.'
          this.cuentas = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    afterBuscar() {
      if (this.cuentas.length === 0) {
        this.error = 'No se encontraron resultados.'
        this.errorDetalle = this.tramiteStatic.textoSinResultados(this)

        if (!this.habilitacionBusqueda.habilitada) {
          const nombreMunicipalidad = this.$payloadStatic.municipalidad(this.institucion).nombre

          let nombre = ''
          let tipoInstitucion = ''

          if (this.mostrarFormInstitucion) {
            nombre = ''
            tipoInstitucion = 'la municipalidad'
          } else if (this.mostrarFormJpl) {
            nombre = ` en el ${this.form.jpl}º Juzgado de Policía Local de ${nombreMunicipalidad}`
            tipoInstitucion = 'el Juzgado de Policía Local'
          } else {
            nombre = ' en la municipalidad de ' + nombreMunicipalidad
            tipoInstitucion = 'la municipalidad'
          }
          this.errorDetalle = `El pago solicitado no se encuentra disponible${nombre}, si tienes dudas, comunícate con ${tipoInstitucion} o puedes contactar a la mesa de ayuda.`
        }
      }

      this.afterBuscarSeleccionarCuentasPagoObligatorio()

      this.afterBuscarActualizaInstitucionDeBusqueda()

      this.afterBuscarAplicaAjustesBeneficioZonaFranca()
    },

    checkout(paso) {
      this.paso = paso
      this.loading = true
      this.error = ''
      this.errorDetalle = ''

      // const url = `/tramites/${this.tramite}/checkout/` + Math.random().toString(36).substring(2, 15)
      // this.$router.push(url)

      this.$axios
        .post(`tramites/${this.tramite}/checkout`, {
          pk: this.cuentasAPagar.map((c) => c.pk),
          institucion_id: this.form.institucion_id,
        })
        .then((response) => {
          const cuentas = response.data.data || response.data || []
          // Selecciona las cuentas, excepto las que sean "no_pagable"
          this.cuentas = cuentas.map((c) => ({
            ...c,
            _seleccionado: this.cuentaPagable(c),
          }))
          this.afterCheckout()
        })
        .catch((e) => {
          this.paso = 1
          this.error = 'No se encontraron resultados.'
          this.cuentas = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    afterCheckout() {
      if (this.cuentas.length === 0) {
        this.paso = 1
        this.error = 'No se encontraron resultados.'
      }

      this.afterBuscarAplicaAjustesBeneficioZonaFranca()
    },

    pagar() {
      const cuentas = this.cuentasAPagar.map((c) => c.id)

      if (!cuentas.length) {
        alert('Debes selecionar al menos una cuenta para pagar')
        return
      }

      if (!this.$refs.formContacto.validate()) {
        alert('Para continuar con el pago, debes ingresar tus datos.')
        return
      }

      if (!this.horarioHabil()) {
        alert('El servicio de pago no está disponible entre las 23:50 y 00:10 hrs.')
        return
      }

      this.error = ''
      this.errorDetalle = ''
      this.formErrors = {}

      this.cargandoTgr = true

      const endpoint = this.modo === 'admin' ? 'pagar-presencial' : 'pagar'
      this.$axios
        .post(
          `tramites/${this.tramite}/${endpoint}`,
          {
            // Cuentas a pagar
            cuentas,
            // Formulario de contacto
            ...this.contacto,
            // Opciones de permisos de circulación
            cuotas: this.cuotas,
            beneficioZonaFranca: this.usaBeneficioZonaFranca,
          },
          {
            timeout: 30 * 1000,
          }
        )
        .then((response) => {
          this.$emit('pago-iniciado', response.data ?? {})

          this.cargandoTgr = false
          if (this.modo === 'portal') {
            const url = response.data.url
            if (!url) {
              this.dialogError = 'No se pudo iniciar el pago'
              this.error =
                'Tuvimos un problema de conexión con la Tesorería General de la República. Reintente en un momento.'
              return
            }
            document.location = url
          }
        })
        .catch((error) => {
          this.dialogError = 'No se pudo iniciar el pago'

          const formErrors = getError(error)
          console.log(formErrors)

          if (typeof formErrors === 'object') {
            this.formErrors = formErrors
            if (formErrors._message === 'Los datos ingresados son inválidos.') {
              if (formErrors.cuentas) {
                this.error = formErrors.cuentas.join(',')
              } else {
                this.error = 'Corrige los datos ingresados y reintenta.'
              }
            } else {
              this.error = 'Tuvimos un problema al iniciar el pago.'
            }
          } else {
            this.error =
              'Tuvimos un problema al iniciar el pago con la Tesorería General de la República.'
          }
          this.cargandoTgr = false
        })
    },

    /**
     * Actualiza la institución actual por la del resultado de búsqueda
     */
    afterBuscarActualizaInstitucionDeBusqueda() {
      if (this.cuentas.length > 0) {
        const habilitacion = this.cuentas[0].habilitacion
        if (this.modo === 'portal' && !this.$nuxt) {
          this.$store.dispatch('app/setInstitucion', {
            nombre: habilitacion?.institucion ?? '',
            rut: habilitacion?.institucion_id ?? 0,
            unidad: habilitacion?.nombre ?? '',
            direccion: habilitacion?.direccion ?? '',
            telefono: habilitacion?.telefono ?? '',
          })
        }
      }
    },

    /**
     * Marca como seleccionadas las cuentas de pago obligatorio
     */
    afterBuscarSeleccionarCuentasPagoObligatorio() {
      if (this.cuentas.length > 0) {
        // cuenta.pago_obligatorio
        this.cuentas = this.cuentas.map((cuenta) => ({
          ...cuenta,
          _seleccionado: !!cuenta.pago_obligatorio,
        }))
      }
    },

    /**
     * Verifica se el contribuyente puede optar al beneficio tributario
     * de zona extrema y inicializa los datos correspondientes
     */
    afterBuscarAplicaAjustesBeneficioZonaFranca() {
      this.declaracionJuradaBeneficioZonaFranca = false

      if (!this.cuentas.find((c) => c.montos_zona_franca?.monto)) {
        this.tieneBeneficioZonaFranca = false
        this.usaBeneficioZonaFranca = false
        return
      }

      this.cuentas.map((cuenta) => {
        cuenta._montos_original = { ...cuenta.montos }
      })
      this.tieneBeneficioZonaFranca = true
      this.actualizarMontosCuentasBeneficioZonaFranca()
    },

    /**
     * Modifica los montos de las cuentas acogibles a beneficio de zona franca
     */
    actualizarMontosCuentasBeneficioZonaFranca() {
      if (!this.tieneBeneficioZonaFranca) {
        return
      }
      this.cuentas
        .filter((c) => c.montos_zona_franca?.monto)
        .forEach((cuenta) => {
          cuenta.montos = this.usaBeneficioZonaFranca
            ? cuenta.montos_zona_franca
            : cuenta._montos_original
        })
    },
  },
}
</script>

<style>
.input-buscador input {
  font-size: 1.25rem;
  text-transform: uppercase;
}
</style>
