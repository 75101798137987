<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Bitácora',
          icon: 'mdi-file-search-outline',
          click: dialogBitacora,
          posicion: 'derecha',
        },
      ]"
      :campo-titulo-dialog="(v) => $payload.tipoTramiteOirs(v.tramite_id)"
      :campo-subtitulo-dialog="(v) => '#' + v.id"
      :headers="headers"
      :query="query"
      url="/documentacion/oirs"
      search-url="/documentacion/oirs/search"
      search-method="post"
      campo-titulo-dialog-crear="Crear ticket"
      search-inicial
      max-width="900"
      disable-view
      disable-edit
      disable-delete
      texto-hits="%s tickets."
      texto-no-hit="No se encontraron tickets."
      texto-un-hit="Un ticket."
      @created="$emit('notificar', 'Ticket de atención ciudadano creado.')"
      @deleted="$emit('notificar', 'Ticket de atención ciudadano eliminado.')"
      @updated="$emit('notificar', 'Ticket de atención ciudadano guardado.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading: loadingSearch, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="5" md="3">
              <z-input
                v-model="query.filter.tipo"
                :items="payloadItemsTipoOirs"
                tipo="select"
                label="Tipo"
                clearable
              />
            </v-col>

            <v-col cols="12" sm="7" md="6">
              <z-input
                v-model="query.filter.categoria"
                :items="$itemsCategoriasOirs"
                tipo="select"
                label="Categoría"
                clearable
              />
            </v-col>

            <v-col cols="12" sm="5" md="3">
              <z-input
                v-model="query.filter.estado"
                :items="payloadItemsEstadoOirs"
                tipo="select"
                label="Estado"
                clearable
              />
            </v-col>

            <v-col cols="12" sm="7" md="6">
              <z-input
                v-model="query.filter.tramite_id"
                :items="$itemsTramitesOirs"
                tipo="autocomplete"
                label="Tramite"
                clearable
              />
            </v-col>

            <v-col class="text-right">
              <z-btn :loading="loadingSearch" color="primary" outlined type="submit" class="mx-1">
                Actualizar
              </z-btn>
              <z-btn color="primary" outlined to="/tickets/crear"> Crear </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.estado="{ value }">
        <z-chip :color="value === 10 ? 'grey' : value === 5 ? 'warning' : 'info'" small outlined>
          {{ $payload.estadoOirs(value) }}
        </z-chip>
      </template>

      <template #item.tramite_id="{ item }">
        {{ $payload.categoriaOirs(item.categoria) }}
        <div class="text-caption text--secondary">
          {{ $payload.tipoTramiteOirs(item.tramite_id) }}
        </div>
      </template>
    </z-mantenedor>

    <v-dialog v-model="dialogBitacoraVisible" persistent width="700">
      <v-card class="pr-8">
        <dialog-toolbar @close="dialogBitacoraVisible = false">
          {{ $payload.categoriaOirs(itemActivo.categoria) }}
          <span v-if="itemActivo.tramite_id" class="text--secondary text-caption">
            &nbsp; {{ $payload.tipoTramiteOirs(itemActivo.tramite_id) }}
          </span>
        </dialog-toolbar>

        <v-card-text>
          <v-card v-if="!!itemActivo.mensaje_a_mesa_de_ayuda" class="mx-auto" max-width="420">
            <v-card-title> Resumen </v-card-title>
            <v-card-text style="white-space: pre-line">
              {{ itemActivo.mensaje_a_mesa_de_ayuda }}
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-timeline dense>
            <v-slide-x-reverse-transition group hide-on-leave>
              <v-timeline-item key="contenido" color="success" small fill-dot>
                <v-card>
                  <v-card-title> {{ itemActivo.nombres }} {{ itemActivo.apellidos }} </v-card-title>
                  <v-card-text class="mt-n6">
                    <v-row dense>
                      <v-col>
                        {{ itemActivo.email }}
                      </v-col>
                      <v-col>
                        {{ itemActivo.telefono }}
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col> Copia a: {{ itemActivo.email_cc }} </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text style="white-space: pre-line">
                    {{ itemActivo.contenido }}
                  </v-card-text>

                  <v-card-text class="text-caption">
                    {{ $formato.fechaHora(itemActivo.created_at) }}
                  </v-card-text>
                </v-card>
              </v-timeline-item>

              <v-timeline-item
                v-for="bitacora in itemActivo.bitacoras"
                :key="bitacora.id"
                color="info"
                small
                fill-dot
              >
                <v-card>
                  <v-card-title>
                    {{ bitacora.user_id === $auth.user().id ? 'Yo' : 'Mesa de ayuda' }}
                  </v-card-title>
                  <v-card-text style="white-space: pre-line">
                    {{ bitacora.contenido }}
                  </v-card-text>
                  <v-card-text class="text-caption">
                    {{ $formato.fechaHora(bitacora.updated_at) }}
                  </v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item
                v-if="itemActivo.estado !== 10"
                key="agregar"
                color="info"
                small
                fill-dot
              >
                <v-card>
                  <v-card-title> Agregar mensaje </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <z-input
                          v-model.trim="form.contenido"
                          tipo="textarea"
                          label="Agregar nuevo mensaje"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-space-between">
                        <v-spacer />
                        <z-btn color="primary" :disabled="!form.contenido" @click="agregarMensaje">
                          Guardar
                        </z-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-slide-x-reverse-transition>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import ZChip from '../../../components/ZChip'
import DialogToolbar from '@/components/DialogToolbar'

export default {
  components: { DialogToolbar, ZChip, ZInput, ZMantenedor, ZBtn },
  data() {
    return {
      loading: false,
      items: [],
      itemActivo: {},
      formDerivar: {},
      form: {
        contenido: '',
      },
      dialogBitacoraVisible: false,
      query: {
        include: 'bitacoras',
        paginationDisabled: true,
        filter: {
          estado: null,
          categoria: null,
          institucion_id_solicitante: this.$auth.user().institucion_id,
          // institucion_id: null,
        },
        sort: {
          0: { field: 'updated_at', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Categoría',
          value: 'tramite_id',
        },
        {
          text: 'Creación',
          value: 'created_at',
          formatter: (v) => this.$formato.fecha(v),
        },
        {
          text: 'Última edición',
          value: 'updated_at',
          formatter: (v) => this.$formato.fechaHora(v),
          visible: this.$vuetify.breakpoint.mdAndUp,
        },
        {
          text: 'Estado',
          value: 'estado',
        },
      ]
    },
  },
  methods: {
    dialogBitacora(item) {
      this.itemActivo = item
      this.dialogBitacoraVisible = true
      this.form = {}
    },
    agregarMensaje() {
      this.loading = true
      this.dialogBitacoraVisible = false
      this.$axios
        .post(`documentacion/oirs/${this.itemActivo.id}/bitacoras`, this.form)
        .then((response) => {
          const bitacora = response.data.data
          this.itemActivo.bitacoras ??= []
          this.itemActivo.bitacoras.push(bitacora)
          this.itemActivo.updated_at = bitacora.updated_at
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
