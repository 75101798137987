<template>
  <div>
    <h2 class="text-h3 mx-auto" style="max-width: 500px">
      {{ $auth.institucion().nombre }}
    </h2>

    <v-card class="mx-auto my-4" max-width="500">
      <v-card-text>
        <input-tramite v-model="slugTramite" />
      </v-card-text>
    </v-card>

    <z-form-editar
      v-if="tramite_id"
      :id="$auth.institucion().id + tramite_id.toString().padStart(3, '0')"
      v-model="habilitacion"
      :before-save="beforeSave"
      :after-save="afterSave"
      :after-read="afterRead"
      :url="`tramites/${slugTramite}/habilitaciones/{id}/secuencia`"
      @readed="valorMinimo = Math.max(1, $event.secuencia || 1)"
      @updated="notificar('Número de serie actualizado a ' + $event.secuencia)"
      @update:error="notificarErrorResponse"
    >
      <template #default="{ errors, loading, save }">
        <template v-if="habilitacion.id">
          <v-form ref="form" :readonly="loading" @submit.prevent="save">
            <v-card :loading="loading" class="mx-auto my-4" max-width="500">
              <v-card-title> Configurar número de serie </v-card-title>

              <v-card-text>
                <p>
                  El número de serie aquí indicado será el utilizado para foliar cada vez que se
                  pague un documento electrónico (en caso que no haya especificado dicho valor en la
                  carga de la nómina).
                </p>
                <p>
                  Debes escoger un número desde 1 en adelante. El siguiente documento emitido
                  utilizará el número de serie actual.
                </p>
                <p>
                  Posteriormente podrá actualizar este valor, pero siempre por uno mayor, nunca
                  menor.
                </p>
                <v-row>
                  <v-col>
                    <z-alert v-if="checkBoxtramiteSlugASeguirDisabled" type="warning">
                      {{ msjCheckboxInhabilitado }}
                    </z-alert>
                    <v-checkbox
                      v-model="checkBoxtramiteSlugASeguir"
                      label=" Quiero seguir un número de serie"
                      :disabled="checkBoxtramiteSlugASeguirDisabled"
                      @change="validarCheckBoxTramiteASeguir()"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="9">
                    <z-input
                      v-model.trim="form.secuencia"
                      :dense="false"
                      :hint="'Valor mínimo: ' + valorMinimo"
                      persistent-hint
                      :rules="[
                        (v) => String(v).length <= 15 || `Valor demasiado grande`,
                        (v) => v >= valorMinimo || `Debe ser mayor o igual a ${valorMinimo}`,
                      ]"
                      :error-messages="errors.secuencia"
                      label="Número de serie para el siguiente documento emitido"
                      type="number"
                      minlength="1"
                      maxlength="15"
                      :disabled="checkBoxtramiteSlugASeguir"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <input-tramite
                      v-show="checkBoxtramiteSlugASeguir"
                      v-model="tramiteSlugASeguir"
                      :hint="'Valor actual : ' + valorActual"
                      persistent-hint
                      :filter="(t) => ![slugTramite].includes(t.slug)"
                      @change="buscarSecuencia($auth.institucion().id)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <z-alert
                      v-if="btnGuardarDisabled && checkBoxtramiteSlugASeguir && msjTramiteASeguir"
                      type="error"
                    >
                      {{ msjTramiteASeguir }}
                    </z-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mx-auto my-4" max-width="500">
              <v-card-text>
                Antes de guardar, asegurate que la nueva secuencia sea correcta. Su valor no podrá
                ser disminuido.
              </v-card-text>
              <v-card-actions>
                <btn-guardar-cancelar
                  :disabled-guardar="
                    habilitacion.secuencia < valorMinimo ||
                    String(habilitacion.secuencia).length > 15 ||
                    habilitacion.secuencia > 999999999999999 ||
                    btnGuardarDisabled
                  "
                  :loading="loading"
                  type="submit"
                  @cancelar="$router.push('/dashboard')"
                />
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </template>
    </z-form-editar>

    <z-alert
      v-if="tramite_id && JSON.stringify(habilitacion) === '{}'"
      width="500"
      ma
      class="mx-auto my-8"
    >
      Trámite no disponible
    </z-alert>
  </div>
</template>
<script>
import { notificar, notificarErrorResponse } from '@/admin/utils/notificar'
import ZInput from '@/components/Input/ZInput'
import ZFormEditar from '@/components/Input/ZFormEditar'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import InputTramite from '@/components/Input/InputTramite'
import ZAlert from '@/components/ZAlert'

export default {
  components: { ZAlert, InputTramite, BtnGuardarCancelar, ZFormEditar, ZInput },
  data: () => ({
    dialog: false,
    valorMinimo: '',
    habilitacion: {},
    slugTramite: '', // this.$store?.getters['app/tramite'] || '',
    checkBoxtramiteSlugASeguir: false,
    checkBoxtramiteSlugASeguirDisabled: false,
    tramiteSlugASeguir: '',
    btnGuardarDisabled: false,
    habilitacionId: '',
    secuenciaHabilitacionPadreTramiteSlug: '',
    form: {},
    valorActual: 0,
    msjCheckboxInhabilitado: '', 
  }),
  computed: {
    tramite_id() {
      return this.$payload.tramite(this.slugTramite, 'id')
    },
  },
  beforeCreate() {
    this.slugTramite = this.$store?.getters['app/tramite'] || ''
  },
  methods: {
    buscarSecuencia(institucionId) {
      this.btnGuardarDisabled = false

      if (this.checkBoxtramiteSlugASeguir) {
        const tramiteIdASeguir = this.$payload.tramite(this.tramiteSlugASeguir, 'id')
        this.habilitacionId = institucionId * 1000 + tramiteIdASeguir
        this.ejecutarBusqueda(this.habilitacionId)
      }
    },
    validarCheckBoxTramiteASeguir() {
      console.log(this.checkBoxtramiteSlugASeguir)
      this.btnGuardarDisabled = false

      if (this.checkBoxtramiteSlugASeguir) {
        this.ejecutarBusqueda()
      }
    },
    ejecutarBusqueda(habilitacionId) {
      this.$axios
        .get(`tramites/${this.tramiteSlugASeguir}/habilitaciones/${habilitacionId}`, {})
        .then((response) => {
          const data = response.data.data
          const secuenciaHabilitacionPadreId = data.secuencia_habilitacion_padre_id
          this.secuenciaHabilitacionPadreTramiteSlug = data.slug

          if (secuenciaHabilitacionPadreId != null) {
            this.btnGuardarDisabled = true
            this.msjTramiteASeguir = 'El trámite a seguir ya sigue a otro. '
            this.valorActual = 0
          }

          this.valorActual = data.secuencia
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.btnGuardarDisabled = true
          }

          if (error.response.status === 404) {
            this.btnGuardarDisabled = true
            this.msjTramiteASeguir = 'Trámite no disponible.'
            this.valorActual = 0
          }
        })
        .finally(() => {})
    },
    afterSave(habilitacion) {
      this.$router.push('/dashboard')
    },
    beforeSave(habilitacion) {
      if (this.checkBoxtramiteSlugASeguir) {
        habilitacion.secuencia_habilitacion_padre_id = this.habilitacionId

        habilitacion.secuencia_habilitacion_padre_tramite_slug =
          this.secuenciaHabilitacionPadreTramiteSlug
      } else {
        habilitacion.secuencia = this.form.secuencia
        habilitacion.secuencia_habilitacion_padre_id = null
        habilitacion.secuencia_habilitacion_padre_tramite_slug = ''
      }
    },
    afterRead(habilitacion) {
      this.tramiteSlugASeguir = ''
      this.valorActual = 0
      this.checkBoxtramiteSlugASeguir = false
      this.form.secuencia = habilitacion.secuencia

      // Si la habilitacion tiene seguidores se desactiva el checkbox 'Quiero seguir el número de serie'
      if (habilitacion.cantidad_de_seguidores > 0) {
        this.checkBoxtramiteSlugASeguirDisabled = true

        const tramite = habilitacion.cantidad_de_seguidores > 1 ? 'tramites' : 'tramite'
        const finFrase =
          habilitacion.cantidad_de_seguidores > 1 ? 'que lo siguen.' : 'que lo sigue.'

        this.msjCheckboxInhabilitado =
          'Inhabilitado porque tiene ' +
          habilitacion.cantidad_de_seguidores +
          ' ' +
          tramite +
          ' ' +
          finFrase
        return
      }

      this.checkBoxtramiteSlugASeguirDisabled = false

      // Si tengo un padre realizo:
      // - checked checkBoxtramiteSlugASeguir
      // - busco la info de la secuencia padre
      if (habilitacion.secuencia_habilitacion_padre_id) {
        this.checkBoxtramiteSlugASeguir = true
        const largo = habilitacion.secuencia_habilitacion_padre_id.toString().length

        this.tramiteSlugASeguir = this.$payload.tramite(
          habilitacion.secuencia_habilitacion_padre_id.toString().substr(largo - 2, largo) * 1,
          'slug',
          'id'
        )

        this.buscarSecuencia(habilitacion.institucion_id)
      }

      // Btn guardar estara disponible para guardar
      // this.btnGuardarDisabled = false
    },
    notificarErrorResponse(event) {
      notificarErrorResponse(event)
    },
    notificar(event) {
      notificar(event)
    },
  },
}
</script>
