<template>
  <div>
    <!--
            Formulario de contacto con datos adicionales para beneficio zona franca
    -->

    <v-row>
      <v-col class="text-h6"> Datos para declaración jurada beneficio de zona extrema </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model.trim="contacto.domicilio"
          outlined
          label="Domicilio"
          name="domicilio"
          data-testid="domicilio"
          hide-details
          maxlength="100"
          required
          :error-messages="errors.domicilio"
          :rules="[
            (v) => !!v || 'Debes ingresar tu domicilio',
            (v) => (v && v.length >= 5) || 'El domicilio debe ser más largo',
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.trim="contacto.ciudad"
          outlined
          label="Ciudad"
          name="ciudad"
          data-testid="ciudad"
          hide-details
          maxlength="50"
          required
          :error-messages="errors.ciudad"
          :rules="[
            (v) => !!v || 'Debes ingresar tu ciudad',
            (v) => (v && v.length >= 3) || 'La ciudad debe ser más larga',
          ]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    tramiteStatic: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    contacto: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {},
}
</script>
