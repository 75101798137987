<template>
  <div>
    <v-sheet max-width="800" class="mx-auto my-4">
      <div v-if="loading">Cargando...</div>

      <v-expansion-panels v-model="grupo_index" flat>
        <v-expansion-panel v-for="grupo in grupos" :key="'grupo-' + grupo.id">
          <v-expansion-panel-header
            class="text-slab"
            :class="grupo.id === grupoActivo.id ? 'text-h4' : 'text-h5'"
          >
            {{ grupo.nombre }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-4">
            <v-expansion-panels>
              <v-expansion-panel v-for="tema in grupo.temasPublicados" :key="'tema-' + tema.id">
                <v-expansion-panel-header class="text-h6 text-slab">
                  {{ tema.titulo }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <div class="text--secondary mb-2">
                    {{ tema.subtitulo }}
                  </div>
                  <div class="mt-8 mb-8 documentacion-contenido" v-html="tema.contenido" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>

    <z-alert class="mx-auto mt-12 mb-4" max-width="600">
      <p>Si no pudiste solucionar tu problema, puedes solicitar un:</p>

      <z-btn to="/tickets/crear" outlined color="primary" small> ticket de asistencia </z-btn>
    </z-alert>
  </div>
</template>

<script>
import ZAlert from '@/components/ZAlert'
import ZBtn from '@/components/ZBtn'
export default {
  name: 'AyudaPage',
  components: { ZBtn, ZAlert },
  auth: false,
  data() {
    return {
      loading: false,
      grupos: [],
      grupo_index: null,
    }
  },
  computed: {
    grupoActivo() {
      return this.grupos[this.grupo_index] ?? {}
    },
  },
  watch: {
    grupo_index(value) {
      // this.$router.replace({ name: 'ayuda', params: { grupoId: value } })
    },
  },
  beforeMount() {
    this.loading = true
    this.$axios
      .post('documentacion/grupos/search', {
        html: true,
        include: 'temasPublicados',
        filter: {
          seccion: 1,
          publicado: true,
          'temas.publicado': true,
        },
        sort: [{ field: 'prioridad', direction: 'asc' }],
      })
      .then((response) => {
        this.grupos = response.data.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {},
}
</script>

<style>
.documentacion-contenido img {
  max-width: 100%;
}
</style>
