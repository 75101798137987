<template>
  <tiptap-vuetify
    v-model="content"
    class="documentacion-editor"
    placeholder="Escribe algo…"
    :extensions="extensions"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Image,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [2, 3, 4],
          },
        },
      ],
      Bold,
      // Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
    // starting editor's content
    // content: `
    // <h1>Yay Headlines!</h1>
    // <p>All these <strong>cool tags</strong> are working now.</p>
    // `,
  }),
  computed: {
    content: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style>
.documentacion-editor img {
  max-width: 100%;
}
</style>
