<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="6" md="5">
              <v-select
                v-model="form.proveedor"
                :items="payloadItemsProveedoresFirma"
                :error-messages="errors.firmante"
                clearable
                dense
                label="Proveedor de firma"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" sm="5" md="3">
              <z-input
                v-model="form.codigo"
                :error-messages="errors.codigo"
                label="Código"
                minlength="3"
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined @click="validarFirmante">
                Actualizar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div v-if="!loading">
      <v-alert v-if="firmante.validado" type="success" class="mx-auto my-4" max-width="600">
        El código de firma es correcto.
      </v-alert>

      <v-alert v-if="!firmante.validado && error" class="mx-auto my-4" max-width="600" type="error">
        {{ error }}
      </v-alert>
      <v-card v-if="firmante.validado" max-width="600" class="mx-auto">
        <v-simple-table>
          <tbody>
            <tr>
              <th>Nombre</th>
              <td>{{ firmante.nombre }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{{ firmante.email }}</td>
            </tr>
            <tr>
              <th>Identificador</th>
              <td>
                {{ firmante.rut }}
              </td>
            </tr>
            <tr>
              <th>Valido desde</th>
              <td>
                {{ $formato.fecha(firmante.desde, 'D/M/YYYY HH:mm:ss') }}
              </td>
            </tr>
            <tr>
              <th>Valido hasta</th>
              <td>
                {{ $formato.fecha(firmante.hasta, 'D/M/YYYY HH:mm:ss') }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZInput from '@/components/Input/ZInput'
import { getError } from '@/utils/errors'

export default {
  components: { ZInput, ZBtn },
  data() {
    return {
      items: [],
      headerOptions: {},
      errors: {},
      form: {
        proveedor: '',
        codigo: '',
      },
      firmante: {
        validado: false,
        nombre: '',
        email: '',
        desde: '',
        hasta: '',
      },
      error: '',
      loading: false,
    }
  },
  methods: {
    resetValidacion() {
      if (this.form.nombre !== '') {
        this.form.nombre = null
      }
      if (this.form.institucion_id !== '') {
        this.form.institucion_id = null
      }

      this.disabled = true
      this.errors = {}
    },
    validarFirmante() {
      this.errors = {}
      const data = {
        firmante: this.form.proveedor,
        codigo: this.form.codigo,
      }

      this.loading = true

      this.$axios
        .post(`tramitesgenerales/firmantes/validar`, data)
        .then((response) => {
          this.loading = false
          const r = response.data
          this.error = r.error
          this.firmante.validado = r.validado

          if (!this.firmante.validado) return

          const certificado = r.certificado
          this.firmante.nombre = certificado.CN
          this.firmante.email = certificado.emailAddress
          this.firmante.rut = certificado.OU
          this.firmante.desde = certificado.validFrom
          this.firmante.hasta = certificado.validTo
        })
        .catch((error) => {
          this.loading = false
          this.errors = getError(error)
        })
    },
  },
}
</script>
