<template>
  <z-dialog
    :campo-titulo="() => titulo"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="{}"
    boton-principal="Iniciar pago masivo"
    width="700"
    @boton-principal="pagoMasivoNominas(id)"
    @cerrar="$emit('cerrar')"
  >
    <div>
      <v-row>
        <v-col>
          <z-input v-model="form.nombre" label="Nombre" :error-messages="errors.nombre" required />
        </v-col>
        <v-col>
          <z-input v-model="form.rut" label="RUT" :error-messages="errors.rut" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <z-input
            v-model="form.email"
            label="Correo Electronico"
            :error-messages="errors.email"
            required
          />
        </v-col>
        <v-col>
          <z-input
            v-model="form.folio"
            :error-messages="errors.folio"
            label="Número de ingreso o folio"
            name="folio"
            :rules="[]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-if="slug != 'permisos-de-circulacion-2c'"
            v-model="form.cuotas"
            :error-messages="errors.cuotas"
            :items="[
              { text: 'Pagar patente completa', value: false },
              { text: 'Pagar primera cuota', value: true },
            ]"
            dense
            label=""
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-file-input
          v-model="form.file"
          :disabled="loading"
          :error-messages="errors.pks"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,application/vnd.oasis.opendocument.spreadsheet"
          prepend-icon="mdi-paperclip"
          class="mt-4"
          label="Archivo Excel con cuentas a pagar"
          outlined
          show-size
          hint="No debe contener más de 20000 elementos."
          persistent-hint
          @change="analizarFile"
        />
      </v-row>
      <v-row v-if="form.beneficioZonaFranca === true && slug === 'permisos-de-circulacion'">
        <v-card-subtitle> Datos de beneficio tributario </v-card-subtitle>
        <v-card-text>
          <v-card>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>
                      <z-input
                        v-model="form.domicilio"
                        label="Domicilio"
                        :error-messages="errors.domicilio"
                        required
                        style="margin-top: 15px"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <z-input
                        v-model="form.ciudad"
                        label="Ciudad"
                        :error-messages="errors.ciudad"
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-row>
    </div>
  </z-dialog>
</template>

<script>
import ZDialog from '@/components/ZDialog'
import ZInput from '@/components/Input/ZInput'
import { getError } from '@/utils/errors'
import { notificar } from '@/admin/utils/notificar'
import * as XLSX from 'xlsx'

/**
 * Ventana de diálogo que muestra el componente `historial-edicion`,
 * el cual muestra un timeline con el
 * historial de modificaciones del registro.
 */
export default {
  components: { ZDialog, ZInput },
  props: {
    /**
     * Título de la ventana
     */
    titulo: {
      type: String,
      default: 'Pago Masivo',
    },
    id: {
      type: [String, Number],
      required: true,
    },
    slug: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      file: null,
      pk: [],
      form: {
        nombre: '',
        folio: '',
        domicilio: '',
        ciudad: '',
        beneficioZonaFranca: false,
        cuotas: false,
      },
      errors: {},
      showDatosZonaFranca: false,
    }
  },
  methods: {
    pagoMasivoNominas(id) {
      this.loading = true
      this.errors = {}
      const url = `tramites/${this.slug}/nominas/${id}/masivos/pagar`

      const data = {
        nomina_id: id,
        nombre: this.form.nombre,
        email: this.form.email,
        rut: this.form.rut,
        folio: this.form.folio,
        beneficioZonaFranca: this.form.beneficioZonaFranca,
        cuotas: this.form.cuotas,
        pks: this.pk,
        ciudad: this.form.ciudad,
        domicilio: this.form.domicilio,
      }

      this.$axios
        .post(url, data)
        .then((response) => {
          this.loading = false
          this.nominasViewVisible = false
          notificar(
            'El pago masivo ha sido enviado. Una vez concluido el proceso recibirás una notificación y se habilitará el boton para descargar los archivos PDF.'
          )
          this.$emit('enviado')
        })
        .catch((error) => {
          this.errors = getError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    analizarFile(file) {
      this.loading = true
      this.showDatosZonaFranca = false
      this.hoja = null
      this.pk = []
      this.form.beneficioZonaFranca = false

      if (!file) {
        this.loading = false
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, {
          type: 'binary',
          cellHTML: false,
        })
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName]
          let data = XLSX.utils.sheet_to_json(worksheet, { raw: true, header: 1, defval: '' })
          // Quita líneas en blanco
          data = data.filter((r) => r.join('').trim() !== '')
          const filas = data.length

          if (filas <= 1) {
            this.errors.file = 'No hay filas con datos'
          }

          // Se verifica si tiene beneficio de zona franca solo para permisos-de-circulacion
          data.slice(1).forEach((element) => {
            if (element[4] === 'si' && this.slug === 'permisos-de-circulacion') {
              this.form.beneficioZonaFranca = true
              return true
            }
          })

          data.slice(1).forEach((element) => {
            this.pk.push(element[1])
          })

          console.log(this.pk)
        })
        this.loading = false
      }
      reader.readAsBinaryString(file)
    },
  },
}
</script>
