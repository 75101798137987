<template>
  <div>
    <v-row>
      <template v-for="[campo, columna] in columnasDefinicionTramite">
        <v-col v-if="campoEditable(columna)" :key="campo" cols="12" md="6">
          <z-input
            v-if="visible(columna)"
            v-model="form.registro[campo]"
            :tipo="tipo(campo)"
            :type="type(campo)"
            :error-messages="errorMessage(campo)"
            :rules="rules(campo)"
            :items="items(campo)"
            :label="columna.nombre"
            :hint="columna.descripcion"
            persistent-hint
          />
        </v-col>
      </template>
    </v-row>
    <v-alert
      v-if="errors.registro || errors['registro.unicidad'] || errors['registro.registro']"
      outlined
      class="mt-4"
      type="error"
    >
      <template v-if="errors.registro">
        {{ errors.registro.join(', ') }}
      </template>
      <template v-if="errors['registro.unicidad']">
        {{ errors['registro.unicidad'].join(', ') }}
      </template>
      <template v-if="errors['registro.registro']">
        {{ errors['registro.registro'].join(', ') }}
      </template>
    </v-alert>
    <v-alert
      v-else-if="alertOnError && Object.keys(errors).length"
      outlined
      class="mt-4"
      type="error"
    >
      {{
        alertOnError === true
          ? errors._message || 'Hay errores que deberás corregir.'
          : alertOnError
      }}
    </v-alert>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import { mapGetters } from 'vuex'

export default {
  components: { ZInput },
  props: {
    accion: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    alertOnError: {
      type: [Boolean, String],
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', ['payload']),
    roles() {
      return Object.entries(this.payloadListaRoles).map((v) => ({ name: v[1], id: parseInt(v[0]) }))
    },
    definicionTramite() {
      return this.$payload.tramite(this.form.tramite_id, 'definicion', 'id') || {}
    },
    columnasDefinicionTramite() {
      return Object.entries(this.definicionTramite.columnas ?? []).sort(
        (a, b) => a[1].prioridadEdicion - b[1].prioridadEdicion
      )
    },
    form: {
      get: function () {
        let value = this.value
        if (!value.registro) {
          value = { ...value, registro: {} }
        }
        return value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapGetters('auth', ['can']),
    tipo(campo) {
      return (
        {
          'Sem\\Tramites\\Tramites\\TiposCampo\\Rut': 'text',
          'Sem\\Tramites\\Tramites\\TiposCampo\\Porcentaje': 'select',
        }[this.definicionTramite.columnas[campo]?.tipo] ?? ''
      )
    },
    type(campo) {
      return (
        {
          'Sem\\Tramites\\Tramites\\TiposCampo\\Fecha': 'date',
          'Sem\\Tramites\\Tramites\\TiposCampo\\FechaHora': 'datetime-local',
          'Sem\\Tramites\\Tramites\\TiposCampo\\FechaPago': 'datetime-local',
        }[this.definicionTramite.columnas[campo]?.tipo] ?? ''
      )
    },
    items(campo) {
      const tipo = this.definicionTramite.columnas[campo]?.tipo

      if (tipo === 'Sem\\Tramites\\Tramites\\TiposCampo\\Porcentaje') {
        return [
          { value: 0, text: '0%' },
          { value: 0.25, text: '25%' },
        ]
      }

      if (campo === 'clasificacion') {
        return [
          { value: 'Leve', text: 'Leve' },
          { value: 'Menos grave', text: 'Menos grave' },
          { value: 'Grave', text: 'Grave' },
          { value: 'Gravisima', text: 'Gravísima' },
        ]
      }

      return []
    },

    visible(columna) {
      if (columna.visible === false) {
        return false
      } else {
        return true
      }
    },

    campoEditable(columna) {
      const form = this.form

      if (!form.estado) {
        return true
      }
      if (form.estado < 25 && columna.editable) {
        return true
      }

      return form.estado >= 25 && form.estado <= 27 && columna.editablePagado
    },

    rules(campo) {
      const rules = this.definicionTramite.columnas[campo].rules || []
      const r = []
      if (rules.includes('required')) {
        r.push((v) => !!v || 'Obligatorio')
      }
      return r
    },
    errorMessage(campo) {
      return this.errors[`registro.${campo}`] ?? ''
    },
  },
}
</script>
