<template>
  <div>
    <z-mantenedor
      v-model="items"
      :botones-listar="[
        { title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial, posicion: 'menu' },
        {
          title: 'Descargar PDF',
          icon: 'mdi-file-download-outline',
          click: $util.descargarPdf,
          visible: (c) => c.estado >= 25 && c.estado <= 26,
          disabled: (c) => !c.codigo_verificacion,
          posicion: 'izquierda',
        },
        {
          title: 'Descargar XML',
          icon: 'mdi-file-code-outline',
          click: $util.descargarXml,
          visible: (c) => c.estado >= 25 && c.estado <= 26,
          disabled: (c) => !c.codigo_verificacion,
          posicion: 'menu',
        },
        {
          title: 'Volver a firmar',
          icon: 'mdi-file-code-outline',
          click: (c) => volverAFirmar(c),
          visible: (c) => c.estado === 25 && $auth.can(['administrador']),
          posicion: 'menu',
        },
        {
          title: 'Búsqueda para pagar',
          click: (c) =>
            $router.push(`/pago-presencial/cuentas?search=${c.pk}&tramite=${slugTramite}`),
          visible: (c) => c.estado === 11 && $auth.can(['cajero']),
          posicion: 'menu',
        },
      ]"
      :botones-ver="[
        { title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial },
        {
          title: 'Descargar PDF',
          icon: 'mdi-file-download-outline',
          click: $util.descargarPdf,
          visible: (c) => c.estado >= 25 && c.estado <= 26,
        },
        {
          title: 'Descargar XML',
          icon: 'mdi-file-code-outline',
          click: $util.descargarXml,
          visible: (c) => c.estado >= 25 && c.estado <= 26,
          posicion: 'menu',
        },
      ]"
      :disable-edit="!$auth.can(['editar-cuentas'])"
      :disabled-edit="(cuenta) => cuenta.estado >= 27 && cuenta.estado <= 28"
      :disable-delete="!$auth.can(['editar-cuentas'])"
      :disabled-delete="(cuenta) => cuenta.estado >= 25 && cuenta.estado <= 28"
      :campo-titulo-dialog="(v) => v.pk_label"
      :campo-subtitulo-dialog="(v) => v.subtitulo"
      :search-inicial="$route.query.buscar === 'true'"
      :on-before-search="beforeSearch"
      :on-after-search="afterSearch"
      :on-before-dialog-view="beforeDialogView"
      :on-before-dialog-edit="beforeDialogEdit"
      :on-after-save="afterUpdate"
      max-width="800"
      :headers="headers"
      :query="query"
      :url="tramiteUrl"
      :search-url="tramiteUrl + '/search'"
      search-method="post"
      @deleted="$emit('notificar', 'La cuenta ha sido eliminada.')"
      @updated="$emit('notificar', 'La cuenta ha sido actualizada.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'La cuenta no puede ser eliminada.')"
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row v-if="query.filter.nomina_id" class="mt-2 ml-0 mb-4">
            <v-chip close color="primary" @click:close="closeChipNomina">
              {{ nomina.nombre || 'Nómina' }}
              <template v-if="nomina.institucion_id !== $auth.institucion().id">
                <span class="text-caption pl-1">
                  – {{ $payload.institucion(nomina.institucion_id) }}
                </span>
              </template>
            </v-chip>
          </v-row>
          <v-row dense>

            <v-col cols="12" sm="6" md="4">
              <input-tramite v-model="slugTramite" :disabled="!!query.filter.nomina_id" />
            </v-col>
            <v-col
              v-if="!query.filter.nomina_id && $auth.can(['operador', 'mesa-de-ayuda'])"
              cols="12"
              sm="6"
              md="5"
            >
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>
            <v-col cols="5" sm="6" md="3">
              <z-input v-model="query.scope.buscarEnTramite" :label="nombrePk" />
            </v-col>

            <v-col cols="7" sm="6">
              <z-input
                v-model="query.filter.estado"
                tipo="select"
                :items="payloadItemsEstadosCuenta"
                label="Estado"
                name="estado"
                clearable
                multiple
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filters[0].value"
                label="Vencimiento desde"
                tipo="mes"
                :max="query.filters[1].value"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <z-input
                v-model="query.filters[1].value"
                label="Vencimiento hasta"
                tipo="mes"
                :min="query.filters[0].value"
                clearable
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                :disabled="!slugTramite"
                :loading="loading"
                color="primary"
                outlined
                type="submit"
              >
                Buscar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #item.fecha="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss'
          )
        }}
        <div class="text--secondary text-caption">({{ $dayjs(value).fromNow() }})</div>
      </template>

      <template #item.pk_label="{ value, item }">
        {{ value }}
        <div v-if="item.subtitulo" class="text--secondary text-caption">
          {{ item.subtitulo }}
        </div>
      </template>

      <template #item.monto="{ value, item }">
        {{ value ? $formato.moneda(value) : '' }}
        <div v-if="item.monto_utm" class="text--secondary text-caption">
          UTM {{ item.monto_utm }}
        </div>
      </template>

      <template #item.estado="{ item }">
        <chip-cuenta
          :cuenta="item"
          small
          :to="
            item.estado === 11 && $auth.can(['cajero'])
              ? `/pago-presencial/cuentas?search=${item.pk}&tramite=${slugTramite}`
              : null
          "
        />
      </template>

      <template #view="{ item }">
        <cuentas-view :item="item" />
      </template>

      <template #form="{ accion, form, errors }">
        <cuentas-form :accion="accion" :errors="errors" :value="form" @input="form = $event" />
      </template>
    </z-mantenedor>
    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.id"
      :titulo="itemActivo.nombre"
      :microservicio="$payload.tramite(tramite.id, 'microservicio', 'id')"
      clase="cuenta"
      @cerrar="historialVisible = false"
    />

    <div v-if="items.length > 0">
      <v-btn
        :loading="loadingDescarga"
        color="primary"
        class="mr-1"
        outlined
        @click="descargarReporte('xlsx')"
      >
        <v-icon left> mdi-file-download-outline </v-icon>
        Excel
      </v-btn>
      <v-btn :loading="loadingDescarga" color="primary" outlined @click="descargarReporte('csv')">
        <v-icon left> mdi-file-download-outline </v-icon>
        Csv
      </v-btn>
    </div>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import CuentasView from '@/admin/views/Cuentas/CuentasView'
import CuentasForm from '@/admin/views/Cuentas/CuentasForm'
import InputTramite from '@/components/Input/InputTramite'
import ChipCuenta from '@/admin/components/ChipCuenta'
import DialogHistorial from '@/admin/components/DialogHistorial'
import { getError } from '@/utils/errors'
import { notificar } from '@/admin/utils/notificar'
import { saveAs } from 'file-saver'

export default {
  components: {
    DialogHistorial,
    ChipCuenta,
    InputTramite,
    ZInput,
    ZMantenedor,
    ZBtn,
    CuentasView,
    CuentasForm,
  },
  data() {
    return {
      loadingDescarga: false,
      items: [],
      itemActivo: {},
      nomina: {
        nombre: '',
      },
      historialVisible: false,
      slugTramite: this.$route.query.tramite ?? '',
      headerOptions: {
        identificador: {
          text: 'Identificador',
        },
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      query: {
        attributes: [
          'id',
          'estado',
          'pk',
          'pk_label',
          'subtitulo',
          'fecha_vencimiento',
          'fecha_pago',
          'monto',
          'monto_utm',
          'institucion_id',
          'codigo_verificacion',
        ],
        scope: {
          buscarEnTramite: '',
        },
        filter: {
          estado: parseInt(this.$route.query.estado),
          institucion_id: parseInt(this.$route.query.institucion_id),
          nomina_id: parseInt(this.$route.query.nomina_id),
        },
        filters: {
          0: { field: 'fecha_vencimiento', operator: '>=', value: null },
          1: { field: 'fecha_vencimiento', operator: '<=', value: null },
        },
        // include: 'pagos',
        sort: {
          0: { field: 'created_at', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    tramiteUrl() {
      return `/tramites/${this.slugTramite}/cuentas`
    },
    tramite() {
      return this.$tramites.find((t) => t.slug === this.slugTramite)
    },
    nombrePk() {
      return (
        (this.slugTramite &&
          this.$payload.tramite(this.slugTramite, 'definicion').buscador?.nombrePk) ||
        'Texto a buscar'
      )
    },
    headers() {
      return [
        {
          text: 'Institución',
          value: 'institucion_id',
          class: 'text-no-wrap',
          visible: this.headerOptions.institucion_id.visible,
          formatter: (v, item) =>
            this.payloadListaInstituciones[item.institucion_id] ||
            this.$formato.rut(item.institucion_id),
        },
        {
          text: this.headerOptions.identificador.text,
          value: 'pk_label',
        },
        {
          text: 'Vencimiento',
          value: 'fecha_vencimiento',
          formatter: (v) => this.$formato.fecha(v, 'L'),
        },
        {
          text: 'Monto neto',
          value: 'monto',
          cellClass: 'text-end',
          formatter: (v) => this.$formato.moneda(v),
        },
        {
          text: 'Fecha de pago',
          value: 'fecha_pago',
          formatter: (v) => this.$formato.fechaHora(v),
        },
        {
          text: 'Estado',
          value: 'estado',
        },
      ]
    },
  },
  mounted() {
    if (this.$route.query.nomina_id) {
      this.$axios
        .get(`tramites/${this.slugTramite}/nominas/${this.$route.query.nomina_id}`)
        .then((response) => {
          this.nomina = response.data.data || response.data
        })
    }
    this.updateHeaders()
  },
  methods: {
    volverAFirmar(cuenta){

      this.$axios
        .get(`tramites/${this.slugTramite}/cuentas/${cuenta.id}/enviar-a-firmar`)
        .then((response) => {
          this.data = response.data.data || response.data

          notificar({
            message:  this.data,
            severity: 'success',
          })
        }).catch((error) => {

          notificar({
            message: error.response.data.message || 'La cuenta no esta disponible para ser firmada',
            severity: 'error',
          })
        })
    },
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
    beforeSearch(query) {
      if (query.filters[0]?.value !== null) {
        query.filters[0].value += '-01'
      }
      if (query.filters[1]?.value !== null) {
        query.filters[1].value = this.$dayjs(query.filters[1].value, 'YYYY-MM')
          .add(1, 'month')
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      }
      if (query.filter.estado?.length === 0) {
        query.filter.estado = null
      }
    },
    afterSearch(response) {
      this.updateHeaders()

      // Agrega tramite_id a cuentas
      const cuentas = response.data.data || response.data
      return cuentas.map((cuenta) => ({ ...cuenta, tramite_id: this.tramite.id }))
    },

    afterUpdate(response) {
      if (
        response.codigo_verificacion === undefined &&
        response.estado >= 25 &&
        response.estado <= 26
      ) {
        alert(
          'La cuenta ha sido actualizada. Una copia del comprobante de pago llegará al correo del ciudadano.'
        )
      }
    },

    async beforeDialogView(cuenta) {
      return this.beforeDialog(cuenta, 'true')
    },
    async beforeDialogEdit(cuenta) {
      return this.beforeDialog(cuenta, 'editable')
    },
    async beforeDialog(cuenta, registro = '') {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`tramites/${this.slugTramite}/cuentas/${cuenta.id}?registro=${registro}`)
          .then((response) => {
            resolve(response.data.data)
          })
          .catch((error) => {
            error = getError(error)
            notificar({ message: error, severity: 'error' })
            reject(error)
          })
      })
    },
    updateHeaders() {
      this.headerOptions.institucion_id.visible =
        !this.query.filter.institucion_id && this.$auth.can(['operador', 'mesa-de-ayuda'])
      this.headerOptions.identificador.text = this.$payload.tramite(
        this.slugTramite,
        'definicion'
      ).buscador?.nombrePk
    },
    closeChipNomina() {
      this.query.filter.institucion_id = null
      this.query.filter.nomina_id = null
    },
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
    descargarReporte(formato = 'xlsx') {
      this.loadingDescarga = true
      const query = JSON.parse(JSON.stringify(this.query))
      this.beforeSearch(query)
      this.$axios
        .post(
          this.tramiteUrl + '/search',
          { ...query, formato },
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const filename = response.headers['content-disposition']
            ?.toString()
            ?.match(/filename=["']?([^'"]+)["']?/)[1]
          saveAs(response.data, filename)
        })
        .catch((error) => {
          error = getError(error)
          notificar({
            message: error || 'Reporte no disponible.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loadingDescarga = false
        })
    },
  },
}
</script>
