<template>
  <div>
    <h2 class="text-h3 mx-auto" style="max-width: 500px">
      {{ $auth.institucion().nombre }}
    </h2>

    <z-form-editar
      :id="$auth.institucion().id"
      v-model="institucion"
      :after-save="afterSave"
      url="usuarios/instituciones"
      @updated="notificar('La institución ha sido actualizada.')"
      @read:error="notificarErrorResponse('No se pudieron leer los datos de la institución.')"
      @update:error="notificarErrorResponse"
    >
      <template #default="{ form, errors, loading, save }">
        <v-card :loading="loading" class="mx-auto" max-width="500" tile>
          <!--
        <v-card-title>
          Edición de institución
        </v-card-title>
        -->

          <v-form ref="form" :readonly="loading" lazy-validation @submit.prevent="save">
            <v-card-text>
              <v-row>
                <v-col>
                  <!--<z-input
                    v-model="form.nombre"
                    :error-messages="errors.nombre"
                    :hint="hintNombreInstitucion()"
                    label="Nombre"
                    persistent-hint
                    data-testid="nombre"
                  />-->
                </v-col>
                <v-col v-if="institucion.tipo === 2" cols="3">
                  <z-input
                    v-model="form.ilustre"
                    :error-messages="errors.ilustre"
                    label="¿Ilustre?"
                    tipo="checkbox"
                    data-testid="ilustre"
                  />
                </v-col>
              </v-row>
              <v-row v-if="institucion.tipo === 1">
                <v-col>
                  <z-input
                    v-model="form.telefono"
                    :error-messages="errors.telefono"
                    hint="Ejemplo: (2) 25551234"
                    label="Teléfono"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.direccion"
                    :error-messages="errors.direccion"
                    label="Dirección"
                    data-testid="direccion"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.email"
                    :error-messages="errors.email"
                    label="Correo electrónico"
                    type="email"
                    data-testid="email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.url"
                    :error-messages="errors.url"
                    label="Dirección web"
                    data-testid="url"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <btn-guardar-cancelar :loading="loading" @cancelar="$router.push('/dashboard')" />
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </z-form-editar>
  </div>
</template>
<script>
import { notificar, notificarErrorResponse } from '@/admin/utils/notificar'
import ZInput from '@/components/Input/ZInput'
import ZFormEditar from '@/components/Input/ZFormEditar'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'

export default {
  components: { BtnGuardarCancelar, ZFormEditar, ZInput },
  data: () => ({
    institucion: {},
  }),
  computed: {},
  methods: {
    afterSave(institucion) {
      this.$store.dispatch('auth/setAuthInstitucion', institucion)
      this.$router.push('/dashboard')
    },
    notificarErrorResponse(event) {
      notificarErrorResponse(event)
    },
    notificar(event) {
      notificar(event)
    },
    hintNombreInstitucion() {
      if (this.institucion.tipo === 2) {
        return (
          'El nombre quedará así: ' +
          (this.institucion.ilustre ? 'I. ' : '') +
          'Municipalidad de ' +
          this.institucion.nombre
        )
      }
    },
  },
}
</script>
