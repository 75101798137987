<template>
  <v-chip
    :color="color(nomina.estado_masivo)"
    :outlined="outlined(nomina.estado_masivo)"
    dark
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ label(nomina.estado_masivo) }}
  </v-chip>
</template>

<script>
export default {
  props: {
    nomina: {
      type: Object,
      required: true,
    },
  },
  methods: {
    label(estado) {
      return this.$payloadStatic.estadosPagoMasivo()[estado] || ''
    },
    color(estado) {
      return (
        {
          1: 'grey',
          2: 'warning',
          3: 'success',
          4: 'error',
          7: 'warning',
          10: 'success',
        }[estado] ?? 'grey'
      )
    },
    outlined(estado) {
      return true
    },
  },
}
</script>
