var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('z-mantenedor',{attrs:{"botones-listar":[
      { title: 'Descargar archivo', icon: 'mdi-cloud-download-outline', click: _vm.descargarArchivo },
    ],"botones-ver":[
      { title: 'Descargar archivo', icon: 'mdi-cloud-download-outline', click: _vm.descargarArchivo },
    ],"campo-subtitulo-dialog":(v) => _vm.formatAccion(v.nombre),"campo-titulo-dialog":(v) => ` ${v.nombre}`,"headers":_vm.headers,"query":_vm.query,"url":_vm.tramiteUrl,"search-url":_vm.tramiteUrl + '/search?with_trashed=true',"search-method":"post","on-after-search":_vm.afterSearch,"search-inicial":_vm.$route.query.buscar === 'true',"disable-edit":"","disable-delete":!_vm.$auth.can(['gestionar-nominas']),"texto-filtrar":"Filtrar"},scopedSlots:_vm._u([{key:"buscador",fn:function({ loading, search }){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return search.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":_vm.puedeSeleccionarInstitucion ? 4 : 6}},[_c('input-tramite',{attrs:{"filter":(t) => !['pagos-genericos', 'multas'].includes(t.slug)},model:{value:(_vm.slugTramite),callback:function ($$v) {_vm.slugTramite=$$v},expression:"slugTramite"}})],1),(_vm.puedeSeleccionarInstitucion)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('z-input',{attrs:{"items":_vm.payloadItemsInstituciones,"clearable":"","label":"Institución","tipo":"autocomplete"},model:{value:(_vm.query.filter.institucion_id),callback:function ($$v) {_vm.$set(_vm.query.filter, "institucion_id", $$v)},expression:"query.filter.institucion_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"7","sm":"3"}},[_c('z-input',{attrs:{"tipo":"select","items":[
                { value: 'por-importar', text: 'Por importar' },
                { value: 'importando', text: 'Importando' },
                { value: 'importada', text: 'Importada' },
                { value: 'error', text: 'Error' },
                { value: 'eliminada', text: 'Eliminada' },
              ],"label":"Estado","clearable":""},model:{value:(_vm.query.scope.estado),callback:function ($$v) {_vm.$set(_vm.query.scope, "estado", $$v)},expression:"query.scope.estado"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex"},[_c('v-spacer'),_c('z-btn',{attrs:{"loading":loading,"disabled":!_vm.slugTramite,"color":"primary","outlined":"","type":"submit"}},[_vm._v(" Actualizar ")])],1)],1)],1)]}},{key:"item.fecha",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$dayjs(value).format( _vm.$dayjs(value).isBefore(_vm.$dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss' ))+" "),_c('div',{staticClass:"text--secondary text-caption"},[_vm._v("("+_vm._s(_vm.$dayjs(value).fromNow())+")")])]}},{key:"item.nombre",fn:function({ value, item }){return [_vm._v(" "+_vm._s(value)+" "),_c('div',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(item.user_nombre)+" ")])]}},{key:"item.estado",fn:function({ item }){return [_c('chip-nomina',{attrs:{"title":item.error,"nomina":item,"small":""}})]}},{key:"item.cantidad",fn:function({ value, item }){return [(value > 0)?_c('v-chip',{attrs:{"small":"","to":`/cuentas?nomina_id=${item.id}&institucion_id=${item.institucion_id}&tramite=${_vm.slugTramite}&buscar=true`}},[_vm._v(" "+_vm._s(_vm.$formato.numero(value || 0))+" ")]):_vm._e()]}},{key:"view",fn:function({ item }){return [_c('nominas-view',{attrs:{"item":item}})]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }