<template>
  <div>
    <h2 class="text-h3 mx-auto" style="max-width: 500px">
      {{ $auth.institucion().nombre }}
    </h2>
    <v-card class="mx-auto my-4" max-width="500">
      <v-card-text>
        <input-tramite v-model="slugTramite" />
      </v-card-text>
    </v-card>
    <z-form-editar
      v-if="tramite_id"
      :id="$auth.institucion().id + tramite_id.toString().padStart(3, '0')"
      v-model="habilitacion"
      :before-save="beforeSave"
      :after-save="afterSave"
      :url="`tramites/${slugTramite}/habilitaciones`"
      @updated="notificar('Los datos del pago han sido actualizados.')"
      @update:error="notificarErrorResponse"
    >
      <template #default="{ form, errors, loading, save }">
        <template v-if="habilitacion.id">
          <v-form ref="form" :readonly="loading" lazy-validation @submit.prevent="save">
            <v-card :loading="loading" class="mx-auto" max-width="500">
              <v-card-title>
                Datos de contacto para {{ $payload.tramite(slugTramite) }}
              </v-card-title>

              <v-card-text>
                <p>
                  Estos datos serán presentados a los contribuyentes en el pie de página de este
                  sitio web y en otras páginas de ayuda. Debes indicar el nombre y datos de contacto
                  del departamento o dirección de la cual depende el pago. En caso de dejarlos en
                  blanco se utilizará la dirección registrada en la municipalidad.
                </p>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.nombre"
                      :error-messages="errors.nombre"
                      label="Departamento o unidad"
                      hint="Ejemplo: Dirección de tránsito"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.direccion"
                      :error-messages="errors.direccion"
                      label="Dirección"
                      hint="Ejemplo: Las Perdices 456"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.telefono"
                      :error-messages="errors.telefono"
                      label="Teléfono"
                      hint="Ejemplo: (2) 5551234"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card
              v-if="form.institucion_id"
              :loading="loading"
              class="mx-auto my-4"
              max-width="500"
            >
              <v-card-title> Firma electrónica de documentos emitidos </v-card-title>

              <v-card-text>
                <p>
                  Debes escoger el certificado con el cual se firmarán electrónicamente los
                  documentos generados por el sistema.
                </p>
                <v-row>
                  <v-col>
                    <input-firmador
                      v-model="form.firmador"
                      :institucion-id="form.institucion_id"
                      :error-messages="errors.firmador"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card :loading="loading" class="mx-auto my-4" max-width="500">
              <v-card-title> Cuenta bancaria para el depósito </v-card-title>

              <v-card-text>
                <p>
                  Cuenta corriente donde se realizará el depósito de los pagos realizados por los
                  contribuyentes.
                </p>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.banco"
                      :error-messages="errors.banco"
                      label="Banco"
                      tipo="select"
                      :disabled="$auth.personificando()"
                      :items="payloadItemsBancos"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.cuenta"
                      :error-messages="errors.cuenta"
                      label="Número de cuenta"
                      :disabled="$auth.personificando()"
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="form.tipo_cuenta"
                      :disabled="$auth.personificando()"
                      :items="[
                        { text: 'Cuenta corriente', value: 1 },
                        { text: 'Cuenta vista', value: 2 },
                        { text: 'Cuenta de ahorro', value: 3 },
                      ]"
                      dense
                      label="Tipo de cuenta"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card :loading="loading" class="mx-auto my-4" max-width="500">
              <v-card-title> Notificación on-line de pagos </v-card-title>

              <v-card-text>
                <p>
                  Cada vez que un contribuyente realice un pago de Patentes municipales, el sistema
                  enviará una notificación a los destinos aquí indicados.
                </p>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.notificacion_email"
                      :error-messages="errors.notificacion_email"
                      label="Correos electrónicos para notificaciones"
                      hint="Puedes ingresar más de una casilla separándolas por punto y coma."
                      persistent-hint
                      :maxlength="255"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-text>
                <p class="text-subtitle-1">Notificaciones REST</p>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.notificacion_rest_url"
                      :error-messages="errors.notificacion_rest_url"
                      label="URL"
                      hint="Ejemplo: https://municipalidad.cl/api/notificar-pagos"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.notificacion_rest_password"
                      :error-messages="errors.notificacion_rest_password"
                      name="notificacion_rest_password"
                      autocomplete="new-password"
                      label="Contraseña para notificaciones"
                      hint="Contraseña opcional que será enviada en las notificaciones REST"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-text v-if="form.notificacion_soap_url">
                <p class="text-subtitle-1">Notificaciones SOAP</p>
                <v-row>
                  <v-col>
                    <z-input
                      v-model="form.notificacion_soap_url"
                      :error-messages="errors.notificacion_soap_url"
                      label="URL"
                      disabled="true"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card :loading="loading" class="mx-auto my-4" max-width="500">
              <v-card-title> Intervalo de fecha y hora disponible para pagar </v-card-title>

              <v-card-text>
                <p>
                  Se recomienda dejar estas fechas en blanco. Utilizarlas sólo en caso de ser
                  necesario aplicar una restricción al período de pago.
                </p>
                <v-row>
                  <v-col>
                    <z-input-fecha-hora
                      v-model="form.fecha_desde"
                      :error-messages="errors.fecha_desde"
                      label="Pago disponible desde"
                      clearable
                      dense
                      outlined
                      hint="Ejemplo: 31/12/2021 00:00."
                      persistent-hint
                    />
                  </v-col>
                  <v-col>
                    <z-input-fecha-hora
                      v-model="form.fecha_hasta"
                      :error-messages="errors.fecha_hasta"
                      label="Pago disponible hasta"
                      clearable
                      dense
                      outlined
                      hint="Ejemplo: 31/12/2029 23:59."
                      persistent-hint
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card :loading="loading" class="mx-auto my-4" max-width="500">
              <v-card-text>
                Antes de guardar, asegurate que los datos que hayas modificado sean los correctos.
              </v-card-text>
              <v-card-actions>
                <btn-guardar-cancelar
                  :loading="loading"
                  type="submit"
                  @cancelar="$router.push('/dashboard')"
                />
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </template>
    </z-form-editar>

    <z-alert
      v-if="tramite_id && JSON.stringify(habilitacion) === '{}'"
      width="500"
      class="mx-auto my-8"
    >
      Trámite no disponible
    </z-alert>
  </div>
</template>
<script>
import { notificar, notificarErrorResponse } from '@/admin/utils/notificar'
import ZInput from '@/components/Input/ZInput'
import ZFormEditar from '@/components/Input/ZFormEditar'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import InputTramite from '@/components/Input/InputTramite'
import ZAlert from '@/components/ZAlert'
import ZInputFechaHora from '@/components/Input/ZInputFechaHora'
import InputFirmador from '@/admin/components/Input/InputFirmador'

export default {
  components: {
    InputFirmador,
    ZInputFechaHora,
    ZAlert,
    InputTramite,
    BtnGuardarCancelar,
    ZFormEditar,
    ZInput,
  },
  data: () => ({
    habilitacion: {},
    slugTramite: '', // this.$store?.getters['app/tramite'] || '',
  }),
  computed: {
    tramite_id() {
      return this.$payload.tramite(this.slugTramite, 'id')
    },
  },
  beforeCreate() {
    this.slugTramite = this.$store?.getters['app/tramite'] || ''
  },
  methods: {
    beforeSave(habilitacion) {
      if (this.$auth.personificando()) {
        delete habilitacion.banco
        delete habilitacion.cuenta
        delete habilitacion.tipo_cuenta
      }
    },
    afterSave(habilitacion) {
      this.$router.push('/dashboard')
    },
    notificarErrorResponse(event) {
      notificarErrorResponse(event)
    },
    notificar(event) {
      notificar(event)
    },
  },
}
</script>
