<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Copiar cuentas',
          icon: 'mdi-content-copy',
          click: copiarRoles,
          posicion: 'izquierda',
        },
      ]"
      :campo-titulo-dialog="(v) => 'Pago'"
      :campo-subtitulo-dialog="(v) => $payload.tramite(v.tramite_id, 'nombre', 'id')"
      :headers="headers"
      :query="query"
      :url="`/tramites/${slugTramite}/pagos`"
      :search-url="`/tramites/${slugTramite}/pagos/search`"
      search-inicial
      search-method="post"
      :on-before-search="beforeSearch"
      :on-after-search="afterSearch"
      disable-edit
      disable-delete
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="5" md="4">
              <input-tramite v-model="slugTramite" />
            </v-col>
            <v-col
              v-if="!query.filter.nomina_id && $auth.can(['operador', 'mesa-de-ayuda'])"
              cols="12"
              sm="6"
              md="5"
            >
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <z-input v-model="diaDePago" label="Día de pago" type="date" clearable />
            </v-col>

            <v-col cols="6" sm="3">
              <z-input
                v-model="query.scope.estado"
                tipo="select"
                :items="[
                  { value: 'pagando', text: 'Pagando' },
                  { value: 'pagado', text: 'Pagado TGR' },
                  { value: 'pagado-presencial', text: 'Pagado en caja municipal' },
                  { value: 'no-pagado', text: 'No pagado' },
                ]"
                label="Estado"
                clearable
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn
                :disabled="!slugTramite"
                :loading="loading"
                color="primary"
                outlined
                type="submit"
              >
                Buscar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.created_at="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'L HH:mm:ss' : 'L HH:mm:ss'
          )
        }}
        <div class="text--secondary text-caption">({{ $dayjs(value).fromNow() }})</div>
      </template>

      <template #item.fecha_pago="{ value }">
        {{
          value
            ? $dayjs(value)
                .tz()
                .format(
                  $dayjs(value).isBefore($dayjs().subtract(6, 'hour')) ? 'L HH:mm:ss' : 'HH:mm:ss'
                )
            : ''
        }}
      </template>

      <template #item.fecha_regreso="{ value }">
        {{
          value
            ? $dayjs(value).tz().format(
                $dayjs(value).isBefore($dayjs().subtract(6, 'hour')) ? 'L HH:mm:ss' : 'HH:mm:ss'
              )
            : ''
        }}
      </template>
      <template #item.cuentas="{ item }">
        <chip-label-cuenta
          v-for="cuenta in item.cuentas"
          :key="cuenta.id"
          :cuenta="cuenta"
          :color="item.estado >= 25 && item.estado <= 26 ? 'primary' : 'disabled'"
          mostrar-tramite
          @click="item.estado >= 25 && item.estado <= 26 ? $util.descargarPdf(cuenta) : {}"
        />
      </template>

      <template #item.estado="{ item }">
        <chip-pago :pago="item" small />
      </template>

      <template #item.monto_total="{ item }">
        <montos-tooltip :montos="item">
          {{ $formato.moneda(item.monto_total) }}
        </montos-tooltip>
      </template>

      <template #view="{ item }">
        <v-simple-table>
          <tbody>
            <tr>
              <th>Institución recaudadora</th>
              <td>{{ $payload.ira(item.ira) }}</td>
            </tr>
            <tr v-if="item.tgr_token">
              <th>Código TGR</th>
              <td>{{ item.tgr_token }}</td>
            </tr>
            <tr>
              <th>Neto</th>
              <td>{{ $formato.moneda(item.monto - item.monto_descuento) }}</td>
            </tr>
            <tr>
              <th>Intereses</th>
              <td>{{ $formato.moneda(item.monto_interes) }}</td>
            </tr>
            <tr>
              <th>Reajustes</th>
              <td>{{ $formato.moneda(item.monto_reajuste) }}</td>
            </tr>
            <tr>
              <th>Monto total</th>
              <td>{{ $formato.moneda(item.monto_total) }}</td>
            </tr>
            <tr v-if="item.monto_saldo">
              <th>Saldo</th>
              <td>{{ $formato.moneda(item.monto_saldo) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-h6 mt-6">Fechas</div>

        <v-simple-table>
          <tbody>
            <tr>
              <th>Inicio del pago</th>
              <td>{{ $dayjs(item.created_at).tz().format('LLLL:ss') }}</td>
            </tr>
            <tr>
              <th>Fecha de pago (TGR)</th>
              <td>{{ item.fecha_pago ? $dayjs(item.fecha_pago).tz().format('LLLL:ss') : '---' }}</td>
            </tr>
            <tr>
              <th>Regreso al portal SEM</th>
              <td>
                {{ item.fecha_regreso ? $dayjs(item.fecha_regreso).tz().format('LLLL:ss') : '---' }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-h6 mt-6">Contacto</div>

        <v-simple-table>
          <tbody>
            <tr v-if="item.monto_saldo">
              <th>Saldo</th>
              <td>{{ $formato.moneda(item.monto_saldo) }}</td>
            </tr>
            <tr>
              <th>Nombre</th>
              <td>{{ item.nombre }}</td>
            </tr>
            <tr>
              <th>Correo electrónico</th>
              <td>{{ item.email }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-h6 mt-6">Cuenta</div>

        <v-simple-table>
          <tbody>
            <tr v-for="cuenta in item.cuentas" :key="cuenta.id">
              <th>
                {{ cuenta.pk_label }}
                {{ cuenta.subtitulo }}
              </th>
              <td class="text--secondary text-caption">
                {{ $payload.tramite(cuenta.tramite_id, 'nombre', 'id') }}
              </td>
              <td>
                <v-btn
                  v-if="
                    item.estado >= 25 && item.estado <= 26 && cuenta.codigo_verificacion !== null
                  "
                  icon
                  @click="$util.descargarPdf(cuenta)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    item.estado >= 25 && item.estado <= 26 && cuenta.codigo_verificacion !== null
                  "
                  icon
                  @click="$util.descargarXml(cuenta)"
                >
                  <v-icon>mdi-file-code-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZInput from '@/components/Input/ZInput'
import InputTramite from '@/components/Input/InputTramite'
import ChipPago from '@/admin/components/ChipPago'
import MontosTooltip from '@/components/MontosTooltip'
import ChipLabelCuenta from '@/components/ChipLabelCuenta'
export default {
  components: { ChipLabelCuenta, MontosTooltip, ChipPago, InputTramite, ZInput, ZMantenedor, ZBtn },
  data() {
    return {
      items: [],
      itemActivo: {},
      historialVisible: false,
      slugTramite: this.$route.query.tramite || this.$store.getters['app/tramite'] || '',
      headerOptions: {
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      diaDePago: null,
      query: {
        scope: {
          estado: this.$route.query.estado,
        },
        include: 'cuentas',
        filters: {
          fecha_pago_desde: {
            field: 'fecha_pago',
            operator: '>=',
            value: this.$route.params.fecha || this.$dayjs().tz().format('YYYY-MM-DD'),
          },
          fecha_pago_hasta: { field: 'fecha_pago', operator: '<=', value: null },
        },
        filter: {
          institucion_id: parseInt(this.$route.query.institucion_id),
        },
        sort: {
          0: { field: 'created_at', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Institución',
          value: 'institucion_id',
          class: 'text-no-wrap',
          visible: this.headerOptions.institucion_id.visible,
          formatter: (v, item) => this.payloadListaInstituciones[item.institucion_id],
        },
        {
          text: 'Fecha de pago',
          value: 'fecha_pago',
          formatter: (v) => this.$formato.fechaHora(v),
        },
        {
          text: 'Monto pagado',
          value: 'monto_total',
          cellClass: 'text-center pr-md-10',
          formatter: (v) => this.$formato.moneda(v),
        },
        {
          text: 'Cuentas',
          value: 'cuentas',
        },
        {
          text: 'Estado',
          value: 'estado',
        },
      ]
    },
  },
  watch: {
    diaDePago(fecha) {
      if (!fecha) {
        this.query.filters.fecha_pago_desde.value = null
        this.query.filters.fecha_pago_hasta.value = null
        return
      }

      this.query.filters.fecha_pago_desde.value = this.$dayjs.tz(fecha).startOf('day').toJSON()
      this.query.filters.fecha_pago_hasta.value = this.$dayjs.tz(fecha).endOf('day').toJSON()
    },
  },
  mounted() {
    this.diaDePago = this.$route.query.fecha ?? this.$dayjs().tz().format('YYYY-MM-DD')
  },
  methods: {
    beforeSearch(query) {
      if (!query.filters.fecha_pago_desde.value) {
        this.errors = { fecha_pago_desde: 'Fecha inválida' }
        return false
      }

      query.filters.fecha_pago_desde.value = this.$dayjs.tz(this.diaDePago ?? this.$route.query.fecha)
        .startOf('day')
        .toJSON()
      query.filters.fecha_pago_hasta.value = this.$dayjs.tz(this.diaDePago ?? this.$route.query.fecha)
        .endOf('day')
        .toJSON()
      this.errors = {}
    },
    afterSearch() {
      this.updateHeaders()
    },
    updateHeaders() {
      this.headerOptions.institucion_id.visible =
        !this.query.filter.institucion_id && this.$auth.can(['operador', 'mesa-de-ayuda'])
    },
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
    copiarRoles(item) {
      const pks = []
      item.cuentas.map((c) => {
        pks.push(c.pk_label)
      })
      navigator.clipboard.writeText(pks)
    },
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
  },
}
</script>
