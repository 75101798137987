<template>
  <div>
    <z-mantenedor
      v-model="items"
      :campo-subtitulo-dialog="(utm) => $dayjs(utm.id + '01', 'YYYYMMDD').format('MMMM YYYY')"
      :campo-titulo-dialog="(utm) => 'UTM'"
      :headers="headers"
      :query="query"
      url="/pagos/utm"
      search-url="/pagos/utm/search"
      search-method="post"
      disable-delete
      disable-view
      height="350"
      search-inicial
      width="380"
      @created="$emit('notificar', 'La UTM fue creada')"
      @created:error="$emit('notificar:error', 'Error al intentar crear UTM.')"
      @updated="$emit('notificar', 'UTM guardada.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-card class="d-flex justify-space-between" flat>
            <v-select
              v-model="query.scopes[0].parameters[0]"
              :items="anos"
              dense
              label="Año"
              outlined
            />
            <v-spacer />
            <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            <z-btn
              class="mx-1"
              :loading="loading"
              color="primary"
              outlined
              @click="create({ monto: '' })"
            >
              Crear
            </z-btn>
          </v-card>
        </v-form>
      </template>

      <template #form="{ accion, form, errors }">
        <v-row v-if="accion === 'create'">
          <v-col>
            <z-input-mes v-model="form.mes" :error-messages="errors.mes" label="Mes" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.monto"
              :error-messages="errors.monto"
              label="Monto"
              minlength="5"
              type="number"
              outlined
            />
          </v-col>
        </v-row>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZInputMes from '@/components/Input/ZInputMes'
import ZMantenedor from '@/components/ZMantenedor'

export default {
  components: { ZMantenedor, ZBtn, ZInputMes },
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Mes',
          value: 'id',
          formatter: (v) => this.$dayjs(v + '01', 'YYYYMMDD').format('MMMM YYYY'),
        },
        {
          text: 'Monto',
          value: 'monto',
          formatter: (v) => this.$formato.moneda(v),
        },
        {
          text: 'Última edición',
          value: 'updated_at',
          formatter: (v) => this.$dayjs(v).format('L LT'),
        },
      ],
      query: {
        scopes: {
          0: { name: 'ano', parameters: [2020] },
        },
        sort: {
          0: { field: 'id', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    anoActual() {
      return parseInt(this.$dayjs().format('YYYY'))
    },
    anos() {
      const anos = []

      for (let ano = this.$dayjs().month() === 11 ? this.anoActual + 1 : this.anoActual ; ano >= 2012; ano--) {
        anos.push(ano)
      }
      return anos
    },
  },
  beforeMount() {
    this.query.scopes[0].parameters[0] = this.anoActual
  },
}
</script>
