var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"text-h3 mx-auto",staticStyle:{"max-width":"500px"}},[_vm._v(" "+_vm._s(_vm.$auth.institucion().nombre)+" ")]),_c('v-card',{staticClass:"mx-auto my-4",attrs:{"max-width":"500"}},[_c('v-card-text',[_c('input-tramite',{attrs:{"filter":(t) =>
            ![
              'pagos-genericos',
              'multas',
              'pagos-de-prueba',
              'permisos-de-circulacion-primer-permiso',
            ].includes(t.slug)},model:{value:(_vm.slugTramite),callback:function ($$v) {_vm.slugTramite=$$v},expression:"slugTramite"}})],1)],1),(JSON.stringify(_vm.habilitacion) !== '{}')?[_c('v-card',{staticClass:"mx-auto my-8 pb-4",attrs:{"min-width":"800"}},[_c('v-card-title',[_vm._v(" Enlace al buscador ")]),_c('v-card-subtitle',[_vm._v(" Selecciona el botón que deseas instalar en el sitio web de la municipalidad. ")]),_vm._l(([
          { filename: '1.png', width: 300, height: 251 },
          { filename: '2.png', width: 421, height: 181 },
          { filename: '3.png', width: 985, height: 171 },
        ]),function(banner){return _c('div',{key:banner.filename.replaceAll('.', '')},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto my-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 8 : 1,"width":banner.width,"height":banner.height},on:{"click":function($event){return _vm.showDialog(banner)}}},[_c('v-img',{attrs:{"contain":"","src":`${_vm.www}/banners/${_vm.slugTramite}/${banner.filename}`}})],1)]}}],null,true)})],1)})],2)]:_vm._e(),(_vm.tramite_id && JSON.stringify(_vm.habilitacion) === '{}')?_c('z-alert',{staticClass:"mx-auto my-8",attrs:{"width":"500","ma":""}},[_vm._v(" Trámite no disponible ")]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('dialog-toolbar',{on:{"close":function($event){_vm.dialog = false}}},[_vm._v(" Banner con enlace al buscador ")]),_c('v-card-subtitle',[_vm._v(" Copia el siguiente código HTML e insertalo en el sitio web. ")]),_c('v-card-text',[_c('v-card',{attrs:{"dark":""}},[_c('v-card-text',{staticClass:"text-caption secondary"},[_vm._v(" "+_vm._s(_vm.html)+" ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-card',{staticClass:"mx-auto my-8",attrs:{"min-width":"800"}},[_c('v-card-title',[_vm._v(" Enlace para validar documentos ")]),_c('v-card-subtitle',[_vm._v(" Selecciona el botón que deseas instalar en el sitio web de la municipalidad. (El enlace es el mismo para todos los trámites) ")]),_c('v-row',{attrs:{"justify":"space-around"}},_vm._l(([
          { filename: '1.png', width: 158, height: 68 },
          { filename: '2.png', width: 176, height: 100 },
          { filename: '3.png', width: 161, height: 136 },
          { filename: '4.png', width: 163, height: 191 },
        ]),function(banner){return _c('v-col',{key:banner.filename.replaceAll('.', ''),attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 8 : 1,"width":banner.width,"height":banner.height},on:{"click":function($event){return _vm.showDialogValidar(banner)}}},[_c('v-img',{attrs:{"contain":"","src":`${_vm.www}/banners/validar/${banner.filename}`}})],1)]}}],null,true)})],1)}),1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialogValidar),callback:function ($$v) {_vm.dialogValidar=$$v},expression:"dialogValidar"}},[_c('v-card',[_c('dialog-toolbar',{on:{"close":function($event){_vm.dialogValidar = false}}},[_vm._v(" Banner con enlace para validar documentos ")]),_c('v-card-subtitle',[_vm._v(" Copia el siguiente código HTML e insertalo en el sitio web. ")]),_c('v-card-text',[_c('v-card',{attrs:{"dark":""}},[_c('v-card-text',{staticClass:"text-caption secondary"},[_vm._v(" "+_vm._s(_vm.htmlValidar)+" ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialogValidar = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }