<template>
  <div>
    <v-btn
      class="float-end"
      color="primary"
      fab
      fixed
      bottom
      right
      style="z-index: 15"
      :loading="loading > 0"
      @click="requests"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-card max-width="600px" class="my-4 mx-auto">
      <v-card-title> Colas </v-card-title>
      <v-simple-table>
        <thead>
          <tr class="text-subtitle-1">
            <td>Cola</td>
            <td>Estado</td>
            <td class="text-right">Mensajes</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="queue in queues">
            <tr v-if="!(queue.messages === 0 && queue.name.includes('.delay.'))" :key="queue.name">
              <td>
                {{ queue.name.split('.')[0] }}
                <span v-if="queue.name.includes('.delay.')" class="text--secondary text-caption">
                  ({{ $dayjs.duration(parseInt(queue.name.split('.delay.')[1])).humanize() }})
                </span>
              </td>
              <td>
                <v-chip small outlined :color="queue.state === 'running' ? 'primary' : 'disabled'">
                  {{ queue.state }}
                </v-chip>
              </td>
              <td class="text-right">
                {{ $formato.numero(queue.messages_ready) }}
                <span v-if="queue.messages_ready !== queue.messages">
                  / {{ $formato.numero(queue.messages) }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-card max-width="1200px" class="my-4 mx-auto">
      <v-card-title> Resumen de Jobs ejecutados </v-card-title>
      <v-card-text>
        <vue-apex-charts
          height="350"
          :options="chartJobsConsolidado.chartOptions"
          :series="chartJobsConsolidado.series"
        />
      </v-card-text>
    </v-card>

    <v-card
      v-if="
        chartPorJobProcessed &&
        chartPorJobProcessed.series.length > 0 &&
        chartPorJobProcessed.series[0].data.filter((v) => v > 0).length > 0
      "
      class="my-4 mx-auto"
    >
      <v-card-title> Jobs exitosos por minuto </v-card-title>
      <v-card-text>
        <vue-apex-charts
          height="400"
          :options="chartPorJobProcessed.chartOptions"
          :series="chartPorJobProcessed.series"
        />
      </v-card-text>
    </v-card>

    <v-card
      v-if="
        chartPorJobFailed &&
        chartPorJobFailed.series.length > 0 &&
        chartPorJobFailed.series[0].data.filter((v) => v > 0).length > 0
      "
      class="my-4 mx-auto"
    >
      <v-card-title> JobFailed por minuto </v-card-title>
      <v-card-text>
        <vue-apex-charts
          height="400"
          :options="chartPorJobFailed.chartOptions"
          :series="chartPorJobFailed.series"
        />
      </v-card-text>
    </v-card>

    <v-card
      v-if="
        chartPorJobExceptionOccurred &&
        chartPorJobExceptionOccurred.series.length > 0 &&
        chartPorJobExceptionOccurred.series[0].data.filter((v) => v > 0).length > 0
      "
      class="my-4 mx-auto"
    >
      <v-card-title> JobExceptionOccurred por minuto </v-card-title>
      <v-card-text>
        <vue-apex-charts
          height="400"
          :options="chartPorJobExceptionOccurred.chartOptions"
          :series="chartPorJobExceptionOccurred.series"
        />
      </v-card-text>
    </v-card>

    <v-card class="my-4 mx-auto">
      <v-card-title> Últimos logs de Jobs </v-card-title>
      <v-card-text v-if="!logs.length" class="text-center py-6"> (ninguno) </v-card-text>
      <v-card-text v-else>
        <v-simple-table>
          <thead>
            <tr>
              <th>Hora</th>
              <th>Cola</th>
              <th>Evento</th>
              <th>Job</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in logs" :key="log.id">
              <td>
                {{ $dayjs(log.fecha).format('HH:mm:ss') }}.<span
                  class="text--secondary text-caption"
                  >{{ $dayjs(log.fecha).format('SSS') }}</span
                >
              </td>

              <td>
                {{ log.payload.queue }}
                <!--{{ log.microservicio }}-->
              </td>

              <td>
                <v-chip :color="log.nivel" outlined small>
                  {{ log.nombre }}
                </v-chip>
                <div
                  class="text-caption text--secondary text-truncate hidden-xs-only"
                  style="max-width: 250px"
                  :title="log.payload.exception.replace(/ in \/.*/s, '').substr(0, 200) + '...'"
                >
                  {{ log.payload.exception.replace(/ in \/.*/s, '').substr(0, 200) }}
                </div>
              </td>

              <td>
                {{ log.clase.split('\\').pop() }}
                <span class="text--secondary text-caption">({{ log.payload.attempts }})</span>
                <div class="text-caption text--secondary hidden-sm-and-down">
                  {{ log.payload.uuid }}
                </div>
              </td>
              <td>
                <v-btn
                  icon
                  @click="
                    logDetalle = log
                    dialogLogVisible = true
                  "
                >
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <z-dialog
      v-if="dialogLogVisible"
      v-model="logDetalle"
      campo-titulo="nombre"
      campo-subtitulo="clase"
      max-width="900"
      :items="logs"
      navegacion
      @cerrar="dialogLogVisible = false"
    >
      <v-simple-table>
        <tbody>
          <template
            v-for="(valor, campo) in {
              fecha: $dayjs(logDetalle.fecha).format('HH:mm:ss.SSS'),
              microservicio: logDetalle.microservicio,
              ms: logDetalle.ms,
              nivel: logDetalle.nivel,
              ...logDetalle.payload,
            }"
          >
            <tr v-if="campo !== 'exception' && valor !== null" :key="campo">
              <td>{{ campo }}</td>
              <td>{{ valor }}</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <v-card v-if="logDetalle.payload.exception" class="mt-8">
        <v-card-title> Exception </v-card-title>
        <v-card-text>
          <pre class="text-caption">{{
            logDetalle.payload.exception.replaceAll(
              'vendor/laravel/framework/src/Illuminate',
              'v.../Illuminate'
            )
          }}</pre>
        </v-card-text>
      </v-card>
    </z-dialog>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ZDialog from '@/components/ZDialog'

export default {
  components: {
    ZDialog,
    VueApexCharts,
  },
  data() {
    return {
      queues: [],
      jobs: [],
      logs: [],
      jobsConsolidado: [],
      listaJobs: [],
      detalleJobProcessed: {},
      detalleJobFailed: {},
      detalleJobExceptionOccurred: {},
      loading: 0,
      logDetalle: {},
      dialogLogVisible: false,
    }
  },
  computed: {
    chartJobsConsolidado() {
      return {
        series: [
          {
            name: 'JobProcessing',
            data: this.jobsConsolidado.map((j) => j.total.JobProcessing),
          },
          {
            name: 'JobProcessed',
            data: this.jobsConsolidado.map((j) => j.total.JobProcessed),
          },
          {
            name: 'JobExceptionOccurred',
            data: this.jobsConsolidado.map((j) => j.total.JobExceptionOccurred),
          },
          {
            name: 'JobFailed',
            data: this.jobsConsolidado.map((j) => j.total.JobFailed),
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            animations: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 1,
            },
          },
          xaxis: {
            type: 'datetime',
            categories: this.jobsConsolidado.map((j) => j.fecha),
            labels: {
              datetimeUTC: false,
              format: 'HH:mm',
            },
          },
          yaxis: {
            title: {
              text: 'Ejecuciones',
            },
          },
          dataLabels: {
            style: {
              fontSize: '9px',
            },
          },
          tooltip: {
            x: {
              format: 'HH:mm',
            },
          },
          legend: {
            position: 'bottom',
            offsetY: 5,
          },
        },
      }
    },
    chartPorJobProcessed() {
      return {
        series: JSON.parse(
          JSON.stringify(
            this.listaJobs.map((j) => ({
              name: j.replace(/:.*Jobs\\/, ':'),
              data: Object.values(this.detalleJobProcessed[j]).reverse(),
            }))
          )
        ),
        chartOptions: {
          chart: {
            type: 'heatmap',
            height: 70 + this.listaJobs.length * 20,
            stacked: false,
            animations: {
              enabled: true,
            },
          },
          plotOptions: {
            heatmap: {
              distributed: true,
              colorScale: {
                inverse: true,
              },
            },
          },
          theme: {
            monochrome: {
              enabled: true,
              color: '#00E396',
            },
          },
          dataLabels: {
            style: {
              fontSize: '9px',
            },
          },
          xaxis: {
            type: 'datetime',
            categories: this.jobsConsolidado.map((j) => j.fecha).reverse(),
            labels: {
              datetimeUTC: false,
              format: 'HH:mm',
            },
          },
          tooltip: {
            x: {
              format: 'HH:mm',
            },
          },
        },
      }
    },
    chartPorJobFailed() {
      const chart = {
        chartOptions: JSON.parse(JSON.stringify(this.chartPorJobProcessed.chartOptions)),
        series: JSON.parse(
          JSON.stringify(
            this.listaJobs.map((j) => ({
              name: j.replace(/:.*Jobs\\/, ':'),
              data: Object.values(this.detalleJobFailed[j]).reverse(),
            }))
          )
        ),
      }

      chart.chartOptions.theme.monochrome.color = '#FF0000'

      return chart
    },
    chartPorJobExceptionOccurred() {
      const chart = {
        chartOptions: JSON.parse(JSON.stringify(this.chartPorJobProcessed.chartOptions)),
        series: JSON.parse(
          JSON.stringify(
            this.listaJobs.map((j) => ({
              name: j.replace(/:.*Jobs\\/, ':'),
              data: Object.values(this.detalleJobExceptionOccurred[j]).reverse(),
            }))
          )
        ),
      }

      chart.chartOptions.theme.monochrome.color = '#FF9800'

      return chart
    },
  },

  beforeMount() {
    // this.query.scopes[0].parameters[0] = this.anoActual
  },
  mounted() {
    this.requests()
  },
  methods: {
    requests() {
      this.requestQueues()
      this.requestJobs()
      this.requestLogs()
    },

    requestQueues() {
      this.loading++
      this.$axios
        .get('logs/monitoreo/queues')
        .then((response) => {
          this.queues = response.data.data
        })
        .finally(() => {
          this.loading--
        })
    },

    requestJobs() {
      this.loading++
      this.$axios
        .get('logs/monitoreo/jobs')
        .then((response) => {
          this.jobs = response.data.data
        })
        .finally(() => {
          this.generarResumenJobs()
          this.loading--
        })
    },

    requestLogs() {
      this.loading++

      // TODO: Convertir a POST a /search
      this.$axios
        .post('logs/logs/search', {
          filter: {
            tipo: 'job',
          },
          filters: {
            0: {
              field: 'created_at',
              operator: '>=',
              value: this.$dayjs().subtract(60, 'minutes').toJSON(),
            },
          },
          sort: {
            0: { field: 'fecha', direction: 'desc' },
          },
        })
        .then((response) => {
          this.logs = response.data.data
        })
        .finally(() => {
          this.loading--
        })
    },

    generarResumenJobs() {
      const jobsConsolidado = {}
      const detalleJobProcessed = {}
      const detalleJobFailed = {}
      const detalleJobExceptionOccurred = {}
      const listaFechas = {}
      const listaJobs = {}

      for (const [fecha, microservicios] of Object.entries(this.jobs)) {
        listaFechas[fecha] = 0
        jobsConsolidado[fecha] = {
          fecha,
          total: {
            JobProcessing: 0,
            JobProcessed: 0,
            JobFailed: 0,
            JobExceptionOccurred: 0,
          },
          detalle: {},
        }
        for (const [microservicio, jobs] of Object.entries(microservicios)) {
          for (const [job, estados] of Object.entries(jobs)) {
            jobsConsolidado[fecha].detalle[microservicio + ':' + job] = {}
            listaJobs[microservicio + ':' + job] = true
            for (const [estado, b] of Object.entries(estados)) {
              jobsConsolidado[fecha].total[estado] += b.count
              jobsConsolidado[fecha].detalle[microservicio + ':' + job][estado] = b.count
            }
          }
        }
      }

      this.jobsConsolidado = Object.values(jobsConsolidado)

      this.jobsConsolidado.map((j) => {
        for (const [job, b] of Object.entries(j.detalle)) {
          // JobProcessed
          detalleJobProcessed[job] ??= { ...listaFechas }
          detalleJobProcessed[job][j.fecha] = b.JobProcessed ?? 0
          // JobFailed
          detalleJobFailed[job] ??= { ...listaFechas }
          detalleJobFailed[job][j.fecha] = b.JobFailed ?? 0
          // JobExceptionOccurred
          detalleJobExceptionOccurred[job] ??= { ...listaFechas }
          detalleJobExceptionOccurred[job][j.fecha] = b.JobExceptionOccurred ?? 0
        }
      })

      this.detalleJobProcessed = detalleJobProcessed
      this.detalleJobFailed = detalleJobFailed
      this.detalleJobExceptionOccurred = detalleJobExceptionOccurred
      this.listaJobs = Object.keys(listaJobs)
    },
  },
}
</script>
