<template>
  <div>
    <v-form>
      <v-card class="mx-auto my-4" max-width="600">
        <v-card-title> Cargar archivos de depósitos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-file-input
                v-show="!loading"
                v-model="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,application/vnd.oasis.opendocument.spreadsheet"
                prepend-icon="mdi-paperclip"
                class="mt-4"
                label="Seleccionar archivo"
                outlined
                show-size
                :error-messages="errors.file"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="subirDeposito()"> Subir deposito </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <dialog-progress v-if="loading"> Cargando información... </dialog-progress>
  </div>
</template>
<script>
import { getError } from '@/utils/errors'
import DialogProgress from '@/admin/components/DialogProgress'
import { notificar } from '@/admin/utils/notificar'

export default {
  components: {
    DialogProgress,
  },
  data: () => ({
    errors: {},
    loading: false,
    slugTramite: '',
    file: null,
    depositoCargado: {},
    tramiteSlug: '',
    fileType: '',
  }),
  computed: {},
  methods: {
    subirDeposito() {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.file === null) {
        notificar({
          message: 'Debe subir un archivo válido',
          severity: 'error',
        })
        return
      }
      this.loading = 1
      this.$axios
        .post('pagos/cargar-depositos', formData, { headers })
        .then((response) => {
          this.errors = {}

          notificar({
            message: 'Se ha cargado el depósito exitosamente.',
            severity: 'info',
          })
        })
        .catch((error) => {
          this.errors = getError(error)
          notificar({
            message: 'No se pudo cargar el depósito.',
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = 0
        })
    },
  },
}
</script>
