<template>
  <div>
    <v-sheet max-width="900" class="mx-auto my-4">
      <h2 class="text-h5 mb-8">
        {{ grupo.nombre }}
      </h2>

      <div v-if="loading">Cargando...</div>

      <v-card>
        <v-card-title>{{ pagina }}. {{ tema.titulo }}</v-card-title>
        <v-card-subtitle>{{ tema.subtitulo }}</v-card-subtitle>
        <v-card-text class="text--primary documentacion-contenido" v-html="tema.contenido" />
        <v-card-text class="d-flex justify-space-between">
          <v-btn v-if="temaAnterior.titulo" color="primary" outlined small @click="pagina--">
            <v-icon left> mdi-chevron-left </v-icon>
            {{ pagina - 1 }}. {{ temaAnterior.titulo }}
          </v-btn>
          <div v-else />
          <v-btn v-if="temaSiguiente.titulo" color="primary" outlined small @click="pagina++">
            {{ pagina + 1 }}. {{ temaSiguiente.titulo }}
            <v-icon right> mdi-chevron-right </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-sheet>

    <div v-if="temasPublicados.length > 1" class="text-center">
      <v-pagination v-model="pagina" :length="temasPublicados.length" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AyudaPage',
  auth: false,
  data() {
    return {
      loading: false,
      pagina: 1,
      grupos: [],
      grupo_index: null,
    }
  },
  computed: {
    grupo() {
      return (
        this.$payload
          .gruposCapacitacion()
          .find((g) => g.id === parseInt(this.$route.params.grupo_id)) || {
          nombre: '',
        }
      )
    },
    temasPublicados() {
      return this.grupos[0]?.temasPublicados ?? []
    },
    tema() {
      return this.temasPublicados[this.pagina - 1] ?? {}
    },
    temaAnterior() {
      return this.temasPublicados[this.pagina - 2] ?? {}
    },
    temaSiguiente() {
      return this.temasPublicados[this.pagina] ?? {}
    },
  },
  beforeMount() {
    this.loading = true
    this.$axios
      .post('documentacion/grupos/search', {
        html: true,
        include: 'temasPublicados',
        filter: {
          seccion: 3,
          id: this.$route.params.grupo_id,
          publicado: true,
          // 'temas.publicado': true,
        },
        sort: [{ field: 'prioridad', direction: 'asc' }],
      })
      .then((response) => {
        this.grupos = response.data.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {},
}
</script>

<style>
.documentacion-contenido img {
  max-width: 100%;
}
</style>
