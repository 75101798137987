<template>
  <v-card
    :color="active ? 'primary ' : 'grey lighten-2'"
    style="user-select: none"
    :style="active ? '' : 'opacity: 0.6'"
    :dark="active"
    min-height="140"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-title>
      {{ $formato.moneda(monto) }}
      <v-spacer />
      <v-icon v-show="active" large class="mt-n2 mr-n1">
        {{ svg.mdiCheckCircleOutline }}
      </v-icon>
    </v-card-title>
    <v-card-subtitle class="text-caption mt-n5 mb-n1">
      <slot name="subtitulo" />
    </v-card-subtitle>
    <v-card-text>
      <slot name="default" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckCircleOutline } from '@mdi/js'

export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    monto: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      svg: {
        mdiCheckCircleOutline,
      },
    }
  },

  computed: {},
}
</script>
