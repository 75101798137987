<template>
  <div>
    <div class="text-h6">
      {{ $payload.seccion($route.params.seccion) }}
    </div>

    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[]"
      :botones-ver="[]"
      :campo-titulo-dialog="(v) => v.nombre"
      :headers="headers"
      :query="query"
      url="/documentacion/temas"
      search-url="/documentacion/temas/search"
      search-method="post"
      :search-inicial="!!$route.params.grupo"
      max-width="750"
      campo-titulo-dialog-crear="Crear tema"
      texto-hits="%s temas."
      texto-no-hit="No se encontraron temas."
      texto-un-hit="Un tema."
      @created="$emit('notificar', 'Tema creado.')"
      @deleted="$emit('notificar', 'Tema eliminado.')"
      @updated="$emit('notificar', 'Tema guardado.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="6">
              <z-input
                v-model="query.filter.grupo_id"
                :items="itemsGrupo"
                tipo="autocomplete"
                label="Grupo"
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <z-btn
                :loading="loading"
                :disabled="!query.filter.grupo_id"
                color="primary"
                outlined
                type="submit"
              >
                Actualizar
              </z-btn>
              <z-btn
                class="ml-1"
                color="primary"
                :disabled="!query.filter.grupo_id"
                outlined
                @click="
                  create({ grupo_id: query.filter.grupo_id, prioridad: 50, publicado: false })
                "
              >
                Crear
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #form="{ accion, form, errors }">
        <temas-form :accion="accion" :errors="errors" :value="form" @input="form = $event" />
      </template>

      <template #item.publicado="{ value }">
        <z-chip :color="value ? 'success' : 'grey'" outlined>
          {{ value ? 'Si' : 'No' }}
        </z-chip>
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import TemasForm from '@/admin/views/Documentacion/TemasForm'
import ZInput from '@/components/Input/ZInput'
import ZChip from '@/components/ZChip'

export default {
  components: { ZChip, ZInput, ZMantenedor, ZBtn, TemasForm },
  data() {
    return {
      data: [],
      items: [],
      itemActivo: {},
      query: {
        paginationDisabled: true,
        filter: {
          grupo_id: parseInt(this.$route.params.grupo) || null,
        },
        sort: {
          0: { field: 'prioridad', direction: 'asc' },
        },
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Prioridad',
          value: 'prioridad',
        },
        {
          text: 'Título',
          value: 'titulo',
        },
        {
          text: '¿Publicado?',
          value: 'publicado',
        },
      ]
    },
    itemsGrupo() {
      return Object.entries(this.$payload.gruposSeccion(parseInt(this.$route.params.seccion))).map(
        ([value, text]) => ({ value: parseInt(value), text })
      )
    },
  },
  created() {},
  methods: {},
}
</script>
