<template>
  <v-select
    v-model="jpl"
    v-bind="$attrs"
    hide-details
    label="Juzgado"
    :items="[
      { text: '1º ' + sufijo, value: 1 },
      { text: '2º ' + sufijo, value: 2 },
      { text: '3º ' + sufijo, value: 3 },
      { text: '4º ' + sufijo, value: 4 },
      { text: '5º ' + sufijo, value: 5 },
    ]"
    outlined
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    sufijo: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  computed: {
    jpl: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
