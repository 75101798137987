<template>
  <v-card
    :class="{
      seleccionado: seleccionado,
    }"
    :elevation="seleccionado ? 2 : 0"
    :max-width="maxWidth"
    outlined
    v-bind="$attrs"
  >
    <v-card-title
      :class="{
        'primary--text text--darken-1 blue lighten-5': seleccionado,
        fondo3: !seleccionado,
      }"
      :style="{ cursor: puedeCambiarSeleccion ? 'pointer' : '' }"
      @click.stop="click"
    >
      <span class="text-h5 text-slab">
        {{ cuenta.nombre }}
      </span>

      <v-spacer />

      <!--
        Indicador de que la cuenta ya está pagada
      -->
      <v-tooltip v-if="cuentaPagada" top>
        <template #activator="{ on, attrs }">
          <v-chip v-bind="attrs" class="ml-2" color="primary" outlined small v-on="on">
            <v-icon left small>
              {{ $svg.mdiCheckCircleOutline }}
            </v-icon>
            {{ labelChipEstadoCuenta }}
          </v-chip>
        </template>
        <span>
          Pagado el
          {{ $formato.fecha(cuenta.fecha_pago, 'D [de] MMMM [de] YYYY [a las] HH:mm') }}
        </span>
      </v-tooltip>

      <!--
        Advertencia de que la cuenta no es pagable
      -->

      <v-tooltip v-else-if="!!cuenta.no_pagable || !cuenta.pagable" top>
        <template #activator="{ on, attrs }">
          <v-chip
            class="ml-2"
            color="secondary"
            small
            style="opacity: 0.8"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left x-small>
              {{ $svg.mdiThumbDownOutline }}
            </v-icon>
            {{ (cuenta.no_pagable && cuenta.no_pagable._label_titulo) || 'No se puede pagar' }}
          </v-chip>
        </template>
        <ul v-if="cuenta.no_pagable">
          <template v-for="(razon, key) in cuenta.no_pagable">
            <li v-if="key.toString().substr(0, 1) !== '_'" :key="razon">
              {{ razon }}
            </li>
          </template>
        </ul>
        <span v-else>No está disponible para pago</span>
      </v-tooltip>

      <!--
        Advertencia de que la cuenta está en estado PAGANDO
      -->

      <v-tooltip v-if="cuenta.estado === 21" top>
        <template #activator="{ on, attrs }">
          <v-chip v-bind="attrs" icon small color="warning" class="mr-2 mt-1" v-on="on">
            <v-icon left small>
              {{ $svg.mdiAlertOutline }}
            </v-icon>
            pago en proceso
          </v-chip>
        </template>
        Hace unos minutos tu u otra persona inició el pago de
        {{ tramiteStatic.txtGenero('este', 'esta') }}
        {{ tramiteStatic.nombreSingular.toLowerCase() }}.
        <br />
        Asegurate de no
        <span v-if="tramiteStatic">
          {{ tramiteStatic.txtGenero('pagarlo', 'pagarla') }}
        </span>
        dos veces.
      </v-tooltip>

      <!--
        Checkbox para seleccionar la cuenta
      -->

      <v-checkbox
        v-if="puedeCambiarSeleccion"
        v-model="seleccionado"
        :aria-label="
          seleccionado ? `Deseleccionar ${cuenta.nombre}` : `Seleccionar ${cuenta.nombre} para pago`
        "
        class="ma-0"
        hide-details
        @click.stop=""
      />
    </v-card-title>
    <v-card-subtitle
      v-if="!!cuenta.subtitulo"
      :class="{
        'primary--text text--darken-1 blue lighten-5': seleccionado,
        fondo3: !seleccionado,
      }"
      :style="{ cursor: puedeCambiarSeleccion ? 'pointer' : '' }"
      class="pb-3"
      @click.stop="click"
    >
      {{ cuenta.subtitulo }}
    </v-card-subtitle>
    <v-card-text>
      <v-simple-table class="mb-4 mt-4 labels">
        <tbody>
          <!--

        Lineas definidas por el trámite

      -->

          <template v-for="(valor, titulo) in cuenta.labels">
            <template v-if="typeof valor !== 'object'">
              <tr :key="titulo">
                <td class="text--secondary">
                  {{ titulo }}
                </td>
                <td class="text-right">
                  {{ valor }}
                </td>
              </tr>
            </template>
            <template v-else>
              <buscador-ficha-fila :key="titulo" :campo="valor" :cuenta="cuenta" />
            </template>
          </template>

          <!--
      <tr v-if="!!cuenta.fecha_pago && fechaPago">
        <td class="text--secondary">
          Fecha de pago
        </td>
        <td>{{ $formato.fechaHora(cuenta.fecha_pago) }}</td>
      </tr>
      -->
        </tbody>
      </v-simple-table>

      <v-row>
        <v-col class="d-flex justify-end">
          <buscador-ficha-totales :cuenta="cuenta" :seleccionado="seleccionado" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BuscadorFichaFila from '@/portal/components/BuscadorFichaFila'
import BuscadorFichaTotales from '@/portal/components/BuscadorFichaTotales'
import { mdiCheckCircleOutline, mdiThumbDownOutline } from '@mdi/js'
export default {
  components: { BuscadorFichaTotales, BuscadorFichaFila },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cuenta: {
      type: Object,
      required: true,
    },
    puedeCambiarSeleccion: {
      type: Boolean,
      default: false,
    },
    fechaPago: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [Number, String],
      default: 600,
    },
  },
  data() {
    return {
      svg: {
        mdiCheckCircleOutline,
        mdiThumbDownOutline,
      },
    }
  },
  computed: {
    seleccionado: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    cuentaPagada() {
      return this.cuenta.estado >= 25 && this.cuenta.estado <= 28
    },
    labelChipEstadoCuenta() {
      if (this.cuenta.estado === 28) {
        // "acoge_beneficio"
        return 'acogido a beneficio'
      }

      return 'pagado'
    },
    tramiteStatic() {
      return this.payloadListaTramitesByIdStatic[this.cuenta.tramite_id]
    },
  },
  methods: {
    click() {
      if (!this.puedeCambiarSeleccion) {
        return
      }
      this.seleccionado = !this.seleccionado
    },
  },
}
</script>
