<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr>
          <th>Id</th>
          <td>{{ item.id }}</td>
        </tr>
        <tr>
          <th>Trámite</th>
          <td>{{ $payload.tramite(item.tramite_id, 'nombre', 'id') }}</td>
        </tr>
        <tr>
          <th>{{ $payload.tramite(item.tramite_id, 'definicion', 'id').buscador.nombrePk }}</th>
          <td>{{ item.pk_label }} {{ item.subtitulo }}</td>
        </tr>
        <tr>
          <th>Nombre institución</th>
          <td>{{ payloadListaInstituciones[item.institucion_id] }}</td>
        </tr>
        <tr>
          <th>Rut institución</th>
          <td>{{ $formato.rut(item.institucion_id) }}</td>
        </tr>
        <tr>
          <th>Monto</th>
          <td>{{ $formato.numero(item.monto) }}</td>
        </tr>
        <tr>
          <th>Monto total pagado</th>
          <td>{{ $formato.numero(pagoPagado(item).monto_total) }}</td>
        </tr>
        <tr>
          <th>Fecha de pago</th>
          <td>{{ $formato.fechaHora(item.fecha_pago) }}</td>
        </tr>
        <tr>
          <th>Fecha de envió de mail</th>
          <td>{{ $formato.fecha(item.fecha_comprobante_pago_email) }}</td>
        </tr>
        <tr>
          <th>IRA</th>
          <td>{{ $payload.ira(pagoPagado(item).ira) }}</td>
        </tr>
        <tr>
          <th>Código de verificacion</th>
          <td>{{ item.codigo_verificacion }}</td>
        </tr>
        <tr>
          <th>Código de unicidad</th>
          <td>{{ item.unico }}</td>
        </tr>
        <tr>
          <th>Secuencia</th>
          <td>{{ item.secuencia }}</td>
        </tr>
        <tr>
          <th>Estado</th>
          <td>{{ $payload.estadoCuenta(item.estado) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="text-h6 mt-4 mb-2">Datos</div>
    <v-card class="mx-10">
      <cuenta-tabla-registro dense :cuenta="item" />
    </v-card>
  </div>
</template>

<script>
import CuentaTablaRegistro from '@/admin/components/CuentaTablaRegistro'

export default {
  components: { CuentaTablaRegistro },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    pagoPagado() {
      return this.item.pagos?.find((pago) => pago.estado === 25) ?? {}
    },
  },
}
</script>
