<template>
  <div>
    <v-card class="mx-auto my-10 pb-5" width="750" style="min-width: 850px">
      <v-card-title>Logo de la institución</v-card-title>
      <v-card-subtitle> Aquí verás el logo actual y podrás subir uno nuevo. </v-card-subtitle>

      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-if="urlLogo"> Logo actual </v-tab>
        <v-tab> Subir nuevo logo </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="urlLogo">
          <v-card-text class="px-16">
            <p>
              Este es el logo actual configurado para la institución. Se utiliza para la generación
              de documentos PDF y en otras secciones del sitio web.
            </p>
            <v-card class="mt-10 mx-auto" width="400">
              <v-img contain :src="urlLogo" />
            </v-card>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="px-16">
            <div style="min-height: 60px">
              <p>
                <span v-if="!imagen"> Selecciona la imagen a subir. </span>
                <span v-else>
                  Ajusta el tamaño y posición del logo, tratando de aprovechar el máximo espacio
                  posible.
                </span>
                El formato del archivo de imagen debe ser PNG o JPG, con fondo blanco o
                transparente. El tamaño mínimo recomendado es 400x300 pixeles.
              </p>
            </div>
          </v-card-text>
          <v-card-text class="">
            <div class="d-flex flex-column align-center justify-center">
              <v-image-input
                v-model="imagen"
                :image-quality="0.85"
                image-format="png"
                overlay-background-color="rgba(100,100,100,0.85)"
                overlay-border-color="#000000"
                overlay-border-width="8px"
                overlay-padding="0px"
                :image-width="400 * 1.8"
                :image-height="300 * 1.8"
                :image-max-scaling="1.5"
                image-min-scaling="contain"
                :hide-actions="!imagen"
                :clearable="!!imagen"
                @file-info="onFileInfo"
              />
              <btn-guardar-cancelar
                v-if="!!imagen"
                :loading="loading"
                @cancelar="inicio"
                @guardar="save"
              />
            </div>
          </v-card-text>
          <v-card-actions class="px-4" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!--
   <img
     :src="imagen"
     alt="Preview"
   >
   -->
  </div>
</template>
<script>
import VImageInput from 'vuetify-image-input/a-la-carte'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import { getError } from '@/utils/errors'
import { util } from '@/utils/util'
import { notificar } from '@/admin/utils/notificar'

export default {
  components: { BtnGuardarCancelar, VImageInput },
  data() {
    return {
      imagen: '',
      tab: 0,
      loading: false,
      random: Date.now(),
    }
  },
  computed: {
    urlLogo() {
      return this.$auth.institucion().url_logo
        ? this.$auth.institucion().url_logo + '?x=' + this.random
        : ''
    },
  },
  mounted() {
    this.inicio()
  },
  methods: {
    onFileInfo(e) {
      console.log(e)
    },
    save() {
      const formData = new FormData()
      formData.append('archivo', util.convertBase64ToFile(this.imagen, 'image/png'))

      this.loading = true
      const headers = { 'Content-Type': 'multipart/form-data' }
      this.$axios
        .post(`usuarios/instituciones/${this.$auth.institucion().id}/archivos/logo`, formData, {
          headers,
        })
        .then((response) => {
          notificar('El logo ha sido actualizado.')
          this.$store.dispatch('auth/setAuthInstitucion', {
            ...this.$auth.institucion(),
            url_logo: response.data.url_logo,
          })
        })
        .catch((error) => {
          error = getError(error)
          console.log(error)
          notificar({
            message: `No se pudo subir la imágen: ${
              error.archivo?.join(', ') || error._message || error
            }`,
            severity: 'error',
          })
        })
        .finally(() => {
          this.loading = false
          this.inicio()
        })
    },
    inicio() {
      this.random = Date.now()
      this.tab = 0
      this.imagen = null
    },
  },
}
</script>
