<template>
  <v-sheet v-bind="$attrs" class="filtro-cuentas" v-on="$listeners">
    <v-chip-group v-model="local" mandatory column active-class="secondary">
      <v-chip filter value="0" small> Todo ({{ cantidadTotal }}) </v-chip>
      <v-chip filter value="no-pagada" small> Por pagar ({{ cantidadNoPagadas }}) </v-chip>
      <v-chip filter value="vencida" small>
        {{ tramiteStatic.txtGenero('Vencidos', 'Vencidas') }}
        ({{ cantidadVencidas }})
      </v-chip>
      <v-chip filter value="pagada" small>
        {{ tramiteStatic.txtGenero('Pagados', 'Pagadas') }}
        ({{ cantidadPagadas }})
      </v-chip>

      <v-chip filter value="seleccionada" small>
        {{ tramiteStatic.txtGenero('Seleccionados', 'Seleccionadas') }}
        ({{ cantidadSeleccionadas }})
      </v-chip>
    </v-chip-group>
  </v-sheet>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    tramiteStatic: {
      type: Object,
      required: true,
    },
    cantidadTotal: {
      type: Number,
      default: 0,
    },
    cantidadVencidas: {
      type: Number,
      default: 0,
    },
    cantidadPagadas: {
      type: Number,
      default: 0,
    },
    cantidadNoPagadas: {
      type: Number,
      default: 0,
    },
    cantidadSeleccionadas: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    local: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style>
.filtro-cuentas .v-chip-group svg.v-icon__svg {
  height: 16px;
}
</style>
