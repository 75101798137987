<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Ver historial',
          icon: 'mdi-history',
          click: mostrarHistorial,
          posicion: 'menu',
        },
      ]"
      :botones-ver="[{ title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial }]"
      :botones-principales-crear="[
        {
          label: 'Validar',
          click: validar,
          loading: false,
          disabled: () => certificadoValidado === true,
        },
      ]"
      :boton-principal-crear-disabled="certificadoValidado !== true"
      :on-before-dialog-edit="onBeforeDialogEdit"
      :campo-titulo-dialog="(v) => v.nombre"
      :headers="headers"
      :query="query"
      url="/usuarios/firmantes"
      search-url="/usuarios/firmantes/search"
      search-method="post"
      campo-titulo-dialog-crear="Crear firmante"
      search-inicial
      :on-after-search="afterSearch"
      :on-after-save="afterSave"
      :on-after-delete="afterDelete"
      texto-filtrar="Filtrar"
      texto-hits="%s firmantes."
      texto-no-hit="No se encontraron firmantes."
      texto-un-hit="Un usuario."
      @created="$emit('notificar', 'Firmante creado.')"
      @deleted="$emit('notificar', 'Firmante eliminado.')"
      @updated="$emit('notificar', 'Firmante guardado.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col v-if="$auth.can(['operador', 'mesa-de-ayuda'])" cols="12" sm="6" md="5">
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                tipo="autocomplete"
                label="Institución"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="5" md="3">
              <v-select
                v-model="query.filter.activo"
                :items="[
                  { text: 'Activo', value: true },
                  { text: 'Deshabilitado', value: false },
                ]"
                clearable
                dense
                label="¿Activo?"
                outlined
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
              <z-btn
                class="ml-1"
                color="primary"
                outlined
                @click="
                  certificadoValidado = null
                  create({ activo: true })
                "
              >
                Crear
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.activo="{ item }">
        <z-chip :color="item.activo ? 'success' : 'red'" dark>
          {{ item.activo ? 'Activo' : 'Deshabilitado' }}
        </z-chip>
      </template>
      <template #form="{ accion, form, errors }">
        <firmantes-form
          :certificado="certificado"
          :certificado-validado="certificadoValidado"
          :accion="accion"
          :errors="errors"
          :value="form"
          @input="form = $event"
        />
      </template>
    </z-mantenedor>

    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.id"
      :titulo="`${itemActivo.nombre}`"
      microservicio="usuarios"
      clase="firmantes"
      @cerrar="historialVisible = false"
    />
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import ZChip from '@/components/ZChip'
import FirmantesForm from '@/admin/views/Admin/FirmantesForm'
import DialogHistorial from '@/admin/components/DialogHistorial'
import ZInput from '@/components/Input/ZInput'
import { notificar } from '@/admin/utils/notificar'

export default {
  components: {
    ZInput,
    DialogHistorial,
    ZChip,
    ZMantenedor,
    ZBtn,
    FirmantesForm,
  },
  data() {
    return {
      items: [],
      itemActivo: {},
      historialVisible: false,
      headerOptions: {
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      query: {
        paginationDisabled: true,
        filter: {
          activo: null,
          institucion_id: null,
        },
        sort: {
          0: { field: 'fecha_desde', direction: 'asc' },
        },
      },
      dialogCrearFirmanteVisible: false,
      certificadoValidado: null,
      certificado: {},
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Institución',
          value: 'institucion_id',
          visible: this.headerOptions.institucion_id.visible,
          formatter: (v) => this.$payload.institucion(v),
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: 'Proveedor de firma',
          value: 'proveedor',
          formatter: (v) => this.$payload.proveedorFirma(v),
        },
        {
          text: 'Código',
          value: 'codigo',
        },
        { text: 'Activo', value: 'activo' },
        {
          text: 'Última edición',
          value: 'updated_at',
          cellClass: 'text-no-wrap',
          formatter: (v) => this.$formato.fechaFromNow(v, 23, 'hour', 'L HH:mm'),
        },
      ]
    },
  },
  created() {
    this.query.filter.institucion_id =
      parseInt(this.$route.query.institucion_id) || this.$auth.institucion().id
  },
  methods: {
    afterSearch() {
      this.updateHeaders()
    },
    afterDelete(item) {
      this.$store.dispatch('app/fetchPayload', 'usuarios', { root: true })
    },
    afterSave(item, accion) {
      this.$store.dispatch('app/fetchPayload', 'usuarios', { root: true })
    },
    updateHeaders() {
      this.headerOptions.institucion_id.visible =
        !this.query.filter.institucion_id && this.$auth.can(['operador', 'mesa-de-ayuda'])
    },

    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },

    abrirDialogCrear() {
      this.accion = 'validar'
      this.dialogCrearFirmanteVisible = true
    },

    onBeforeDialogEdit(item) {
      return new Promise((resolve, reject) => {
        this.certificadoValidado = null
        resolve(item)
      })
    },

    validar({ form, formDom, submit, actualizarForm, actualizarErrores }) {
      if (!formDom.validate()) {
        return
      }

      this.data = {
        firmante: form.proveedor,
        codigo: form.codigo,
      }

      this.$axios
        .post(`tramitesgenerales/firmantes/validar`, this.data)
        .then((response) => {
          this.certificadoValidado = response.data.validado

          if (this.certificadoValidado === true) {
            this.certificado = response.data.certificado
            form.nombre = this.certificado.CN
            form.activo = true
          } else {
            this.certificadoValidado = null
            notificar({ message: response.data.error, severity: 'error' })
          }
        })
        .catch((error) => {
          actualizarErrores(error)
        })
    },
  },
}
</script>
