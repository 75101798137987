<template>
  <v-tooltip top v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        x-small
        outlined
        class="mr-1"
        :color="color"
        label
        v-on="on"
        @click="$emit('click')"
      >
        {{ cuenta.pk_label }}
      </v-chip>
    </template>
    <div v-if="mostrarTramite">
      {{ $payload.tramite(cuenta.tramite_id, 'nombre', 'id') }}
    </div>
    <div v-show="cuenta.subtitulo" class="font-weight-medium">
      {{ cuenta.subtitulo }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ChipLabelCuenta',
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
    mostrarTramite: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
